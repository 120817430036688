import { usePreviousRef } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import { useEffect } from 'react';
import { useTldlAudioUrl } from '../state/useTldlJobStatus';

export default function useTrackTldlAudioReady() {
  const { trackTldlAudioReady } = useEventTracking();
  const audioUrl = useTldlAudioUrl();
  const prevAudioUrl = usePreviousRef(audioUrl);

  useEffect(() => {
    if (audioUrl !== prevAudioUrl.current && audioUrl) {
      trackTldlAudioReady();
    }
  }, [audioUrl, prevAudioUrl, trackTldlAudioReady]);
}
