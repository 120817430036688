import { useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { parseTldlQueryParams } from 'utils/routes';
import { TldlPageParams } from '../../types';

/**
 * Read location data once on mount.  After the initial mount, data only flows
 * from the app to the URL and not in the other direction
 */
export default function useTldlLocation() {
  const [{ jobCuid }] = useState(useParams<TldlPageParams>());
  const [{ search }] = useState(useLocation());
  const episodes = useMemo(
    () => parseTldlQueryParams(search).episodes,
    [search],
  );

  return { episodes, jobCuid };
}
