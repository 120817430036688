import { podcastSelectors } from 'api/selectors';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import {
  GetBestRankedPoscastsResponse,
  getBestRankedPoscasts,
} from '../podcast';
import { bestRankedPodcastsQueryKeys } from './queries';

export type Options<TData = GetBestRankedPoscastsResponse> = UseQueryOptions<
  GetBestRankedPoscastsResponse,
  typeof bestRankedPodcastsQueryKeys.all,
  AxiosError | Error,
  TData
>;

export default function useGetBestRankedPodcasts<
  TData = GetBestRankedPoscastsResponse,
>(opts?: Options<TData>) {
  return useQuery(
    bestRankedPodcastsQueryKeys.all,
    ({ pageParam }) => getBestRankedPoscasts(pageParam),
    {
      ...opts,
      getNextPageParam: (lastPage) => lastPage?.nextPage ?? undefined,
    },
  );
}

export const useFirstNBestRankedPodcasts = (n: number) => {
  const selector = useMemo(
    () => podcastSelectors.firstNBestRankedPodcastsSelector(n),
    [n],
  );

  return useGetBestRankedPodcasts({ select: selector });
};
