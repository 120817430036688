import { Card } from '@sparemin/blockhead';
import React, { useCallback, useState } from 'react';

import SearchModalBody from '../SearchModalBody';
import { useMode, useSearchModalBanner } from '../SearchModalConfigContext';
import { SearchModalDispatchProvider } from '../SearchModalDispatchContext';
import SearchModalHeader from '../SearchModalHeader';
import SearchModalPodcastEpisodeList from '../SearchModalPodcastEpisodeList';
import { OnSelectEpisode, OnSelectPodcast, SearchPodcast } from '../types';
import useSearchInput from '../useSearchInput';
import styles from './SearchModalContents.module.scss';
import useSearchModalCallbacks, {
  OnSelectPodcastInternal,
} from './useSearchModalCallbacks';

interface SearchModalContentsProps {
  onCloseModal: () => void;
  onSelectPodcast?: OnSelectPodcast;
  onSelectEpisode?: OnSelectEpisode;
}

const Null = () => null;

const SearchModalContents: React.FunctionComponent<SearchModalContentsProps> = (
  props,
) => {
  const { onCloseModal } = props;

  const banner = useSearchModalBanner();
  const mode = useMode();

  const [searchValue, setSearchValue] = React.useState<string>('');

  const { isSearchActive, searchQuery } = useSearchInput({
    searchInputValue: searchValue,
  });

  const [selectedPodcast, setSelectedPodcast] = useState<SearchPodcast>();

  const { onSelectEpisode, onSelectPodcast } = useSearchModalCallbacks(props, {
    onSelectEpisode: useCallback(() => {
      if (mode === 'navigation') {
        onCloseModal();
      }
    }, [mode, onCloseModal]),
    onSelectPodcast: useCallback<OnSelectPodcastInternal>(
      (podcast, result) => {
        if (mode === 'navigation') {
          onCloseModal();
        } else if (!result.isDefaultPrevented) {
          setSelectedPodcast(podcast);
        }
      },
      [mode, onCloseModal],
    ),
  });

  return (
    <Card className={styles.root} header={banner} footer={<Null />}>
      <SearchModalDispatchProvider {...{ onSelectEpisode, onSelectPodcast }}>
        {selectedPodcast ? (
          <SearchModalPodcastEpisodeList
            onBackPress={() => setSelectedPodcast(undefined)}
            podcastId={selectedPodcast.id}
            title={selectedPodcast.title}
          />
        ) : (
          <>
            <SearchModalHeader
              onChangeValue={setSearchValue}
              onClose={onCloseModal}
              value={searchValue}
            />
            <SearchModalBody
              {...{
                isSearchActive,
                searchQuery,
                selectedPodcastId: selectedPodcast,
              }}
            />
          </>
        )}
      </SearchModalDispatchProvider>
    </Card>
  );
};

export default SearchModalContents;
