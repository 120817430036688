import { Spacer } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';
import React from 'react';
import TldlCard from '../TldlCard';
import TldlEpisodeCardFooter from './TldlEpisodeCardFooter';
import TldlEpisodeCardHeader from './TldlEpisodeCardHeader';
import TldlEpisodeList from './TldlEpisodeList';

export interface TldlEpisodesCardProps {}

// TODO - rename to TldlSummarizerCard
const TldlEpisodesCard: React.FC<TldlEpisodesCardProps> = () => {
  const { md } = useBreakpoints();

  const space = md ? '24px' : '40px;';

  return (
    <TldlCard gutter={space}>
      <Spacer align="center" orientation="vertical" space={space}>
        <TldlEpisodeCardHeader />
        <TldlEpisodeList />
        <TldlEpisodeCardFooter />
      </Spacer>
    </TldlCard>
  );
};

export default TldlEpisodesCard;
