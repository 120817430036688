import { DescriptionHeadline } from '@sparemin/blockhead';
import React from 'react';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export interface EpisodeDescriptionPodcastTitleProps {}

const EpisodeDescriptionPodcastTitle: React.FC<
  EpisodeDescriptionPodcastTitleProps
> = () => {
  const { podcastName } = useEpisodeDescription();

  return (
    <DescriptionHeadline.SecondaryText>
      {podcastName}
    </DescriptionHeadline.SecondaryText>
  );
};

export default EpisodeDescriptionPodcastTitle;
