import api, { PodpressJobDetail } from '@sparemin/api-sdk';
import { tldlJobDetailQueryKeys } from 'api/hooks/queries';
import {
  tldlAudioPlayerChaptersSelector,
  tldlIsJobProcessingSelector,
  tldlJobEpisodeStatusSelector,
  tldlJobInputsIdsSelector,
  tldlJobProgressSelector,
  tldlJobStatusSelector,
  tldlJobTargetDurationMillisSelector,
  tldlSummarizedAudioUrlSelector,
} from 'api/selectors/tldl';
import { useCallback } from 'react';
import { Query, QueryFunctionContext, useQuery } from 'react-query';
import { UseQueryOptions } from 'types';
import { DEFAULT_POLLING_INTERVAL_MILLIS } from 'utils/constants';

export type UseTldlJobStatusQueryArgs = {
  jobCuid?: string;
};

export type UseTldlEpisodeStatusArgs = UseTldlJobStatusQueryArgs & {
  episodeId: string;
  podcastId: string;
};

type QueryKey = ReturnType<typeof tldlJobDetailQueryKeys.job>;

export type UseTldlJobStatusQueryOptions<TData = PodpressJobDetail> = Pick<
  UseQueryOptions<PodpressJobDetail, QueryKey, unknown, TData>,
  'select'
>;

function queryFn({ queryKey: [{ cuid }] }: QueryFunctionContext<QueryKey>) {
  return api.getPodpressJobDetail({ cuid: cuid as string });
}

function refetchInterval(
  _: unknown,
  query: Query<PodpressJobDetail, unknown, PodpressJobDetail, QueryKey>,
) {
  return tldlIsJobProcessingSelector(query.state?.data)
    ? DEFAULT_POLLING_INTERVAL_MILLIS
    : false;
}

export function useTldlJobStatusQuery<TData = PodpressJobDetail>(
  args: UseTldlJobStatusQueryArgs,
  opts?: UseTldlJobStatusQueryOptions<TData>,
) {
  const { jobCuid } = args;

  return useQuery<PodpressJobDetail, unknown, TData, QueryKey>({
    enabled: jobCuid !== undefined,
    queryKey: tldlJobDetailQueryKeys.job(jobCuid),
    queryFn,
    refetchInterval,
    // the summary URL has different query parameters on it for every api response.
    // the different query params cause some re-renders in the app where the src is
    // used as the key.  the entire audio player reloads because of the new URL
    staleTime: Infinity,
    ...opts,
  });
}

export const useTldlJobStatus = (args: UseTldlJobStatusQueryArgs) =>
  useTldlJobStatusQuery(args, { select: tldlJobStatusSelector });

export const useTldlProgressValue = (args: UseTldlJobStatusQueryArgs) =>
  useTldlJobStatusQuery(args, { select: tldlJobProgressSelector });

export const useTldlEpisodeStatus = ({
  jobCuid,
  podcastId,
  episodeId,
}: UseTldlEpisodeStatusArgs) =>
  useTldlJobStatusQuery(
    { jobCuid },
    {
      select: useCallback(
        (d: PodpressJobDetail) =>
          tldlJobEpisodeStatusSelector(d, podcastId, episodeId),
        [episodeId, podcastId],
      ),
    },
  );

export const useTldlAudioUrl = (args: UseTldlJobStatusQueryArgs) =>
  useTldlJobStatusQuery(args, { select: tldlSummarizedAudioUrlSelector });

export const useTldlJobInputIds = (args: UseTldlJobStatusQueryArgs) =>
  useTldlJobStatusQuery(args, { select: tldlJobInputsIdsSelector });

export const useTldlAudioPlayerChapters = (args: UseTldlJobStatusQueryArgs) =>
  useTldlJobStatusQuery(args, { select: tldlAudioPlayerChaptersSelector });

export const useTldlSummarizedAudioUrl = (args: UseTldlJobStatusQueryArgs) =>
  useTldlJobStatusQuery(args, { select: tldlSummarizedAudioUrlSelector });

export const useTldlJobTargetDurationMillis = (
  args: UseTldlJobStatusQueryArgs,
) =>
  useTldlJobStatusQuery(args, { select: tldlJobTargetDurationMillisSelector });
