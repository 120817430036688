import cn from 'classnames';

export const getClassNameWithVariant = (
  styles: Record<string, string>,
  className: string,
  variant: string,
): string => cn(styles[className], styles[`${className}__${variant}`]);

export function px(val: string | number) {
  return typeof val === 'string' ? val : `${val}px`;
}

export function numToPx(val: string | number | undefined) {
  if (val === undefined || typeof val === 'string') {
    return val;
  }

  return `${val}px`;
}
