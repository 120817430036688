import { Heading, Plus, Spacer } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import useOpenTldlSearch from '../hooks/useOpenTldlSearch';
import TldlCard from '../TldlCard';
import styles from './TldlLandingCard.module.scss';
import TldlLandingCardButton from './TldlLandingCardButton';

export interface TldlLandingCardProps {}

const TldlLandingCard: React.FC<TldlLandingCardProps> = () => {
  const { openTldlSearch } = useOpenTldlSearch();
  const { trackTldlEmptyStateButtons } = useEventTracking();
  const { md } = useBreakpoints();

  const topGutter = md ? '40px' : '80px';

  const handleClickSelectEpisodes = useCallback(() => {
    trackTldlEmptyStateButtons('SelectEpisodes');
    openTldlSearch();
  }, [openTldlSearch, trackTldlEmptyStateButtons]);

  return (
    <TldlCard gutter={`${topGutter} 24px 24px`}>
      <Spacer align="center" orientation="vertical" space="80px">
        <Spacer
          align="center"
          className={styles.header}
          orientation="vertical"
          space="16px"
        >
          <Heading className={styles.hero} level={1} lineHeight={1.2}>
            Podcasts too long? Listen to just the gist.
          </Heading>
          <Heading level={2}>
            No time to listen to every episode of your favorite podcast? Run
            them through our <em>semantic compression algorithm</em> to get the
            gist in a fraction of the time. If you like what you hear then you
            can easily dive in to the full episode!
          </Heading>
        </Spacer>
        <Spacer
          className={styles.actions}
          orientation={md ? 'vertical' : 'horizontal'}
          space="16px"
        >
          <TldlLandingCardButton onPress={handleClickSelectEpisodes}>
            <Plus style={{ marginRight: 8 }} /> select episodes
          </TldlLandingCardButton>
        </Spacer>
      </Spacer>
    </TldlCard>
  );
};

export default TldlLandingCard;
