import { FadingContainer } from '@sparemin/blockhead';
import React from 'react';
import { PALETTE } from 'utils/constants';

import styles from './SearchModalFadingWrapper.module.scss';

const SearchModalFadingWrapper: React.FunctionComponent = (props) => {
  const { children } = props;

  return (
    <FadingContainer
      axis="y"
      backgroundColor={PALETTE.white}
      className={styles.root}
      threshold={0.025}
    >
      {(
        scrollerRef: React.Ref<HTMLDivElement>,
        contentRef: React.Ref<HTMLDivElement>,
      ) => (
        <div ref={scrollerRef} className={styles.content}>
          <div ref={contentRef}>{children}</div>
        </div>
      )}
    </FadingContainer>
  );
};

export default SearchModalFadingWrapper;
