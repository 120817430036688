import React, { useCallback, useContext, useMemo, useState } from 'react';
import { AudioPlayerProvider, useAudioPlayer } from '../AudioPlayerContext';
import {
  AudioPlayerLoaderContextType,
  AudioPlayerLoaderProviderProps,
  LoaderContextType,
} from './types';
import useEpisodeLoader from './useEpisodeLoader';
import useTldlLoader from './useTldlLoader';

const AudioPlayerLoaderContext = React.createContext<
  AudioPlayerLoaderContextType | undefined
>(undefined);

export const AudioPlayerLoaderProvider: React.FC<
  AudioPlayerLoaderProviderProps
> = ({ audioElement, children }) => {
  const [loaderContext, setLoaderContext] =
    useState<LoaderContextType>(undefined);

  const {
    loaderProviderProps: episodeLoaderProviderProps,
    playerProviderProps: episodePlayerProviderProps,
  } = useEpisodeLoader({
    audioElement,
    isEnabled: true,
    onLoadAudio: useCallback(() => {
      setLoaderContext('episode');
    }, []),
  });

  const {
    loaderProviderProps: tldlLoaderProviderProps,
    playerProviderProps: tldlPlayerProviderProps,
  } = useTldlLoader({
    audioElement,
    isEnabled: true,
    onLoadAudio: useCallback(() => {
      setLoaderContext('tldl');
    }, []),
  });

  const loaderProviderProps = useMemo(
    () => ({
      ...episodeLoaderProviderProps,
      ...tldlLoaderProviderProps,
      loaderContextType: loaderContext,
    }),
    [episodeLoaderProviderProps, loaderContext, tldlLoaderProviderProps],
  );

  const playerProviderProps =
    loaderContext === 'episode'
      ? episodePlayerProviderProps
      : tldlPlayerProviderProps;

  return (
    <AudioPlayerLoaderContext.Provider value={loaderProviderProps}>
      <AudioPlayerProvider {...playerProviderProps}>
        {children}
      </AudioPlayerProvider>
    </AudioPlayerLoaderContext.Provider>
  );
};

export function useAudioPlayerLoader() {
  const context = useContext(AudioPlayerLoaderContext);

  if (context === undefined) {
    throw new Error(
      'useAudioPlayerLoader must be used within AudioPlayerLoaderProvider',
    );
  }

  return context;
}

export function useAudioPlayerWithMeta() {
  const audioPlayerLoaderContext = useAudioPlayerLoader();
  const audioPlayerContext = useAudioPlayer();

  return {
    ...audioPlayerLoaderContext,
    ...audioPlayerContext,
  };
}
