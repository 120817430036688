import React from 'react';

import SearchModalBestRankedPodcast from './SearchModalBestRankedPodcast';
import SearchModalResults from './SearchModalResults';

interface SearchModalBodyProps {
  isSearchActive: boolean;
  searchQuery: string;
}

const SearchModalBody: React.FunctionComponent<SearchModalBodyProps> = (
  props,
) => {
  const { isSearchActive, searchQuery } = props;

  if (isSearchActive) {
    return <SearchModalResults searchQuery={searchQuery} />;
  }

  return <SearchModalBestRankedPodcast />;
};

export default SearchModalBody;
