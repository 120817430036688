import { isTldlStatusResolved } from 'api/selectors/tldl';
import { notifyError } from 'components/notification';
import usePrevious from 'hooks/usePrevious';
import { useEffect } from 'react';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import { useTldlActions } from '../state/tldl';
import { useTldlJobStatus } from '../state/useTldlJobStatus';

export default function useNotifyOnTldlJobError() {
  const { data: status } = useTldlJobStatus();
  const { tldlJobFailure } = useTldlActions();
  const prevStatus = usePrevious(status);

  useEffect(() => {
    if (prevStatus && !isTldlStatusResolved(prevStatus) && status === 'error') {
      notifyError({ heading: DEFAULT_ERROR_MESSAGE });
      tldlJobFailure();
    }
  }, [prevStatus, status, tldlJobFailure]);
}
