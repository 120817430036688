import { useStaticCallback } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import { useModalControls } from 'context/ModalContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import { getEpisodeListeningProgress } from 'pages/AudioPlayerPage/state/listeningProgress';
import { useEffect, useRef } from 'react';
import useSponsoredEpisodeFromQueue from './hooks/useSponsoredEpisodeFromQueue';

export interface UseEpisodePageConfig {
  episodeId: string;
  internal: boolean;
  podcastId: string;
  startSec?: number;
  podcastName: string;
  episodeName: string;
  hasChapters?: boolean;
  handleInteractionModal?: (val: boolean) => void;
  source?: 'queue';
  sourceWidgetId?: string;
}

export default function useEpisodePage({
  episodeId,
  internal,
  podcastId,
  startSec,
  podcastName,
  episodeName,
  handleInteractionModal,
  source,
  sourceWidgetId,
}: UseEpisodePageConfig) {
  const {
    episodeId: playerEpisodeId,
    loadEpisodeAudio,
    setSourceWidgetId,
  } = useAudioPlayer();

  const { trackSponsoredEpisodePlayed } = useEventTracking();
  const episodePageAudioLoadedRef = useRef(false);

  const { open } = useModalControls();

  const staticHandleInteractionModal = useStaticCallback(
    handleInteractionModal,
  );
  const { isSponsored, widgetInfo } = useSponsoredEpisodeFromQueue({
    episodeId,
    podcastId,
    source,
  });

  const staticLoadAudio = useStaticCallback(loadEpisodeAudio);
  const staticSetSourceWidgetId = useStaticCallback(setSourceWidgetId);

  useEffect(() => {
    const episodePageAudioLoaded = episodePageAudioLoadedRef.current;

    if (
      startSec !== undefined &&
      episodeId !== playerEpisodeId &&
      !episodePageAudioLoaded
    ) {
      if (internal) {
        const startTime = getEpisodeListeningProgress(episodeId);
        staticLoadAudio({
          episodeId,
          podcastId,
          startSec: startTime ?? startSec,
          isSponsored,
          sponsoredWidgetInfo: widgetInfo,
          isPlayedFromQueue: source === 'queue',
        });
        if (isSponsored && widgetInfo) {
          trackSponsoredEpisodePlayed(widgetInfo);
        }
        staticHandleInteractionModal(false);
      } else {
        staticHandleInteractionModal(true);
        if (sourceWidgetId) {
          staticSetSourceWidgetId(sourceWidgetId);
        }
        open({
          name: 'ContinueListening',
          onAfterClose: () =>
            staticLoadAudio({
              episodeId,
              podcastId,
              startSec,
            }),
          params: { episodeId, podcastId, podcastName, episodeName },
        });
      }
      episodePageAudioLoadedRef.current = true;
    } else staticHandleInteractionModal(false);
  }, [
    episodeId,
    internal,
    playerEpisodeId,
    podcastId,
    startSec,
    staticHandleInteractionModal,
    staticLoadAudio,
    episodeName,
    podcastName,
    open,
    isSponsored,
    widgetInfo,
    trackSponsoredEpisodePlayed,
    source,
    sourceWidgetId,
    staticSetSourceWidgetId,
  ]);
}
