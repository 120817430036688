import { DescriptionHeadline } from '@sparemin/blockhead';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export type EpisodeDescriptionHeadlineProps = {
  children?: React.ReactNode;
};

const EpisodeDescriptionHeadline: React.FC<EpisodeDescriptionHeadlineProps> = ({
  children,
}) => {
  const { isLoading } = useEpisodeDescription();

  return (
    <DescriptionHeadline>
      {isLoading ? (
        <>
          <DescriptionHeadline.PrimaryText>
            <Skeleton width="100px" style={{ maxWidth: '100%' }} />
          </DescriptionHeadline.PrimaryText>
          <DescriptionHeadline.SecondaryText>
            <Skeleton width="400px" style={{ maxWidth: '100%' }} />
          </DescriptionHeadline.SecondaryText>
        </>
      ) : (
        children
      )}
    </DescriptionHeadline>
  );
};

export default EpisodeDescriptionHeadline;
