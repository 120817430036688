import BigPlayButton from 'components/BigPlayButton';
import { notifySuccess } from 'components/notification';
import ShareButton from 'components/ShareButton';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import { Duration } from 'dayjs/plugin/duration';
import { useAudioPlayer, useIsAudioLoaded } from 'pages/AudioPlayerPage/state';
import React, { useCallback, useMemo } from 'react';
import { copyToClipboard } from 'utils/clipboard';
import { formatFriendlyDuration } from 'utils/time';
import {
  useTldlJobCuid,
  useTldlTotalEpisodeDurationMillis,
} from '../../state/tldl';
import {
  useTldlAudioUrl,
  useTldlJobTargetDurationMillis,
} from '../../state/useTldlJobStatus';
import styles from './TldlEpisodeCardHeader.module.scss';
import TldlEpisodeCardHeaderContainer from './TldlEpisodeCardHeaderContainer';
import TldlHeaderH1 from './TldlHeaderH1';

export interface TldlProcessedHeaderProps {}

function getDurationFormat(d: Duration) {
  if (d.minutes() < 1) {
    const unit = d.seconds() > 1 ? 'seconds' : 'second';
    return `s [${unit}]`;
  }

  if (d.hours() < 1) {
    const unit = d.minutes() > 1 ? 'minutes' : 'minute';
    return `m [${unit}]`;
  }

  const unit = d.hours() > 1 ? 'hours' : 'hour';
  return `H [${unit}]`;
}

const TldlProcessedHeader: React.FC<TldlProcessedHeaderProps> = () => {
  const { md } = useBreakpoints();
  const { trackPlayTldlAudio, trackTldlShareButton } = useEventTracking();

  const durationMillis = useTldlTotalEpisodeDurationMillis();
  const jobCuid = useTldlJobCuid();

  const { data: targetDurationMillis = 0 } = useTldlJobTargetDurationMillis();
  const { data: url } = useTldlAudioUrl();

  const { controls: audioControls, loadTldlAudio, paused } = useAudioPlayer();
  const isAudioLoaded = useIsAudioLoaded({ jobCuid });

  const durationString = useMemo(
    () => formatFriendlyDuration(durationMillis, getDurationFormat),
    [durationMillis],
  );

  // all target durations right now are in minutes
  const targetDurationString = useMemo(
    () => formatFriendlyDuration(targetDurationMillis, 'm [minutes]'),
    [targetDurationMillis],
  );

  const handleShare = useCallback(() => {
    if (url) {
      trackTldlShareButton();
      copyToClipboard(window.location.href);
      notifySuccess({ heading: 'Link copied to clipboard!' });
    }
  }, [trackTldlShareButton, url]);

  const handlePlayButtonClick = useCallback(() => {
    if (!isAudioLoaded && jobCuid) {
      loadTldlAudio({ jobCuid });
      trackPlayTldlAudio(jobCuid);
    } else if (paused) {
      audioControls?.play();
    } else {
      audioControls?.pause();
    }
  }, [
    audioControls,
    isAudioLoaded,
    jobCuid,
    loadTldlAudio,
    paused,
    trackPlayTldlAudio,
  ]);

  return (
    <TldlEpisodeCardHeaderContainer align="center" justify="space-between">
      <BigPlayButton
        className={styles.bigPlayButton}
        playing={isAudioLoaded && !paused}
        onClick={handlePlayButtonClick}
        size="x-large"
      />
      <TldlHeaderH1>
        We summarized {durationString} into {targetDurationString}
      </TldlHeaderH1>
      <ShareButton
        className={styles.shareButton}
        variant={md ? 'icon' : 'icon-text'}
        onPress={handleShare}
      />
    </TldlEpisodeCardHeaderContainer>
  );
};

export default TldlProcessedHeader;
