/* eslint-disable no-console */
import { Spacer } from '@sparemin/blockhead';
import React from 'react';
import useTldlView from './state/useTldlView';
import TldlBetaIcon from './TldlBetaIcon';
import SummarizeMoreButton from './TldlEpisodesCard/SummarizeMoreButton';
import styles from './TldlPage.module.scss';
import TldlWizard from './TldlWizard';

export interface TldlPageProps {}

const TldlPage: React.FC<TldlPageProps> = () => {
  const view = useTldlView();

  return (
    <Spacer
      className={styles.contents}
      orientation="vertical"
      space="24px"
      align="center"
    >
      <TldlBetaIcon />
      <TldlWizard />
      {view === 'summarized' && <SummarizeMoreButton />}
    </Spacer>
  );
};

export default TldlPage;
