import { Heading } from '@sparemin/blockhead';
import React from 'react';
import TldlEpisodeCardHeaderContainer from './TldlEpisodeCardHeaderContainer';

export interface TldlProcessingHeaderProps {}

const TldlProcessingHeader: React.FC<TldlProcessingHeaderProps> = () => (
  <TldlEpisodeCardHeaderContainer
    align="center"
    orientation="vertical"
    space="16px"
  >
    <Heading level={1}>Creating your audio summary</Heading>
    <Heading level={2}>
      This process takes about 10 minutes, so hang tight!
    </Heading>
  </TldlEpisodeCardHeaderContainer>
);

export default TldlProcessingHeader;
