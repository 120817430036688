import { Button } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { createTldlPath } from 'utils/routes';
import styles from './SummarizeMoreButton.module.scss';

export interface SummarizeMoreButtonProps {}

const SummarizeMoreButton: React.FC<SummarizeMoreButtonProps> = () => {
  const [path] = createTldlPath();
  const { trackTldlSummarizeMore } = useEventTracking();

  const handlePress = useCallback(() => {
    trackTldlSummarizeMore();
  }, [trackTldlSummarizeMore]);

  return (
    <div className={styles.container}>
      <Button as={Link} onPress={handlePress} target="_blank" to={path}>
        summarize more episodes
      </Button>
    </div>
  );
};

export default SummarizeMoreButton;
