import {
  formatFriendlyDuration,
  ONE_HOUR_MILLIS,
  ONE_MINUTE_MILLIS,
} from 'utils/time';

function getFormat(millis: number) {
  if (millis < ONE_MINUTE_MILLIS) {
    return 'mm:ss';
  }

  if (millis < ONE_HOUR_MILLIS) {
    return 'm:ss';
  }

  return 'H:mm:ss';
}

export function formatDuration(millis: number) {
  return formatFriendlyDuration(millis, getFormat(millis));
}
