import { truthy } from '@sparemin/blockhead';
import { TldlEpisodeIdentifier } from 'pages/TldlPage/types';
import qs from 'query-string';
import { generatePath } from 'react-router-dom';
import { EpisodePageLocationData } from 'types';
import {
  EpisodeLocationKeys,
  PodcastLocationKeys,
  PodcastLegacyLocationKeys,
  LocationKeys,
  TldlLocationKeys,
} from './constants';
import { CreatePathResult, Path, CreateRedirectPathConfig } from './types';

const routes = {
  episodeDetails: `/podcast/:${EpisodeLocationKeys.PODCAST_ID}/episode/:${EpisodeLocationKeys.EPISODE_ID}`,
  episodeLegacy: `/episode/:${EpisodeLocationKeys.EPISODE_ID}`,
  home: '/',
  podcastDetails: `/podcast-details/:${PodcastLocationKeys.PODCAST_ID}`,
  podcastLegacy: `/podcast/:${PodcastLegacyLocationKeys.FEED_ID}`,
  resetPassword: `/reset-password`,
  tldl: `/tldl/:${TldlLocationKeys.JOB_CUID}?`,
} as const;

export const authModalParams = { modal: 'authentication' } as const;

export function createRedirectPath({
  destination,
  search,
  state,
}: CreateRedirectPathConfig): CreatePathResult {
  const [path] = destination;

  const searchParams = search ?? '';

  const newPath: Path = { pathname: path.pathname, search: searchParams };

  return [newPath, { state, replace: true }];
}

export function createEpisodePath(
  episodeId: string | number,
  podcastId: string | number,
  opts?: Partial<Omit<EpisodePageLocationData, 'episodeId' | 'internal'>>,
): CreatePathResult {
  const { startSec, source } = opts ?? {};

  const pathname = generatePath(routes.episodeDetails, {
    [EpisodeLocationKeys.PODCAST_ID]: encodeURIComponent(podcastId),
    [EpisodeLocationKeys.EPISODE_ID]: encodeURIComponent(episodeId),
  });

  const search = qs.stringify({
    [EpisodeLocationKeys.START_SEC]: startSec,
  });

  const path: Path = { pathname, search };

  const options = {
    state: {
      [LocationKeys.INTERNAL]: true,
      [EpisodeLocationKeys.SOURCE]: source,
    },
  };

  return [path, options];
}

export function createPodcastPath(podcastId: string): CreatePathResult {
  const pathname = generatePath(routes.podcastDetails, {
    [PodcastLocationKeys.PODCAST_ID]: podcastId,
  });

  const path: Path = { pathname, search: '' };

  const options = {
    state: {
      [LocationKeys.INTERNAL]: true,
    },
  };
  return [path, options];
}

type TldlPathOptions = {
  jobCuid?: string;
  episodes?: TldlEpisodeIdentifier[];
};

export function createTldlPath(opts?: TldlPathOptions): CreatePathResult {
  const { jobCuid, episodes } = opts ?? {};

  const pathname = generatePath(routes.tldl, { jobCuid: jobCuid ?? null });

  const options = {
    state: {
      [LocationKeys.INTERNAL]: true,
    },
  };

  const search =
    jobCuid || !episodes || episodes.length === 0
      ? ''
      : qs.stringify(
          {
            [TldlLocationKeys.EPISODES]: episodes.map(
              ({ podcastId, remoteEpisodeId }) =>
                `${podcastId},${remoteEpisodeId}`,
            ),
          },
          { arrayFormat: 'bracket' },
        );

  return [{ pathname, search }, options];
}

export function parseTldlQueryParams(search: string) {
  const params = qs.parse(search, { arrayFormat: 'bracket' });
  const episodes = params[TldlLocationKeys.EPISODES];

  const es = !episodes || !Array.isArray(episodes) ? [] : episodes;

  return {
    episodes: es.filter(truthy).map((e) => {
      const [podcastId, remoteEpisodeId] = e.split(',');
      return { podcastId, remoteEpisodeId };
    }),
  };
}

export default routes;
