import React from 'react';
import SearchButton from '../../components/AppHeaderTopbar/SearchButton';
import useOpenTldlSearch from './hooks/useOpenTldlSearch';
import { useIsTldlPreloading } from './state/tldl';
import { useIsTldlJobSubmitting } from './state/useSubmitTldlJob';
import useTldlView from './state/useTldlView';
import TldlEpisodeLimitReachedTooltip from './TldlEpisodeLimitReachedTooltip';

export interface TldlSearchButtonProps {}

const TldlSearchButton: React.FC<TldlSearchButtonProps> = () => {
  const { openTldlSearch } = useOpenTldlSearch();
  const view = useTldlView();
  const isSubmitting = useIsTldlJobSubmitting();
  const isPreloading = useIsTldlPreloading();

  return (
    <TldlEpisodeLimitReachedTooltip>
      {(limitReached) => (
        <SearchButton
          isDisabled={
            isPreloading ||
            isSubmitting ||
            limitReached ||
            (view !== 'landing' && view !== 'selection')
          }
          onOpenModal={openTldlSearch}
        />
      )}
    </TldlEpisodeLimitReachedTooltip>
  );
};

export default TldlSearchButton;
