import EpisodeDescription from 'components/EpisodeDescription';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { secToMillis } from 'utils/time';
import {
  getLinkProps,
  useCreateAutoplayEpisodePath,
  useCreateEpisodePath,
} from '../SearchModalConfigContext';
import { useOnSelectEpisode } from '../SearchModalDispatchContext';
import SearchResult from '../SearchResult';
import { SearchEpisode } from '../types';
import useSelectEpisodeSearchResult from './useSelectEpisodeSearchResult';

type EpisodeCallback = (episode: SearchEpisode) => void;

export type EpisodeSearchResultProps = {
  context?: 'podcast' | 'standalone';
  episode: SearchEpisode;
  onHover?: EpisodeCallback;
  onHoverLost?: EpisodeCallback;
};

const EpisodeSearchResult: React.FC<EpisodeSearchResultProps> = ({
  context = 'standalone',
  episode,
  onHover,
  onHoverLost,
}) => {
  const {
    id: episodeId,
    podcast: { id: podcastId, title: podcastTitle },
    title: episodeTitle,
  } = episode;

  const navigate = useNavigate();

  const { trackPlayback, trackSearchResultSelected } = useEventTracking();

  const onSelectEpisode = useOnSelectEpisode();

  const { selectEpisodeSearchResult } = useSelectEpisodeSearchResult();

  const createEpisodePath = useCreateEpisodePath();
  const createAutoplayEpisodePath = useCreateAutoplayEpisodePath();

  const link = getLinkProps(createEpisodePath(episodeId, podcastId));
  const autoplayPath = createAutoplayEpisodePath(episodeId, podcastId);

  const isLink = autoplayPath !== undefined;

  const handleBadgeClick = useCallback(
    (e: React.MouseEvent) => {
      trackPlayback('play', {
        source: 'SearchResult',
        podcastName: podcastTitle,
        episodeName: episodeTitle,
        podcastId,
        remoteEpisodeId: episodeId,
      });

      trackSearchResultSelected({
        podcastName: podcastTitle,
        episodeName: episodeTitle,
      });

      e.stopPropagation();
      e.preventDefault();

      onSelectEpisode?.(episode);

      if (autoplayPath) {
        navigate(...autoplayPath);
      }
    },
    [
      autoplayPath,
      episode,
      episodeId,
      episodeTitle,
      navigate,
      onSelectEpisode,
      podcastId,
      podcastTitle,
      trackPlayback,
      trackSearchResultSelected,
    ],
  );

  return (
    <SearchResult
      data={episode}
      onClick={selectEpisodeSearchResult}
      {...{ link, onHover, onHoverLost }}
    >
      <EpisodeDescription
        descriptionHtml={episode.description}
        durationMillis={secToMillis(episode.durationSec)}
        podcastName={episode.podcast.title}
        publishedAtMillis={episode.publishedAtMillis}
        thumbnailSrc={episode.thumbnailUrl}
        title={episode.title}
      >
        <EpisodeDescription.Header>
          <EpisodeDescription.Thumbnail />
          <EpisodeDescription.Headline>
            {context === 'standalone' ? (
              <>
                <EpisodeDescription.PodcastTitle />
                <EpisodeDescription.EpisodeTitle />
              </>
            ) : (
              <>
                <EpisodeDescription.EpisodeTitle />
                <EpisodeDescription.Metadata />
              </>
            )}
          </EpisodeDescription.Headline>
        </EpisodeDescription.Header>
        <EpisodeDescription.Description />
        {
          // if the result is not a link, then the autoplay button shouldn't
          // render.  if the context is 'podcast', then the publish date was
          // already included in the headline and there's no need for a footer
          !isLink && context === 'podcast' ? null : (
            <EpisodeDescription.Footer>
              {isLink && (
                <EpisodeDescription.PlayButton
                  onClickCapture={handleBadgeClick}
                />
              )}
              {context === 'standalone' && <EpisodeDescription.PublishedAt />}
            </EpisodeDescription.Footer>
          )
        }
      </EpisodeDescription>
    </SearchResult>
  );
};

export default EpisodeSearchResult;
