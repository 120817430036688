import { debounce } from 'lodash';
import { useMemo, useState } from 'react';

export interface UseSearchInput {
  isSearchActive: boolean;
  searchQuery: string;
}

export interface UseSearchInputConfig {
  searchInputValue: string;
}

// Suggested debounce time at spec
const SEARCH_VALUE_UPDATE_DEBOUNCE_MS = 1000;

const useSearchInput = (config: UseSearchInputConfig): UseSearchInput => {
  const { searchInputValue } = config;

  const [searchQueryData, setSearchQueryData] = useState({
    isSearchActive: !!searchInputValue,
    value: searchInputValue,
  });

  const [prevSearchInputValue, setPrevSearchInputValue] =
    useState(searchInputValue);

  const searchValueUpdater = useMemo(
    () =>
      debounce(
        (nextValue: string): void => {
          setSearchQueryData({
            isSearchActive: !!nextValue,
            value: nextValue,
          });
        },
        SEARCH_VALUE_UPDATE_DEBOUNCE_MS,
        { trailing: true },
      ),
    [],
  );

  if (prevSearchInputValue !== searchInputValue) {
    setSearchQueryData({
      isSearchActive: true,
      value: '',
    });
    setPrevSearchInputValue(searchInputValue);
    searchValueUpdater(searchInputValue);
  }

  return {
    isSearchActive: searchQueryData.isSearchActive,
    searchQuery: searchQueryData.value,
  };
};

export default useSearchInput;
