import {
  PodcastRefreshStatus,
  PodpressJobDetail,
  PodpressJobEpisode,
} from '@sparemin/api-sdk';
import memoize from 'memoizee';
import { millisToSec } from 'utils/time';

const RESOLVED_PODCAST_REFRESH_STATUSES: PodcastRefreshStatus[] = [
  'completed',
  'error',
];

export function isTldlStatusResolved(status: PodcastRefreshStatus | undefined) {
  if (!status) {
    return false;
  }

  return RESOLVED_PODCAST_REFRESH_STATUSES.includes(status);
}

export const tldlJobProgressSelector = (d: PodpressJobDetail) => d.progress;

export const tldlJobStatusSelector = (d: PodpressJobDetail) => d.status;

export const tldlJobInputsSelector = (d: PodpressJobDetail) => d.jobInputs;

const tldlJobOutputSelector = (d: PodpressJobDetail) => d.jobOutput;

export const tldlChaptersSelector = (d: PodpressJobDetail) =>
  tldlJobOutputSelector(d)?.chapters;

const createInputIds = memoize(
  (inputs: PodpressJobEpisode[]) =>
    inputs.map((i) => ({
      podcastId: i.podcastId,
      remoteEpisodeId: i.remoteEpisodeId,
    })),
  { max: 1 },
);

export const tldlJobInputsIdsSelector = (d: PodpressJobDetail) => {
  const inputs = tldlJobInputsSelector(d);
  return createInputIds(inputs);
};

export const tldlSummarizedAudioUrlSelector = (d: PodpressJobDetail) =>
  d.jobOutput?.audioUrl;

export const tldlIsJobProcessingSelector = (
  d: PodpressJobDetail | undefined,
) => {
  if (!d) {
    return false;
  }

  const status = tldlJobStatusSelector(d);
  return !isTldlStatusResolved(status);
};

const tldlJobEpisodeSelector = (
  d: PodpressJobDetail,
  podcastId: string,
  remoteEpisodeId: string,
) =>
  d.jobInputs.find(
    (input) =>
      input.podcastId === podcastId &&
      input.remoteEpisodeId === remoteEpisodeId,
  );

export const tldlJobEpisodeStatusSelector = (
  d: PodpressJobDetail,
  podcastId: string,
  remoteEpisodeId: string,
) => tldlJobEpisodeSelector(d, podcastId, remoteEpisodeId)?.status;

export const tldlJobIsEpisodeProcessingSelector = (
  d: PodpressJobDetail,
  podcastId: string,
  remoteEpisodeId: string,
) => {
  const status = tldlJobEpisodeStatusSelector(d, podcastId, remoteEpisodeId);
  return !isTldlStatusResolved(status);
};

export const tldlAudioPlayerChaptersSelector = (d: PodpressJobDetail) => {
  const chapters = tldlChaptersSelector(d);
  return chapters?.map((chapter, i, ary) => ({
    headline: '',
    endSec: millisToSec(ary[i + 1]?.startMillis),
    id: i,
    startSec: millisToSec(chapter.startMillis),
  }));
};

export const tldlJobTargetDurationMillisSelector = (d: PodpressJobDetail) =>
  d.targetDurationMillis;
