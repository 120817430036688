import { DescriptionMetadata } from '@sparemin/blockhead';
import React from 'react';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export interface EpisodeDescriptionPublisedAtProps {}

const EpisodeDescriptionPublisedAt: React.FC<
  EpisodeDescriptionPublisedAtProps
> = () => {
  const { publishedAtMillis } = useEpisodeDescription();

  return (
    <DescriptionMetadata>
      <DescriptionMetadata.Date epochMillis={publishedAtMillis} />
    </DescriptionMetadata>
  );
};

export default EpisodeDescriptionPublisedAt;
