import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import AppHeader, {
  AppHeaderArtwork,
  AppHeaderContents,
  AppHeaderDescription,
  AppHeaderFooter,
  AppHeaderSubtitle,
  AppHeaderTitle,
  AppHeaderUserButtons,
  HeaderState,
  useAppHeaderAnimation,
} from 'components/AppHeader';
import { PodcastSearchNavigationButton } from 'components/AppHeaderTopbar';
import BigPlayButton from 'components/BigPlayButton';
import UserAccountControl from 'components/UserAccountControl';
import { useBreakpoints } from 'context/BreakpointsContext';
import { motion } from 'framer-motion';
import useDate from 'hooks/useDate';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { Link } from 'react-router-dom';
import { createPodcastPath } from 'utils/routes';
import { formatFriendlyDuration } from 'utils/time';
import { useEpisodeDetails } from '../state';
import { useAnimation } from './AnimationContext';
import EpisodeShareButton from './EpisodeShareButton';
import styles from './Header.module.scss';
import useHeaderPodcastLink from './useHeaderPodcastLink';
import useLoadAudioOnClick from './useLoadAudioOnClick';

export interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const { data } = useEpisodeDetails();

  const { activeVariant } = useAppHeaderAnimation();
  const { md } = useBreakpoints();
  const { paused, episodeId: playerEpisodeId } = useAudioPlayer();
  const animation = useAnimation();
  const formattedDate = useDate(data?.publishedAtMillis);

  const {
    titleRef,
    onContentsMouseOut,
    onContentsMouseOver,
    onLinkClick,
    playButtonVisible,
  } = useHeaderPodcastLink<HTMLHeadingElement>();

  const hasData = !!data;
  const durationMillis = data?.durationMillis;
  const podcastTitle = data?.podcastTitle;
  const feedId = data?.feedId;

  const { handleClick } = useLoadAudioOnClick({
    podcastId: data?.remotePodcastId,
    episodeId: data?.episodeId,
  });

  const clickable = !md && activeVariant !== HeaderState.CONDENSED;

  const [path, pathOpts] = data?.remotePodcastId
    ? createPodcastPath(data?.remotePodcastId)
    : [''];

  const isPlaying = !paused && data?.episodeId === playerEpisodeId;

  const playButton = (
    <BigPlayButton
      className={cn(styles.playButton, {
        // https://bitbucket.org/sparemin/%7Bfaac3fc1-ac22-41ad-ae82-8b053baaed26%7D/pull-requests/73#comment-330686784
        [styles.playButton__visible]: playButtonVisible,
      })}
      as={motion.button}
      onClick={md ? handleClick : undefined}
      playing={isPlaying}
      size="x-large"
      {...animation.playButton}
    />
  );

  const userButtons = feedId ? (
    <AppHeaderUserButtons
      source="EpisodePage"
      feedId={feedId}
      itunesUrl={data.itunesUrl}
      podcastName={podcastTitle as string}
      spotifyUrl={data.spotifyUrl}
      shareButton={<EpisodeShareButton />}
    />
  ) : null;

  return (
    <AppHeader
      backgroundImageUrl={data?.thumbnailImageUrl}
      topCenterElement={<PodcastSearchNavigationButton />}
      topRightElement={<UserAccountControl />}
    >
      <AppHeaderContents
        className={cn(styles.contents, {
          [styles.contents__clickable]: clickable,
        })}
        onClick={clickable ? handleClick : undefined}
        onMouseOut={onContentsMouseOut}
        onMouseOver={onContentsMouseOver}
      >
        <AppHeaderArtwork src={data?.thumbnailImageUrl}>
          {!md && playButton}
        </AppHeaderArtwork>
        <Spacer
          className={styles.headerContents}
          justify="space-between"
          align="center"
        >
          <AppHeaderDescription
            isLoading={!data}
            footer={
              <AppHeaderFooter
                parts={
                  !hasData
                    ? undefined
                    : [
                        formattedDate as string,
                        durationMillis === undefined
                          ? '--'
                          : formatFriendlyDuration(durationMillis),
                      ]
                }
              />
            }
            subtitle={
              <AppHeaderSubtitle>{data?.episodeTitle}</AppHeaderSubtitle>
            }
            title={
              <Link
                className={styles.podcastLink}
                onClick={onLinkClick}
                state={pathOpts}
                to={path}
              >
                <AppHeaderTitle className={styles.podcast} ref={titleRef}>
                  {data?.podcastTitle}
                </AppHeaderTitle>
              </Link>
            }
          >
            {md && playButton}
          </AppHeaderDescription>
          {!md ? (
            <motion.div {...animation.buttonBox}>{userButtons}</motion.div>
          ) : null}
        </Spacer>
      </AppHeaderContents>
      {md ? userButtons : null}
    </AppHeader>
  );
};

export default Header;
