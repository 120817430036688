import { Heading } from '@sparemin/blockhead';
import HeaderTextLink from 'components/HeaderTextLink';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { isAudioPlayerLink } from 'pages/AudioPlayerPage/state/AudioPlayerContext/utils';
import useNowPlayingCard from 'pages/AudioPlayerPage/state/NowPlayingCardContext';
import React, { useCallback } from 'react';
import styles from './PodcastTitle.module.scss';

type PodcastTitleProps = {};

const PodcastTitle: React.FC<PodcastTitleProps> = () => {
  const { subtitle } = useAudioPlayer();
  const { hide } = useNowPlayingCard();

  const isLinkConfig = isAudioPlayerLink(subtitle);

  const handlePodcastLinkClick = useCallback(() => {
    hide();

    if (isLinkConfig) {
      subtitle.onClick?.();
    }
  }, [hide, isLinkConfig, subtitle]);

  return isLinkConfig ? (
    <HeaderTextLink
      className={styles.root}
      linkClassName={styles.link}
      level={2}
      onPress={handlePodcastLinkClick}
      path={subtitle.path}
      pathOpts={subtitle?.navOpts}
      title={subtitle.value}
    />
  ) : (
    <Heading level={2} className={styles.root}>
      {subtitle}
    </Heading>
  );
};
export default PodcastTitle;
