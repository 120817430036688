import { isTldlStatusResolved } from 'api/selectors/tldl';
import { TldlEpisodeView } from './types';
import { useTldlJobStatus } from './useTldlJobStatus';

/**
 * Returns an enum that describes the different states an episode row can be in
 */
export default function useTldlEpisodeView(): TldlEpisodeView {
  const { data: jobStatus } = useTldlJobStatus();

  if (jobStatus === 'completed') {
    return 'summarized';
  }

  if (jobStatus && !isTldlStatusResolved(jobStatus)) {
    return 'summarizing';
  }

  return 'selection';
}
