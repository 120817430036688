import { Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import LightningGradientCircle from 'components/icons/LightningGradientCircle';
import OpenSponsoredEpisodeModalButton from 'components/OpenSponsoredEpisodeModalButton';
import { useBreakpoints } from 'context/BreakpointsContext';
import useListeningQueue from 'pages/AudioPlayerPage/state/ListeningQueueContext';
import React, { useCallback } from 'react';
import { useAudioPlayer } from '../../state/AudioPlayerContext/AudioPlayerContext';
import useNowPlayingCard from '../../state/NowPlayingCardContext';
import AudioPlayerControls from '../AudioPlayerControls/AudioPlayerControls';
import PlaybackRateMenu from '../PlaybackRateMenu';
import PlaybackTime from '../PlaybackTime';
import PodcastData from '../PodcastData';
import ProgressBar from '../ProgressBar';
import QueueButton from '../QueueButton';
import styles from './StickyAudioPlayerUi.module.scss';

type StickyAudioPlayerUiProps = {};

const StickyAudioPlayerUi: React.FC<StickyAudioPlayerUiProps> = () => {
  const { md } = useBreakpoints();
  const { chapters, isSponsored: isEpisodeSponsored } = useAudioPlayer();
  const { queue } = useListeningQueue();

  const { open: showNowPlayingCard } = useNowPlayingCard();

  const handleOpenNowPlayingCard = useCallback(() => {
    showNowPlayingCard();
  }, [showNowPlayingCard]);

  const sponsoredIcon = md ? (
    <LightningGradientCircle className={styles.icon} />
  ) : (
    <OpenSponsoredEpisodeModalButton />
  );

  return (
    <>
      {md && (
        <button
          type="button"
          aria-label="open now playing card"
          onClick={handleOpenNowPlayingCard}
          className={styles.overlay}
        />
      )}
      <ProgressBar chapters={chapters} />
      <Spacer align="center" space={1.4} className={styles.playbar}>
        <div className={styles.segment}>
          <Spacer space={1} justify="center" align="center">
            <PodcastData />
            {isEpisodeSponsored && <span>{sponsoredIcon}</span>}
          </Spacer>
        </div>
        <div className={cn(styles.segment, styles.segment__playback)}>
          <AudioPlayerControls className={styles.controls} />
        </div>
        <div className={cn(styles.segment, styles.segment__time)}>
          <Spacer space={3} align="center">
            <PlaybackTime />
            <PlaybackRateMenu />
            {!!queue && <QueueButton />}
          </Spacer>
        </div>
      </Spacer>
    </>
  );
};
export default StickyAudioPlayerUi;
