import SearchModal from 'components/SearchModal';
import { useModalControls } from 'context/ModalContext';
import React from 'react';
import TldlSearchModalBanner from './TldlSearchModalBanner';

export interface TldlSearchModalProps {}

const TldlSearchModal: React.FC<TldlSearchModalProps> = () => {
  const { close } = useModalControls();

  return (
    <SearchModal
      banner={<TldlSearchModalBanner />}
      name="TldlSearch"
      onSelectEpisode={close}
    />
  );
};

export default TldlSearchModal;
