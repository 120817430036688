import cn from 'classnames';
import React from 'react';
import styles from './VideoBackground.module.scss';

export type VideoBackgroundProps = {
  className?: string;
  src?: string;
};

const VideoBackground: React.FC<VideoBackgroundProps> = ({
  className,
  src,
}) => (
  <video
    autoPlay
    className={cn(styles.video, className)}
    muted
    loop
    playsInline
    src={src}
  />
);

export default VideoBackground;
