import useTldlEpisodeView from 'pages/TldlPage/state/useTldlEpisodeView';
import React from 'react';
import TldlDeleteEpisodeButton from './TldlDeleteEpisodeButton';
import TldlFullEpisodeButton from './TldlFullEpisodeButton';

export type TldlEpisodeRowDynamicActionSlotProps = {
  episodeId: string;
};

const TldlEpisodeRowDynamicActionSlot: React.FC<
  TldlEpisodeRowDynamicActionSlotProps
> = ({ episodeId }) => {
  const view = useTldlEpisodeView();

  if (view === 'selection') {
    return <TldlDeleteEpisodeButton episodeId={episodeId} />;
  }

  if (view === 'summarizing') {
    return null;
  }

  return <TldlFullEpisodeButton episodeId={episodeId} />;
};

export default TldlEpisodeRowDynamicActionSlot;
