import { useEventTracking } from 'context/EventTrackingContext';
import useGetTitles from 'hooks/useGetTitlesFromIds';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import { getEpisodeListeningProgress } from 'pages/AudioPlayerPage/state/listeningProgress';
import { useCallback, useMemo } from 'react';
import { useEpisodePage } from '../EpisodePageContext';
import useSponsoredEpisodeFromQueue from '../hooks/useSponsoredEpisodeFromQueue';

export interface UseLoadAudioOnClickConfig {
  episodeId: string | undefined;
  podcastId: string | undefined;
}
export default function useLoadAudioOnClick({
  podcastId,
  episodeId,
}: UseLoadAudioOnClickConfig) {
  const {
    controls,
    episodeId: playerEpisodeId,
    paused,
    loadEpisodeAudio,
  } = useAudioPlayer();

  const { source } = useEpisodePage();

  const { trackPlayback, trackSponsoredEpisodePlayed } = useEventTracking();

  const { podcastName, episodeName } = useGetTitles({ podcastId, episodeId });
  const { isSponsored, widgetInfo } = useSponsoredEpisodeFromQueue({
    episodeId,
    podcastId,
    source,
  });

  const episodeInfo = useMemo(() => {
    if (podcastName && episodeName && podcastId && episodeId)
      return {
        podcastId,
        remoteEpisodeId: episodeId,
        podcastName,
        episodeName,
      };
    return undefined;
  }, [podcastId, episodeId, podcastName, episodeName]);

  const handleRequestPlay = useCallback(() => {
    if (episodeId !== playerEpisodeId && episodeId) {
      const startTime = getEpisodeListeningProgress(episodeId);
      loadEpisodeAudio({
        episodeId,
        podcastId,
        startSec: startTime,
        isSponsored,
        sponsoredWidgetInfo: widgetInfo,
      });

      if (isSponsored && widgetInfo) {
        trackSponsoredEpisodePlayed(widgetInfo);
      }

      if (episodeInfo) {
        trackPlayback('play', {
          source: 'HeaderButton',
          ...episodeInfo,
        });
      }
    } else {
      controls?.togglePlay({ scroll: true });

      if (episodeInfo) {
        trackPlayback(paused ? 'play' : 'pause', {
          source: 'HeaderButton',
          ...episodeInfo,
        });
      }
    }
  }, [
    episodeId,
    playerEpisodeId,
    loadEpisodeAudio,
    trackSponsoredEpisodePlayed,
    isSponsored,
    widgetInfo,
    podcastId,
    episodeInfo,
    trackPlayback,
    controls,
    paused,
  ]);

  return { handleClick: handleRequestPlay };
}
