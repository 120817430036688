import { Description } from '@sparemin/blockhead';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export type EpisodeDescriptionFooterProps = {
  children?: React.ReactNode;
};

const EpisodeDescriptionFooter: React.FC<EpisodeDescriptionFooterProps> = ({
  children,
}) => {
  const { isLoading } = useEpisodeDescription();

  return (
    <Description.Footer>
      {isLoading ? <Skeleton width="100px" /> : children}
    </Description.Footer>
  );
};

export default EpisodeDescriptionFooter;
