import {
  saveEpisodeListeningProgress,
  deleteEpisodeFromListeningProgress,
} from 'pages/AudioPlayerPage/state/listeningProgress';
import { useCallback, useEffect } from 'react';
import { BaseAudioPlayer } from 'utils/audio/types';

type UsePersistListeningProgressConfig = {
  player?: BaseAudioPlayer;
  episodeId?: string;
};

export default function usePersistListeningProgress({
  episodeId,
  player,
}: UsePersistListeningProgressConfig) {
  const handleEpisodeEnded = useCallback(() => {
    if (episodeId) {
      deleteEpisodeFromListeningProgress(episodeId);
    }
  }, [episodeId]);

  useEffect(() => {
    const handleTimeupdate = () => {
      const currentTime = player?.currentTime();
      if (episodeId && currentTime) {
        saveEpisodeListeningProgress(episodeId, currentTime);
      }
    };

    player?.on('timeupdate', handleTimeupdate);

    return () => {
      player?.off('timeupdate', handleTimeupdate);
    };
  }, [episodeId, player]);

  useEffect(() => {
    player?.on('ended', handleEpisodeEnded);

    return () => {
      player?.off('ended', handleEpisodeEnded);
    };
  }, [handleEpisodeEnded, player]);
}
