import { IconButton, Trash } from '@sparemin/blockhead';
import { useTldlActions } from 'pages/TldlPage/state/tldl';
import React, { useCallback } from 'react';
import styles from './TldlEpisodeRowDynamicActionSlot.module.scss';

export type TldlDeleteEpisodeButtonProps = {
  episodeId: string;
};

const TldlDeleteEpisodeButton: React.FC<TldlDeleteEpisodeButtonProps> = ({
  episodeId,
}) => {
  const { removeEpisode } = useTldlActions();

  const handleDelete = useCallback(() => {
    removeEpisode(episodeId);
  }, [episodeId, removeEpisode]);

  return (
    <div className={styles.deleteButtonContainer}>
      <IconButton
        className={styles.deleteButton}
        color="tertiary"
        onPress={handleDelete}
      >
        <Trash style={{ height: 12 }} />
      </IconButton>
    </div>
  );
};

export default TldlDeleteEpisodeButton;
