import { truthy } from '@sparemin/blockhead';
import { episodeQueryKeys } from 'api/hooks/queries';
import {
  GetPodcastEpisodeDetailResponse,
  getPodcastEpisodeDetails,
} from 'api/podcast';
import { AxiosError } from 'axios';
import { QueryFunctionContext, useQueries, UseQueryOptions } from 'react-query';
import { combineQueryStatus } from 'utils/query';
import { TldlEpisodeIdentifier } from '../types';

type QueryKey = ReturnType<typeof episodeQueryKeys.details>;

type Query<TData = GetPodcastEpisodeDetailResponse> = UseQueryOptions<
  GetPodcastEpisodeDetailResponse,
  AxiosError | unknown,
  TData,
  QueryKey
>;

type UseTldlEpisodesConfig<TData = GetPodcastEpisodeDetailResponse> = {
  enabled?: boolean;
  ids?: TldlEpisodeIdentifier[];
  select?: (data: GetPodcastEpisodeDetailResponse) => TData;
};

function queryFn({ queryKey: [key] }: QueryFunctionContext<QueryKey>) {
  return getPodcastEpisodeDetails(
    key.episodeId as string,
    key.podcastId as string,
    {
      includeChapters: true,
      includeDescription: true,
    },
  );
}

// a query hook that handles fetching multiple episodes at once
export function useTldlEpisodes<TData = GetPodcastEpisodeDetailResponse>({
  enabled = true,
  ids = [],
  select,
}: UseTldlEpisodesConfig<TData>) {
  const queries: Array<Query<TData>> = ids.map(
    ({ podcastId, remoteEpisodeId }) => ({
      queryFn,
      queryKey: episodeQueryKeys.details(remoteEpisodeId, podcastId),
      enabled: enabled && !!podcastId && !!remoteEpisodeId,
      select,
    }),
  );

  const result = useQueries(queries);

  return {
    data: result.map((r) => r.data).filter(truthy),
    status: combineQueryStatus(result.map((r) => r.status)),
  };
}
