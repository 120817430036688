import { Button, ButtonProps } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import { useWithAuthentication } from 'hooks/useWithAuthentication';
import {
  useTargetDurationMillis,
  useTldlEpisodeCount,
} from 'pages/TldlPage/state/tldl';
import useSubmitTldlJob, {
  useIsTldlJobSubmitting,
} from 'pages/TldlPage/state/useSubmitTldlJob';
import React, { useMemo } from 'react';
import { millisToMin } from 'utils/time';

export type TldlIdleFooterProps = Pick<ButtonProps, 'className'>;

const TldlIdleFooter: React.FC<TldlIdleFooterProps> = ({ className }) => {
  const { createAuthenticatedHandler } = useWithAuthentication();
  const { mutate } = useSubmitTldlJob();
  const { trackTldlSummarizeEpisodes } = useEventTracking();
  const isSubmitting = useIsTldlJobSubmitting();
  const episodeCount = useTldlEpisodeCount();
  const targetDurationMillis = useTargetDurationMillis();

  const handlePress = useMemo(
    () =>
      createAuthenticatedHandler(() => {
        trackTldlSummarizeEpisodes({
          episodeCount,
          targetDurationMins: millisToMin(targetDurationMillis),
        });
        mutate();
      }),
    [
      createAuthenticatedHandler,
      episodeCount,
      mutate,
      targetDurationMillis,
      trackTldlSummarizeEpisodes,
    ],
  );

  return (
    <Button
      className={className}
      isDisabled={isSubmitting}
      onPress={handlePress}
    >
      create an audio summary of these episodes
    </Button>
  );
};

export default TldlIdleFooter;
