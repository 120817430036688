import {
  HEADLINER_USER_SERVICE_URL,
  PLAY_SERVICE_URL,
  PODCAST_SERVICE_URL,
} from 'config';
// @ts-ignore
import breakpoints from 'styles/theme/_breakpoints.scss';
// @ts-ignore
import styleConstants from 'styles/theme/_constants.scss';
// @ts-ignore
import palette from 'styles/theme/_palette.scss';

// app
export const APP_ROOT_ID = 'root';

// responsiveness
export const BREAKPOINT_XS_PX = parseInt(breakpoints.xs, 10);
export const BREAKPOINT_MD_PX = parseInt(breakpoints.md, 10);
export const XS_SCREEN_MEDIA_QUERY = `only screen and (max-width: ${BREAKPOINT_XS_PX}px)`;
export const MD_SCREEN_MEDIA_QUERY = `only screen and (max-width: ${BREAKPOINT_MD_PX}px)`;

// services
export const DEFAULT_POLLING_INTERVAL_MILLIS = 2000;
export const PLAY_SERVICE_V1_URL = `${PLAY_SERVICE_URL}/api/v1/play`;
export const PODCAST_SERVICE_V1_URL = `${PODCAST_SERVICE_URL}/api/v1/podcast`;
export const PODCAST_SERVICE_V2_URL = `${PODCAST_SERVICE_URL}/api/v2/podcast`;
export const HEADLINER_USER_SERVICE_V1_URL = `${HEADLINER_USER_SERVICE_URL}/api/v1/headliner-user`;

// audio player
export const PLAYER_SKIP_SECONDS = 15;

// colors
export const PALETTE = palette;
export const SKELETON_BASE_COLOR = '#646464';
export const SKELETON_HIGHLIGHT_COLOR = '#979797';

// css constants
export const STYLE_CONSTANTS = styleConstants;

// animations and transitions
// these are just some default, commonly used transition values throughout the app.
// some components might have to use different values to achieve the desired animation
export const ANIMATION_DURATION_LONG_SEC = 0.6;
export const ANIMATION_DURATION_MEDIUM_SEC = ANIMATION_DURATION_LONG_SEC / 2;
export const ANIMATION_DURATION_SHORT_SEC = ANIMATION_DURATION_MEDIUM_SEC / 2;
export const AUTOPLAY_DELAY_SEC = 0.9;

// URLs
export const DISCO_HOMEPAGE_URL = 'https://www.headliner.app/disco';
export const HOMEPAGE_HIGHLIGHT_CLIP_SUBMISSION_FORM =
  'https://docs.google.com/forms/d/e/1FAIpQLSeBmswzEp4FIMiFxfD0f7h79X3JK9HQLA7VlrCuX92A1ROg_Q/viewform';
export const DASHBOARD_PROMO_URL =
  'https://dashboard.headliner.app/dashboard/disco-ads/create-ad';

// pre-render
export const PRERENDER_SERVICE_USER_AGENT =
  'Headliner/1.0.0 +https://headliner.app';

// local storage
export const SESSION_ID_KEY = 'sessionId';
export const LISTENING_PROGRESS_KEY = 'disco_play_listeningProgress';

// messages
export const GENERIC_ERROR_MESSAGE = 'Something went wrong, please try again.';

// TLDL
export const TLDL_BACKGROUND_VIDEO_URL =
  'https://static.sparemin.com/static/play/tldl-background-202503062136.mp4';

export const TLDL_MAX_EPISODE_COUNT = 5;
