import React, { useContext, useMemo } from 'react';
import { ModalContentsContextType, ModalContentsProviderProps } from './types';

const ModalContentsContext = React.createContext<
  ModalContentsContextType | undefined
>(undefined);

export const ModalContentsProvider: React.FC<ModalContentsProviderProps> = ({
  children,
  params,
  onRequestClose,
}) => (
  <ModalContentsContext.Provider
    value={useMemo(
      () => ({ params, onRequestClose }),
      [params, onRequestClose],
    )}
  >
    {children}
  </ModalContentsContext.Provider>
);

export function useModalContents<T>(): {
  params: T;
  onRequestClose?: ModalContentsContextType['onRequestClose'];
} {
  const context = useContext(ModalContentsContext);

  if (context === undefined) {
    throw new Error(
      'useModalContents must be used within ModalContentsProvider',
    );
  }

  return context;
}
