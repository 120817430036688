import { useCallback, useEffect, useMemo } from 'react';
import { getChapterStartSec } from 'utils/chapters';
import { clamp } from 'utils/math';
import {
  AudioPlayerContextType,
  AudioPlayerProviderProps,
  Controls,
} from './types';
import useAudioPlayerControls from './useAudioPlayerControls';
import useAudioPlayerEvents from './useAudioPlayerEvents/useAudioPlayerEvents';
import useAudioPlayerState from './useAudioPlayerState';
import { ScrollConfig } from './useAudioPlayerState/types';
import { indexOfChapterById, indexOfChapterByTime } from './utils';

export type UseAudioPlayerContextValueConfig = AudioPlayerProviderProps;

export default function useAudioPlayerContextValue({
  chapters,
  defaultDuration,
  player,
  isSponsored,
  playedFromQueueOverride,
  src,
  subtitle,
  title,
  thumbnailUrl,
}: UseAudioPlayerContextValueConfig): AudioPlayerContextType {
  useEffect(() => () => player?.pause(), [player]);

  const [state, dispatch] = useAudioPlayerState({
    chapters,
    defaultDuration,
    player,
  });
  const controls = useAudioPlayerControls({ chapters, player });
  const events = useAudioPlayerEvents(player);

  const scrollToChapter = useCallback(
    (
      chapterIndex: number | undefined,
      opts?: Omit<Partial<ScrollConfig>, 'scroll'>,
    ) => {
      if (chapterIndex !== undefined) {
        dispatch({ type: 'scrollchapter', payload: { chapterIndex, ...opts } });
      }
    },
    [dispatch],
  );

  const seekAndPlayChapter = useCallback(
    async (
      chapterIndex: number,
      scroll?: Partial<ScrollConfig>,
    ): Promise<void> => {
      const chapter = chapters?.[chapterIndex];

      if (chapter && controls) {
        const timeToSeek = getChapterStartSec(chapter.startSec);

        controls.seek(timeToSeek);

        dispatch({
          type: 'playchapter',
          payload: {
            chapterIndex,
            scroll,
            chapters,
          },
        });

        if (state.paused) {
          return controls.play();
        }
      }

      return undefined;
    },
    [chapters, controls, dispatch, state.paused],
  );

  // these actions use elements from 2 or more hooks which is why they live here
  // rather than in the respective hooks
  const handleNextChapter = useCallback(
    async (scroll?: Partial<ScrollConfig>) => {
      if (!controls || !chapters) return undefined;

      const index = indexOfChapterByTime(controls.currentTime(), chapters);
      const nextChapterIndex = clamp(index + 1, 0, chapters.length - 1);
      return seekAndPlayChapter(nextChapterIndex, scroll);
    },
    [chapters, controls, seekAndPlayChapter],
  );

  const handlePlay = useCallback(
    async (scroll?: Partial<ScrollConfig>) => {
      if (!controls) return undefined;

      const result = controls.play();

      const chapterIndex = indexOfChapterByTime(
        controls.currentTime(),
        chapters,
      );

      dispatch({
        type: 'play',
        payload: !scroll
          ? {}
          : {
              chapterIndex,
              scroll,
            },
      });

      return result;
    },
    [chapters, controls, dispatch],
  );

  const handlePlayChapter = useCallback(
    async (chapterId: number, scroll?: Partial<ScrollConfig>) => {
      if (!controls || !chapters) return undefined;

      const chapterIndex = indexOfChapterById(chapterId, chapters);
      return seekAndPlayChapter(chapterIndex, scroll);
    },
    [chapters, controls, seekAndPlayChapter],
  );

  const handlePrevChapter = useCallback(
    async (scroll?: Partial<ScrollConfig>) => {
      if (!controls || !chapters) return undefined;

      const chapterIndex = indexOfChapterByTime(
        controls.currentTime(),
        chapters,
      );
      const nextIndex = clamp(chapterIndex - 1, 0, chapters.length - 1);
      return seekAndPlayChapter(nextIndex, scroll);
    },
    [chapters, controls, seekAndPlayChapter],
  );

  const handleTogglePlay = useCallback(
    async (scroll?: Partial<ScrollConfig>) => {
      if (!controls) return;

      const wasPaused = state.paused;

      controls.togglePlay();

      if (scroll && wasPaused) {
        const chapterIndex = indexOfChapterByTime(
          controls.currentTime(),
          chapters,
        );
        scrollToChapter(chapterIndex, scroll);
      }
    },
    [chapters, controls, scrollToChapter, state.paused],
  );

  const augmentedControls: Controls | undefined = useMemo(
    () =>
      !controls
        ? undefined
        : {
            ...controls,
            nextChapter: handleNextChapter,
            play: handlePlay,
            playChapter: handlePlayChapter,
            prevChapter: handlePrevChapter,
            togglePlay: handleTogglePlay,
          },
    [
      controls,
      handleNextChapter,
      handlePlay,
      handlePlayChapter,
      handlePrevChapter,
      handleTogglePlay,
    ],
  );

  return useMemo(
    () => ({
      chapters,
      events,
      player,
      controls: augmentedControls,
      isSponsored,
      playedFromQueueOverride,
      src,
      subtitle,
      title,
      thumbnailUrl,
      ...state,
    }),
    [
      augmentedControls,
      chapters,
      events,
      isSponsored,
      playedFromQueueOverride,
      player,
      src,
      state,
      subtitle,
      thumbnailUrl,
      title,
    ],
  );
}
