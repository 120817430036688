import { Chip, Clock, Heading, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import { useBreakpoints } from 'context/BreakpointsContext';
import React, { useMemo } from 'react';
import { useTldlTotalEpisodeDurationMillis } from '../state/tldl';
import styles from './TldlDurationBadge.module.scss';
import { formatDuration } from './utils';

export type TldlDurationBadgeProps = {
  className?: string;
};

const TldlDurationBadge: React.FC<TldlDurationBadgeProps> = ({ className }) => {
  const { md } = useBreakpoints();

  const durationMillis = useTldlTotalEpisodeDurationMillis();
  const durationString = useMemo(
    () => formatDuration(durationMillis),
    [durationMillis],
  );

  return (
    <Chip className={cn(styles.badge, className)} permanent>
      <Spacer align="center" orientation="horizontal" space="10px">
        <Clock className={styles.icon} />
        <Heading className={styles.text} level={md ? 4 : 2}>
          {durationString}
        </Heading>
      </Spacer>
    </Chip>
  );
};

export default TldlDurationBadge;
