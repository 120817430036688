import useGetPodcastEpisodeDetail from 'api/hooks/useGetPodcastEpisodeDetail';
import { podcastEpisodeFeedIdSelector } from 'api/selectors/podcast';
import { useAudioPlayer } from '../state';

const useGetPodcastInformation = () => {
  const { episodeId, podcastId } = useAudioPlayer();
  const { data: feedId } = useGetPodcastEpisodeDetail(
    { episodeId, podcastId },
    {
      select: podcastEpisodeFeedIdSelector,
    },
  );

  return { episodeId, feedId, podcastId };
};

export default useGetPodcastInformation;
