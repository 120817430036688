import { secToMillis } from 'utils/time';
import { TldlStore } from './types';

export const episodesSelector = (s: TldlStore) => s.episodes;

export const actionsSelector = (s: TldlStore) => s.actions;

export const tldlIsErrorSelector = (s: TldlStore) => s.isError;

export const tldlJobCuidSelector = (s: TldlStore) => s.jobCuid;

export const tldlIsPreloadingSelector = (s: TldlStore) => s.isPreloading;

export const episodeCountSelector = (s: TldlStore) =>
  episodesSelector(s).length;

export const targetDurationMillisSelector = (s: TldlStore) =>
  s.targetDurationMillis;

export const totalEpisodeDurationMillisSelector = (s: TldlStore) => {
  const episodes = episodesSelector(s);
  return episodes.reduce((total, e) => total + secToMillis(e.durationSec), 0);
};

export const episodeListDataSelector = (s: TldlStore) =>
  episodesSelector(s).map((e) => ({
    id: e.id,
    title: e.title,
  }));

export const episodeByIdSelector = (s: TldlStore, id: string) => {
  const episodes = episodesSelector(s);
  return episodes.find((e) => e.id === id);
};

export const episodePodcastIdSelector = (s: TldlStore, episodeId: string) => {
  const episode = episodeByIdSelector(s, episodeId);
  return episode?.podcast.id ?? '';
};

export const tldlJobInputsSelector = (s: TldlStore) => {
  const episodes = episodesSelector(s);
  return episodes.map((e) => ({
    podcastId: e.podcast.id,
    remoteEpisodeId: e.id,
  }));
};

export const tldlHasJobSelector = (s: TldlStore) =>
  tldlJobCuidSelector(s) !== undefined;
