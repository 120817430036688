import { notifyError } from 'components/notification';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIsTldlPreloading, useTldlActions } from '../../state/tldl';
import usePreloadTldlForm from './usePreloadTldlForm';
import usePreloadTldlJob from './usePreloadTldlJob';
import useTldlLocation from './useTldlLocation';

// tldl can be preloaded with a "job", using its cuid, or a by pre-populating the
// tldl "form" with episodes to include in the job once submitted
type PreloadContext = 'job' | 'form' | undefined;

export default function usePreloadTldlData() {
  // only attempt to preload once on mount
  const isInitialized = useRef(false);
  const [preloadContext, setPreloadContext] = useState<PreloadContext>();

  // the rules are a little different during preloading.  for example, the data
  // store is initialized outside of react with an undefined jobCuid.  If the
  // route contains a jobCuid and the app is considered the source of truth, then
  // the jobCuid would get cleared.  this flag can be used to modify logic like
  // this during the preloading phase.
  const isPreloading = useIsTldlPreloading();
  const { preloadFailure, preloadRequest, preloadSuccess } = useTldlActions();

  const { episodes: queryStringEpisodes, jobCuid } = useTldlLocation();

  const handleFailure = useCallback(
    (e?: Error) => {
      preloadFailure();

      if (e?.message) {
        notifyError({ heading: e.message });
      }
    },
    [preloadFailure],
  );

  usePreloadTldlForm({
    isDisabled: !isPreloading || preloadContext !== 'form',
    ids: queryStringEpisodes,
    onSuccess: preloadSuccess,
    onFailure: handleFailure,
  });

  usePreloadTldlJob({
    isDisabled: !isPreloading || preloadContext !== 'job',
    jobCuid,
    onSuccess: preloadSuccess,
    onFailure: handleFailure,
  });

  useEffect(() => {
    if (isInitialized.current) {
      return;
    }

    isInitialized.current = true;

    if (jobCuid) {
      preloadRequest();
      setPreloadContext('job');
    } else if (queryStringEpisodes.length > 0) {
      preloadRequest();
      setPreloadContext('form');
    } else {
      preloadSuccess();
    }
  }, [jobCuid, preloadRequest, preloadSuccess, queryStringEpisodes.length]);
}
