import { useFollowPodcastPrompt } from 'context/FollowPodcastContext';
import useStaticCallback from 'hooks/useStaticCallback';
import { useEffect } from 'react';
import { useAudioPlayer } from '../state';

const useChangeEpisode = () => {
  const { close } = useFollowPodcastPrompt();
  const { episodeId } = useAudioPlayer();
  const handleClose = useStaticCallback(close);

  useEffect(() => {
    handleClose();
  }, [episodeId, handleClose]);
};

export default useChangeEpisode;
