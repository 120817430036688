import EpisodeSearchResult from './EpisodeSearchResult/EpisodeSearchResult';
import type { EpisodeSearchResultProps } from './EpisodeSearchResult/EpisodeSearchResult';
import PodcastSearchResult from './PodcastSearchResult';
import type { PodcastSearchResultProps } from './PodcastSearchResult';
import SearchModal from './SearchModal';
import {
  OnSelectEpisode,
  OnSelectPodcast,
  SearchEpisode,
  SearchModalParams,
  SearchPodcast,
} from './types';

export * from './SearchModalConfigContext';
export default SearchModal;
export { EpisodeSearchResult, PodcastSearchResult };
export type {
  EpisodeSearchResultProps,
  OnSelectEpisode,
  OnSelectPodcast,
  PodcastSearchResultProps,
  SearchEpisode,
  SearchModalParams,
  SearchPodcast,
};
