import { Chip, Clock, Spacer, Text } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import styles from './TldlEpisodeRow.module.scss';
import { formatDuration } from './utils';

export type TldlEpisodeRowDurationBadgeProps = {
  durationMillis: number;
};

const TldlEpisodeRowDurationBadge: React.FC<
  TldlEpisodeRowDurationBadgeProps
> = ({ durationMillis }) => {
  const formattedDuration = useMemo(
    () => formatDuration(durationMillis),
    [durationMillis],
  );

  return (
    <Chip className={styles.badge} permanent>
      <Spacer
        align="center"
        justify="center"
        orientation="horizontal"
        space="6px"
      >
        <Clock />
        <Text variant="mono">{formattedDuration}</Text>
      </Spacer>
    </Chip>
  );
};

export default TldlEpisodeRowDurationBadge;
