import ShareButton from 'components/ShareButton';
import { useModalControls } from 'context/ModalContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useEpisodePage } from 'pages/EpisodePage/EpisodePageContext';
import React from 'react';

export interface EpisodeShareButtonProps {}

const EpisodeShareButton: React.FC<EpisodeShareButtonProps> = () => {
  const { open } = useModalControls();
  const {
    episodeId: playerEpisodeId,
    podcastId: playerPodcastId,
    player,
  } = useAudioPlayer();

  const { podcastId, episodeId } = useEpisodePage();

  const handleOpenModal = () => {
    const timestamp =
      playerEpisodeId === episodeId && playerPodcastId === podcastId
        ? player?.currentTime()
        : undefined;
    open({
      name: 'ShareModal',
      params: {
        episodeId,
        podcastId,
        timestamp,
      },
    });
  };

  return <ShareButton onPress={handleOpenModal} />;
};
export default EpisodeShareButton;
