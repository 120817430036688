import cn from 'classnames';
import React from 'react';
import Spinner from 'react-spinkit';

import styles from './LoadingSpinner.module.scss';

interface IProps {
  className?: string;
  customContent?: React.ReactNode;
  title?: string;
  description?: string;
  small?: boolean;
}

const LoadingSpinner: React.FunctionComponent<IProps> = () => (
  <Spinner
    className={cn(
      styles.spinner,
      styles['spinner--blue'],
      styles['spinner--wave'],
    )}
    fadeIn="quarter"
  />
);

export default LoadingSpinner;
