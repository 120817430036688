import React from 'react';
import useNotifyOnTldlJobError from '../hooks/useNotifyOnTldlJobError';
import useTldlView from '../state/useTldlView';
import TldlEpisodesCard from '../TldlEpisodesCard';
import TldlLandingCard from '../TldlLandingCard';

export interface TldlWizardProps {}

const TldlWizard: React.FC<TldlWizardProps> = () => {
  const view = useTldlView();

  useNotifyOnTldlJobError();

  return view === 'landing' ? <TldlLandingCard /> : <TldlEpisodesCard />;
};

export default TldlWizard;
