import DurationBadge from 'components/DurationBadge';
import React from 'react';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export type EpisodeDescriptionDurationBadgeProps = {
  onClickCapture?: React.MouseEventHandler;
};

const EpisodeDescriptionDurationBadge: React.FC<
  EpisodeDescriptionDurationBadgeProps
> = ({ onClickCapture }) => {
  const { durationMillis } = useEpisodeDescription();

  return (
    <DurationBadge
      durationMillis={durationMillis}
      onClickCapture={onClickCapture}
    />
  );
};

export default EpisodeDescriptionDurationBadge;
