/* eslint-disable no-param-reassign */
import { SearchEpisode } from 'components/SearchModal';
import { DEFAULT_DURATION_MILLIS } from 'pages/TldlPage/TldlDurationSelector';
import { useCallback, useEffect, useRef } from 'react';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import {
  actionsSelector,
  episodeByIdSelector,
  episodeCountSelector,
  episodeListDataSelector,
  episodePodcastIdSelector,
  episodesSelector,
  targetDurationMillisSelector,
  tldlHasJobSelector,
  tldlIsErrorSelector,
  tldlIsPreloadingSelector,
  tldlJobCuidSelector,
  totalEpisodeDurationMillisSelector,
} from './selectors';
import { TldlState, TldlStore } from './types';

const defaultState: TldlState = {
  episodes: [],
  isError: false,
  isPreloading: true,
  jobCuid: undefined,
  targetDurationMillis: DEFAULT_DURATION_MILLIS,
};

const useTldlStore = create<TldlStore>()(
  immer((set) => ({
    ...defaultState,
    actions: {
      addEpisode: (episode: SearchEpisode) =>
        set((state) => {
          const i = state.episodes.findIndex((e) => e.id === episode.id);

          if (i < 0) {
            state.episodes.push(episode);
          }
        }),
      createTldlJobRequest: () =>
        set((state) => {
          state.isError = false;
        }),
      createTldlJobSuccess: (jobCuid) =>
        set((state) => {
          state.jobCuid = jobCuid;
        }),
      preloadRequest: () =>
        set((state) => {
          state.isPreloading = true;
        }),
      preloadSuccess: () =>
        set((state) => {
          state.isPreloading = false;
        }),
      preloadFailure: () =>
        set((state) => {
          state.isPreloading = false;
          state.isError = true;
          state.jobCuid = undefined;
        }),
      removeEpisode: (id: string) =>
        set((state) => {
          const index = state.episodes.findIndex((e) => e.id === id);

          if (index >= 0) {
            state.episodes.splice(index, 1);
          }
        }),
      reset: () => set(defaultState),
      selectTargetDuration: (millis: number) =>
        set((state) => {
          state.targetDurationMillis = millis;
        }),
      tldlJobFailure: () =>
        set((state) => {
          state.isError = true;
          state.jobCuid = undefined;
        }),
    },
  })),
);

const useTldlStoreData = () => useTldlStore();

export const useTldlActions = () => useTldlStore(actionsSelector);

export const useTldlEpisodes = () => useTldlStore(useShallow(episodesSelector));

export const useTargetDurationMillis = () =>
  useTldlStore(targetDurationMillisSelector);

export const useTldlEpisodeCount = () => useTldlStore(episodeCountSelector);

export const useTldlTotalEpisodeDurationMillis = () =>
  useTldlStore(totalEpisodeDurationMillisSelector);

export const useTldlEpisode = (episodeId: string) =>
  useTldlStore(
    useShallow(
      useCallback(
        (s: TldlStore) => episodeByIdSelector(s, episodeId),
        [episodeId],
      ),
    ),
  );

export const useTldlEpisodeIdentifiers = () =>
  useTldlStore(useShallow(episodeListDataSelector));

export const useTldlSubscription = (
  cb: (current: TldlStore, prev: TldlStore) => void,
) => {
  useEffect(() => useTldlStore.subscribe(cb), [cb]);
};

export const useTldlSubscriptionWithSelector = <TData>(
  selector: (store: TldlStore) => TData,
) => {
  const data = useRef(useTldlStore(selector));

  useTldlSubscription(
    useCallback(
      (store: TldlStore) => {
        data.current = selector(store);
      },
      [selector],
    ),
  );

  return data;
};

export const useTldlJobCuid = () => useTldlStore(tldlJobCuidSelector);

export const useTldlEpisodePodcastId = (episodeId: string) =>
  useTldlStore(
    useCallback(
      (s: TldlStore) => episodePodcastIdSelector(s, episodeId),
      [episodeId],
    ),
  );

export const useHasTldlJob = () => useTldlStore(tldlHasJobSelector);

export const useHasTldlError = () => useTldlStore(tldlIsErrorSelector);

export const useIsTldlPreloading = () => useTldlStore(tldlIsPreloadingSelector);

export { useTldlStoreData as useTldlStore };
