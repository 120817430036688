import { useConnectedModal } from 'context/ModalContext';
import useKeyBinding from 'hooks/useKeyBinding';
import { debounce } from 'lodash';
import React from 'react';

import {
  OPEN_SEARCH_KEY_CODE,
  SEARCH_MODAL_DEBOUNCE_DELAY_MS,
} from './constants';

type UseSearchModalKeyBindingConfig = {
  openModal: () => void;
};

const useSearchModalKeyBinding = ({
  openModal,
}: UseSearchModalKeyBindingConfig): void => {
  const { show } = useConnectedModal({ name: 'PodcastSearchNavigation' });

  const shownRef = React.useRef(show);

  // it is required for show to be handled at a ref as it prevents duplicated
  // modal opens when hitting the key repeated times.
  shownRef.current = show;

  const handleKeyDown = React.useMemo(
    () =>
      debounce(
        () => {
          if (shownRef.current) {
            return;
          }

          shownRef.current = true;
          openModal();
          // using the trailing event is more effective for debouncing the key down
          // event for this particular case.
        },
        SEARCH_MODAL_DEBOUNCE_DELAY_MS,
        { trailing: true },
      ),
    [openModal],
  );

  useKeyBinding({
    bindings: [{ keyCode: OPEN_SEARCH_KEY_CODE, onKeyDown: handleKeyDown }],
  });
};

export default useSearchModalKeyBinding;
