import { episodeSearchQueryKeys } from 'api/hooks/queries';
import useSearchEpisodes from 'api/hooks/useSearchEpisodes';
import { EpisodeSearchResponse } from 'api/podcast';
import { useEffect } from 'react';
import { InfiniteData, useQueryClient } from 'react-query';
import { SearchEpisode } from '../types';

export interface UseSeachModalEpisodesResults
  extends Pick<ReturnType<typeof useSearchEpisodes>, 'fetchNextPage'> {
  episodesSearchData: SearchEpisode[];
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
}

export interface UseSeachMoralEpisodesResultsConfig {
  searchQuery: string;
}

const searchEpisodeSelector = (d: InfiniteData<EpisodeSearchResponse>) => {
  const data = d.pages
    .flatMap((p) => p.content)
    .map((r) => ({
      id: r.episodeId,
      durationSec: r.durationSec,
      title: r.title,
      description: r.description,
      thumbnailUrl: r.thumbnailUrl,
      publishedAtMillis: r.publishedAtMillis,
      podcast: {
        thumbnailUrl: r.podcast.thumbnailUrl,
        id: r.podcast.podcastId,
        title: r.podcast.title,
      },
    }));

  return {
    ...d,
    pages: data,
  };
};

const useSeachModalEpisodesResults = (
  config: UseSeachMoralEpisodesResultsConfig,
): UseSeachModalEpisodesResults => {
  const { searchQuery } = config;
  const queryClient = useQueryClient();

  useEffect(
    () => () => {
      if (searchQuery) {
        // This prevents the screen render to get slow due to too many results
        // and also clears the query when the search query changes
        queryClient.removeQueries(episodeSearchQueryKeys.search(searchQuery));
      }
    },
    [queryClient, searchQuery],
  );

  const {
    data: episodesSearchDataResponse,
    fetchNextPage,
    hasNextPage: hasMore = false,
    isError,
    isLoading: isQueryLoading,
  } = useSearchEpisodes(searchQuery, undefined, {
    keepPreviousData: false,
    refetchOnMount: 'always',
    select: searchEpisodeSelector,
  });

  const episodesSearchData = episodesSearchDataResponse?.pages ?? [];

  const isLoading = (!isError && !episodesSearchDataResponse) || isQueryLoading;

  return {
    episodesSearchData,
    fetchNextPage,
    hasMore,
    isError,
    isLoading,
  };
};

export default useSeachModalEpisodesResults;
