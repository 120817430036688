import { useEffect } from 'react';
import { millisToSec } from 'utils/time';
import { useTldlActions } from '../../state/tldl';
import { useTldlEpisodes } from '../../state/useTldlEpisodes';
import { TldlEpisodeIdentifier } from '../../types';

type UsePreloadTldlFormConfig = {
  isDisabled?: boolean;
  ids?: TldlEpisodeIdentifier[];
  onFailure?: () => void;
  onSuccess?: () => void;
};

export default function usePreloadTldlForm({
  ids,
  isDisabled,
  onFailure,
  onSuccess,
}: UsePreloadTldlFormConfig) {
  const { data: episodes, status: episodesStatus } = useTldlEpisodes({
    ids,
    enabled: !isDisabled,
  });

  const { addEpisode } = useTldlActions();

  useEffect(() => {
    if (!isDisabled) {
      if (episodesStatus === 'error') {
        onFailure?.();
      } else if (episodesStatus === 'success') {
        episodes.forEach((e) =>
          addEpisode({
            description: e.description,
            durationSec: millisToSec(e.audioDurationMillis),
            id: e?.remoteEpisodeId,
            podcast: {
              id: e?.podcast.podcastId,
              thumbnailUrl: e?.podcast.thumbnailImageUrl,
              title: e?.podcast.title,
            },
            publishedAtMillis: e?.publishedAtMillis,
            thumbnailUrl: e?.thumbnailImageUrl,
            title: e?.title,
          }),
        );
        onSuccess?.();
      }
    }
  }, [addEpisode, episodes, episodesStatus, isDisabled, onFailure, onSuccess]);
}
