import { isTldlStatusResolved } from 'api/selectors/tldl';
import { useHasTldlError, useHasTldlJob, useTldlEpisodeCount } from './tldl';
import { TldlView } from './types';
import { useTldlJobStatus } from './useTldlJobStatus';

/**
 * Returns an enum describing the different states of the TLDL UI
 */
export default function useTldlView(): TldlView {
  // job creation error
  const isError = useHasTldlError();
  const isTldlJobCreated = useHasTldlJob();
  const episodeCount = useTldlEpisodeCount();

  // job processing error
  const { data: jobStatus } = useTldlJobStatus();

  if (episodeCount === 0) {
    return 'landing';
  }

  if (isError || jobStatus === 'error' || !isTldlJobCreated) {
    return 'selection';
  }

  if (isTldlJobCreated && !isTldlStatusResolved(jobStatus)) {
    return 'summarizing';
  }

  return 'summarized';
}
