import { ProgressBar } from '@sparemin/blockhead';
import { useTldlProgressValue } from 'pages/TldlPage/state/useTldlJobStatus';
import React from 'react';
import styles from './TldlEpisodeCardFooter.module.scss';

export interface TldlProcessingFooterProps {}

const TldlProcessingFooter: React.FC<TldlProcessingFooterProps> = () => {
  const { data: progressValue = 0 } = useTldlProgressValue();

  return (
    <div className={styles.progressContainer}>
      <ProgressBar aria-label="tldl job progress" value={progressValue} />
    </div>
  );
};

export default TldlProcessingFooter;
