import { Button, ButtonProps, Search } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import React from 'react';

import styles from './SearchButton.module.scss';
import useSearchModalKeyBinding from './useSearchModalKeyBinding';

// These props were added only so that a tooltip could be attached to the SearchButton.
type ButtonTooltipProps = Pick<
  ButtonProps,
  | 'onBlur'
  | 'onFocus'
  | 'onKeyDown'
  | 'onKeyUp'
  | 'onPointerDown'
  | 'onPointerEnter'
  | 'onPointerLeave'
  | 'tabIndex'
>;

export type SearchButtonProps = ButtonTooltipProps &
  Pick<ButtonProps, 'isDisabled'> & {
    onOpenModal: () => void;
  };

const SearchButton = React.forwardRef<HTMLButtonElement, SearchButtonProps>(
  ({ isDisabled, onOpenModal, ...rest }, ref) => {
    const { trackSearchBarExpanded } = useEventTracking();

    const handlePressSeach = React.useCallback(() => {
      trackSearchBarExpanded();
      onOpenModal();
    }, [onOpenModal, trackSearchBarExpanded]);

    useSearchModalKeyBinding({
      openModal: onOpenModal,
    });

    return (
      <Button
        className={styles.root}
        color="light"
        isDisabled={isDisabled}
        onPress={handlePressSeach}
        ref={ref}
        startIcon={<Search className={styles.root__icon} />}
        variant="frosted"
        {...rest}
      >
        <span className={styles.root__label}>
          Search for your favorite podcasts
        </span>
      </Button>
    );
  },
);

export default SearchButton;
