import { ListeningQueueArgs } from 'api/podcast';

export const authenticatedQueryKey = {
  authenticated: true,
};

export const episodeSearchQueryKeys = {
  all: [{ scope: 'episodeSearch' }] as const,
  search: (query: string) =>
    [{ ...episodeSearchQueryKeys.all[0], query }] as const,
};

export const episodeQueryKeys = {
  all: [{ scope: 'podcastEpisode' }] as const,
  details: (episodeId?: string | number, podcastId?: string) =>
    [
      {
        ...episodeQueryKeys.all[0],
        episodeId,
        podcastId,
      },
    ] as const,
  feedDetails: (feedEpisodeId?: number) => [
    { ...episodeQueryKeys.all[0], feedEpisodeId },
  ],
};

export const chapterSuggestionQueryKeys = {
  all: [{ scope: 'chapterSuggestion' }] as const,
  chapter: (chapterId?: number) =>
    [
      {
        ...chapterSuggestionQueryKeys.all[0],
        chapterId,
      },
    ] as const,
};

export const podcastSearchQueryKeys = {
  all: [{ scope: 'podcastSearch' }] as const,
  search: (query: string) =>
    [{ ...podcastSearchQueryKeys.all[0], query }] as const,
};

export const feedQueryKeys = {
  all: ['feed'] as const,
  details: (feedId?: number) => [...feedQueryKeys.all, feedId],
  episodes: (feedId?: number) => [...feedQueryKeys.details(feedId), 'episodes'],
};

export const experimentQueryKeys = {
  all: ['experiments'] as const,
  experiments: (experimentName?: string[]) =>
    [...experimentQueryKeys.all, experimentName] as const,
};

export const podcastQueryKeys = {
  all: [{ scope: 'podcast' }] as const,
  details: (podcastId?: string | number) =>
    [
      {
        ...podcastQueryKeys.all[0],
        podcastId,
      },
    ] as const,
  episodes: (podcastId?: string | number) =>
    [{ ...podcastQueryKeys.all[0], podcastId, entity: 'episodes' }] as const,
  feedDetails: (feedPodcastId?: number) =>
    [{ ...podcastQueryKeys.all[0], feedPodcastId }] as const,
};

export const bestRankedPodcastsQueryKeys = {
  all: [{ scope: 'bestRankedPodcasts' }] as const,
};

export const podcastHighlightsQueryKeys = {
  all: [{ scope: 'podcastHighlights' }] as const,
  highlights: (feedId?: number) =>
    [
      {
        ...podcastHighlightsQueryKeys.all[0],
        feedId,
      },
    ] as const,
  homepage: () =>
    [{ ...podcastHighlightsQueryKeys.all[0], entity: 'homepage' }] as const,
};

export const podcastFollowsQueryKeys = {
  all: [{ scope: 'podcastFollows' }] as const,
  follows: (feedId: number | undefined) =>
    [
      {
        ...podcastFollowsQueryKeys.all[0],
        ...authenticatedQueryKey,
        feedId,
      },
    ] as const,
};

export const userProfileQueryKeys = {
  all: [{ scope: 'profile' }] as const,
  profile: (userId: number | undefined, accessToken: string | undefined) =>
    [
      {
        ...userProfileQueryKeys.all[0],
        ...authenticatedQueryKey,
        userId,
        accessToken,
      },
    ] as const,
};

export const listeningQueueKeys = {
  all: [{ scope: 'listeningQueue' }] as const,
  episode: (args: Omit<ListeningQueueArgs, 'anonymousUserToken'>) =>
    [
      {
        ...listeningQueueKeys.all[0],
        args,
      },
    ] as const,
};

export const ownedPodcastQueryKeys = {
  all: [{ scope: 'ownedPodcasts' }] as const,
  podcasts: () =>
    [
      {
        ...ownedPodcastQueryKeys.all[0],
        ...authenticatedQueryKey,
      },
    ] as const,
};

export const tldlJobDetailQueryKeys = {
  all: [{ scope: 'tldlJobDetail' }],
  job: (cuid: string | undefined) => [
    { ...tldlJobDetailQueryKeys.all[0], cuid },
  ],
};
