import { Button } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import { useTldlEpisodePodcastId } from 'pages/TldlPage/state/tldl';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { createEpisodePath } from 'utils/routes';

export type TldlFullEpisodeButtonProps = {
  episodeId: string;
};

const TldlFullEpisodeButton: React.FC<TldlFullEpisodeButtonProps> = ({
  episodeId,
}) => {
  const { trackTldlVisitFullEpisode } = useEventTracking();
  const podcastId = useTldlEpisodePodcastId(episodeId);
  const [path, opts] = createEpisodePath(episodeId, podcastId);

  const handlePress = useCallback(() => {
    trackTldlVisitFullEpisode();
  }, [trackTldlVisitFullEpisode]);

  return (
    <Button
      as={Link}
      size="small"
      target="_blank"
      to={path}
      onPress={handlePress}
      {...opts}
    >
      full episode
    </Button>
  );
};

export default TldlFullEpisodeButton;
