import { Description } from '@sparemin/blockhead';
import React, { useMemo } from 'react';
import { getTextContentFromHtmlString } from 'utils/dom';
import { EpisodeDescriptionProvider } from './EpisodeDescriptionContext';
import EpisodeDescriptionDurationBadge from './EpisodeDescriptionDurationBadge';
import EpisodeDescriptionEpisodeTitle from './EpisodeDescriptionEpisodeTitle';
import EpisodeDescriptionFooter from './EpisodeDescriptionFooter';
import EpisodeDescriptionHeadline from './EpisodeDescriptionHeadline';
import EpisodeDescriptionMetadata from './EpisodeDescriptionMetadata';
import EpisodeDescriptionPodcastTitle from './EpisodeDescriptionPodcastTitle';
import EpisodeDescriptionPublisedAt from './EpisodeDescriptionPublisedAt';
import EpisodeDescriptionText from './EpisodeDescriptionText';
import EpisodeDescriptionThumbnail from './EpisodeDescriptionThumbnail';
import { EpisodeDescriptionProps } from './types';

/**
 * A description of an episode which can be used as episode cards, search results,
 * etc.
 *
 * This component is an an eventual replacement for EpisodeCard.
 */
const EpisodeDescriptionComponent: React.FC<EpisodeDescriptionProps> = ({
  className,
  children,
  descriptionHtml,
  durationMillis,
  isLoading,
  publishedAtMillis,
  podcastName,
  thumbnailSrc,
  title,
}) => {
  const description = useMemo(
    () => getTextContentFromHtmlString(descriptionHtml),
    [descriptionHtml],
  );

  return (
    <EpisodeDescriptionProvider
      {...{
        description,
        durationMillis,
        isLoading,
        podcastName,
        publishedAtMillis,
        thumbnailSrc,
        title,
      }}
    >
      <Description className={className}>{children}</Description>
    </EpisodeDescriptionProvider>
  );
};

const EpisodeDescription = Object.assign(EpisodeDescriptionComponent, {
  Description: EpisodeDescriptionText,
  EpisodeTitle: EpisodeDescriptionEpisodeTitle,
  Footer: EpisodeDescriptionFooter,
  Header: Description.Header,
  Headline: EpisodeDescriptionHeadline,
  Metadata: EpisodeDescriptionMetadata,
  PlayButton: EpisodeDescriptionDurationBadge,
  PodcastTitle: EpisodeDescriptionPodcastTitle,
  PublishedAt: EpisodeDescriptionPublisedAt,
  Thumbnail: EpisodeDescriptionThumbnail,
});

export default EpisodeDescription;
