import useTldlView from 'pages/TldlPage/state/useTldlView';
import React from 'react';
import TldlIdleHeader from './TldlIdleHeader';
import TldlProcessedHeader from './TldlProcessedHeader';
import TldlProcessingHeader from './TldlProcessingHeader';

export interface TldlEpisodeCardHeaderProps {}

const TldlEpisodeCardHeader: React.FC<TldlEpisodeCardHeaderProps> = () => {
  const view = useTldlView();

  if (view === 'selection') {
    return <TldlIdleHeader />;
  }

  if (view === 'summarizing') {
    return <TldlProcessingHeader />;
  }

  if (view === 'summarized') {
    return <TldlProcessedHeader />;
  }

  return null;
};

export default TldlEpisodeCardHeader;
