import { BackArrowCircle, ClearTwoTone, Search } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';
import React from 'react';

import styles from './SearchModal.module.scss';

interface SearchModalHeaderProps {
  onChangeValue: (value: string) => void;
  onClose: () => void;
  value: string;
}

const SearchModalHeader: React.FunctionComponent<SearchModalHeaderProps> = (
  props,
) => {
  const { onChangeValue, onClose, value } = props;

  const { md } = useBreakpoints();

  const inputRef = React.useRef<HTMLInputElement>(null);

  const clearVisible = !!value;

  React.useEffect(() => {
    inputRef.current?.focus();
  }, [inputRef]);

  const handleChangeValue: React.ChangeEventHandler<HTMLInputElement> =
    React.useCallback(
      (e) => {
        onChangeValue(e.target.value);
      },
      [onChangeValue],
    );

  const handleClear = React.useCallback((): void => {
    onChangeValue('');
  }, [onChangeValue]);

  const LeftIcon = md ? (
    <BackArrowCircle className={styles.backIcon} onClick={onClose} />
  ) : (
    <Search className={styles.searchIcon} />
  );

  return (
    <div className={styles.header}>
      {LeftIcon}
      <input
        ref={inputRef}
        className={styles.input}
        onChange={handleChangeValue}
        placeholder="Search for your favorite podcasts"
        value={value}
      />
      {clearVisible && (
        <ClearTwoTone className={styles.clearIcon} onClick={handleClear} />
      )}
    </div>
  );
};

export default SearchModalHeader;
