import {
  BackArrowCircle,
  Heading,
  List,
  Spacer,
  UnstyledButton,
} from '@sparemin/blockhead';
import { useGetPodcastSearchEpisodeList } from 'api/hooks/useGetPodcastEpisodes';
import React from 'react';
import EpisodeSearchResult from '../EpisodeSearchResult/EpisodeSearchResult';
import SearchModalFadingWrapper from '../SearchModalFadingWrapper';
import { SearchEpisode } from '../types';
import styles from './SearchModalPodcastEpisodeList.module.scss';

export type SearchModalPodcastEpisodeListProps = {
  onBackPress?: () => void;
  podcastId: string;
  title: string;
};

// When scrolling with the keyboard arrows, the end of the list is eventually reached.
// That end is faded by the fading container. In order to prevent fading the current focused
// element, this offset moves the scroll 80px more when the scroll is triggered by the
// keyboard navigation
// const SCROLLING_OFFSET_PX = '-80px';

const getEpisodeKey = (e: SearchEpisode) => e.id;

const SearchModalPodcastEpisodeList: React.FC<
  SearchModalPodcastEpisodeListProps
> = ({ onBackPress, podcastId, title }) => {
  const { data, fetchNextPage, hasNextPage } =
    useGetPodcastSearchEpisodeList(podcastId);

  return (
    <div className={styles.root}>
      <Spacer
        as={UnstyledButton}
        className={styles.header}
        align="center"
        justify="flex-start"
        orientation="horizontal"
        space="8px"
        onPress={onBackPress}
      >
        <BackArrowCircle
          className={styles.backIcon}
          style={{ height: 24, width: 24 }}
        />
        <Heading className={styles.title} level={5} letterSpacing="normal">
          {title}
        </Heading>
      </Spacer>
      <SearchModalFadingWrapper>
        <List
          aria-label="podcast episodes"
          borderWeight={0}
          className={styles.list}
          cornerRadius={0}
          hasMore={hasNextPage}
          items={data?.pages ?? []}
          onLoadMore={fetchNextPage}
        >
          {(episode) => (
            <List.Item key={getEpisodeKey(episode)} textValue={episode.title}>
              <EpisodeSearchResult context="podcast" episode={episode} />
            </List.Item>
          )}
        </List>
      </SearchModalFadingWrapper>
    </div>
  );
};

export default SearchModalPodcastEpisodeList;
