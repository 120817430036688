import cn from 'classnames';
import BetaBadge from 'components/BetaBadge';
import { TldlIcon } from 'components/icons';
import React from 'react';
import styles from './TldlBetaIcon.module.scss';

export type TldlBetaIconProps = {
  className?: string;
};

const TldlBetaIcon: React.FC<TldlBetaIconProps> = ({ className }) => (
  <div className={cn(styles.container, className)}>
    <TldlIcon className={styles.icon} />
    <BetaBadge className={styles.beta} fontSize={9} />
  </div>
);

export default TldlBetaIcon;
