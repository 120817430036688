import { ButtonBase } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import { Link, NavigateOptions, To } from 'react-router-dom';
import styles from './SearchResult.module.scss';
import { BaseSearchResultProps } from './types';
import useSearchResultHandlers from './useSearchResultHandlers';

type SearchResultLinkProps = {
  to: To;
  opts?: NavigateOptions;
};

export type SearchResultProps<TData = undefined> =
  BaseSearchResultProps<TData> & {
    children?: React.ReactNode;
    className?: string;
    link?: SearchResultLinkProps;
  };

/**
 * A wrapper that will render as a clickable search result element or a link,
 * depending on whether or not link props present.
 *
 * This component renders a div by default because `children` is opaque and might
 * contain interactable elements.
 */
function SearchResult<TData = undefined>(props: SearchResultProps<TData>) {
  const { children, className, link } = props;

  const { onClick, onHover, onHoverLost } = useSearchResultHandlers(props);

  const linkProps = !link?.to
    ? {}
    : {
        as: Link,
        to: link.to,
        ...link.opts,
      };

  return (
    <ButtonBase
      as="div"
      className={cn(styles.root, className)}
      onPress={onClick}
      onHoverStart={onHover}
      onHoverEnd={onHoverLost}
      {...linkProps}
    >
      {children}
    </ButtonBase>
  );
}

export default SearchResult;
