import { Thumbnail, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import ThumbnailSkeleton from 'components/ThumbnailSkeleton';
import { useAudioPlayer } from '../../state/AudioPlayerContext/AudioPlayerContext';
import EpisodeTitle from './EpisodeTitle';
import styles from './PodcastData.module.scss';
import PodcastTitle from './PodcastTitle';

export interface PodcastDataProps {
  className?: string;
}

const PodcastData: React.FC<PodcastDataProps> = ({ className }) => {
  const { thumbnailUrl: artworkUrl } = useAudioPlayer();

  return (
    <Spacer align="center" className={cn(styles.root, className)} space={1.5}>
      {artworkUrl ? (
        <Thumbnail
          alt="episode artwork"
          className={styles.thumbnail}
          ratio={1}
          basis="width"
          src={artworkUrl}
        />
      ) : (
        <ThumbnailSkeleton className={styles.thumbnail} />
      )}
      <Spacer space={1} justify="center" orientation="vertical">
        <EpisodeTitle />
        <PodcastTitle />
      </Spacer>
    </Spacer>
  );
};

export default PodcastData;
