import { PressEvent } from '@sparemin/blockhead';
import { useCallback } from 'react';
import { BaseSearchResultProps } from './types';

export default function useSearchResultHandlers<TData>({
  data,
  onClick,
  onHover,
  onHoverLost,
}: BaseSearchResultProps<TData>) {
  return {
    onClick: useCallback(
      (e: PressEvent) => {
        onClick?.(data, e);
      },
      [data, onClick],
    ),
    onHover: useCallback(() => {
      onHover?.(data);
    }, [data, onHover]),
    onHoverLost: useCallback(() => {
      onHoverLost?.(data);
    }, [data, onHoverLost]),
  };
}
