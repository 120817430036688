import { Dialog, DialogProps, BackdropProps } from '@sparemin/blockhead';
import cn from 'classnames';
import ModalContentsProvider from 'context/ModalContentsContext';
import { ModalName, useConnectedModal } from 'context/ModalContext';
import React from 'react';
import styles from './ConnectedModal.module.scss';

interface ConnectedModalProps extends React.PropsWithChildren<DialogProps> {
  name: ModalName;
  className?: string;
  onRequestClose?: (e?: React.MouseEvent | React.KeyboardEvent) => void;
  onAfterClose?: () => void;
  position?: BackdropProps['contentsPosition'];
}

const ConnectedModal: React.FunctionComponent<ConnectedModalProps> = (
  props,
) => {
  const {
    children,
    className,
    name,
    position = 'center',
    backdropClassName,
    onRequestClose,
    onAfterClose,
  } = props;

  const { show, params, handleRequestClose, handleAfterClose } =
    useConnectedModal({
      name,
      onRequestClose,
      onAfterClose,
    });

  return (
    <Dialog
      isOpen={show}
      className={className}
      onClose={handleRequestClose}
      onExited={handleAfterClose}
      position={position}
      backdropClassName={cn(styles.overlay, backdropClassName)}
    >
      <ModalContentsProvider
        params={params}
        onRequestClose={handleRequestClose}
      >
        {children}
      </ModalContentsProvider>
    </Dialog>
  );
};

export default ConnectedModal;
