import React from 'react';
import PodcastResultDescription, {
  PodcastResultDescriptionProps,
} from './PodcastResultDescription';
import { getLinkProps, useCreatePodcastPath } from './SearchModalConfigContext';
import SearchResult from './SearchResult';
import { SearchPodcast } from './types';

type PodcastCallback = (podcast: SearchPodcast) => void;

export type PodcastSearchResultProps = PodcastResultDescriptionProps & {
  onClick?: PodcastCallback;
  onHover?: PodcastCallback;
  onHoverLost?: PodcastCallback;
};

const PodcastSearchResult: React.FC<PodcastSearchResultProps> = ({
  onClick,
  onHover,
  onHoverLost,
  podcast,
  showDescription,
}) => {
  const createPodcastPath = useCreatePodcastPath();

  const link = getLinkProps(createPodcastPath(podcast.id));

  return (
    <SearchResult data={podcast} {...{ link, onClick, onHover, onHoverLost }}>
      <PodcastResultDescription {...{ podcast, showDescription }} />
    </SearchResult>
  );
};

export default PodcastSearchResult;
