import { DescriptionHeadline } from '@sparemin/blockhead';
import React from 'react';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export interface EpisodeDescriptionEpisodeTitleProps {}

const EpisodeDescriptionEpisodeTitle: React.FC<
  EpisodeDescriptionEpisodeTitleProps
> = () => {
  const { title } = useEpisodeDescription();

  return (
    <DescriptionHeadline.PrimaryText>{title}</DescriptionHeadline.PrimaryText>
  );
};

export default EpisodeDescriptionEpisodeTitle;
