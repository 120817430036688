import { formatFriendlyDuration } from 'utils/time';

export function formatDuration(millis: number) {
  return formatFriendlyDuration(millis, (duration) => {
    if (duration.hours() > 0) {
      const hoursToken = duration.hours() === 1 ? 'hour' : 'hours';
      const parts = [`H [${hoursToken}]`, 'm [min]'];

      if (duration.minutes() === 0) {
        parts.pop();
      }

      return parts.join(' [and] ');
    }

    if (duration.minutes() > 0) {
      const parts = ['m [min]', 's [sec]'];

      if (duration.seconds() === 0) {
        parts.pop();
      }

      return parts.join(' [and] ');
    }

    return 's [sec]';
  });
}
