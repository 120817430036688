import { QueryStatus } from 'react-query';

export function combineQueryStatus(ss: QueryStatus[]): QueryStatus {
  if (ss.length === 0) {
    return 'idle';
  }

  // if at least one error, fail the whole batch
  if (ss.some((s) => s === 'error')) {
    return 'error';
  }

  // if any status is loading, consider them all as loading
  if (ss.some((s) => s === 'loading')) {
    return 'loading';
  }

  if (ss.every((s) => s === 'success')) {
    return 'success';
  }

  return 'idle';
}
