import {
  useTldlAudioPlayerChapters,
  useTldlSummarizedAudioUrl,
} from 'api/hooks/useTldlJobStatus';
import tldlPlayerThumbSrc from 'assets/images/tldl-player-thumb.png';
import usePrevious from 'hooks/usePrevious';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MediaElementAudioPlayer } from 'utils/audio';
import { BaseAudioPlayer } from 'utils/audio/types';
import { createTldlPath } from 'utils/routes';
import { AudioPlayerTldlLoader, LoadTldlAudioConfig } from './types';

export type UseTldlLoaderConfig = {
  audioElement: HTMLAudioElement | null;
  onLoadAudio?: () => void;
  isEnabled?: boolean;
};

type UseTldlLoaderState = {
  jobCuid?: string;
  player?: BaseAudioPlayer;
};

const DEFAULT_STATE: UseTldlLoaderState = {
  jobCuid: undefined,
  player: undefined,
};

export default function useTldlLoader({
  audioElement,
  onLoadAudio,
  isEnabled,
}: UseTldlLoaderConfig): AudioPlayerTldlLoader {
  const [state, setState] = useState<UseTldlLoaderState>(DEFAULT_STATE);
  const prevAudioElement = usePrevious<HTMLAudioElement | null>(null);

  const { jobCuid, player } = state;

  const { data: chapters } = useTldlAudioPlayerChapters({ jobCuid });

  const { data: src } = useTldlSummarizedAudioUrl({ jobCuid });

  const loadTldlAudio = useCallback(
    (config: LoadTldlAudioConfig) => {
      onLoadAudio?.();
      setState({ jobCuid: config.jobCuid, player: undefined });
    },
    [onLoadAudio],
  );

  useEffect(() => {
    if (isEnabled && prevAudioElement !== audioElement && audioElement?.src) {
      const newPlayer = new MediaElementAudioPlayer(audioElement, {});
      setState((s) => ({ ...s, player: newPlayer }));
    }
  }, [audioElement, isEnabled, prevAudioElement]);

  const title = useMemo(() => {
    const [tldlPath, tldlNavOpts] = !jobCuid ? [] : createTldlPath({ jobCuid });

    if (!tldlPath) {
      return undefined;
    }

    return {
      value: 'Headliner',
      path: tldlPath,
      navOpts: tldlNavOpts,
    };
  }, [jobCuid]);

  return {
    loaderProviderProps: useMemo(
      () => ({
        jobCuid,
        loadTldlAudio,
      }),
      [jobCuid, loadTldlAudio],
    ),
    playerProviderProps: useMemo(
      () => ({
        chapters,
        isSponsored: false,
        player,
        playedFromQueueOverride: false,
        src,
        title,
        thumbnailUrl: tldlPlayerThumbSrc,
      }),
      [chapters, player, src, title],
    ),
  };
}
