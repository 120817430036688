import { useAudioPlayerLoader } from './AudioPlayerLoaderContext';
import { AudioPlayerMediaIdentifier } from './types';
import { isEpisodeIdentifier, isTldlIdentifier } from './utils';

export default function useIsAudioLoaded(id: AudioPlayerMediaIdentifier) {
  const { episodeId, podcastId, jobCuid, loaderContextType } =
    useAudioPlayerLoader();

  if (isTldlIdentifier(id) && loaderContextType === 'tldl') {
    return id.jobCuid === jobCuid;
  }

  if (isEpisodeIdentifier(id) && loaderContextType === 'episode') {
    return id.episodeId === episodeId && id.podcastId === podcastId;
  }

  return false;
}
