import { GetOwnedPodcastsResult } from '@sparemin/api-sdk';
import {
  GetBestRankedPoscastsResponse,
  GetFeedFollowResponse,
  GetPodcastDetailsResponse,
  GetPodcastEpisodeDetailResponse,
  GetPodcastEpisodesResponse,
  HighlightVideoClip,
  HighlightVideoClipsResponse,
} from 'api/podcast';
import { SearchEpisode } from 'components/SearchModal';
import { BestRankedPoscast } from 'components/SearchModal/SearchModalBestRankedPodcast/types';
import memoize from 'memoizee';
import { InfiniteData } from 'react-query';
import { millisToSec } from 'utils/time';

export const firstNBestRankedPodcastsSelector =
  (n: number) =>
  (data: GetBestRankedPoscastsResponse): BestRankedPoscast[] =>
    data?.content.slice(0, n) ?? [];

export const feedFollowIsFollowingSelector = (
  data: GetFeedFollowResponse,
): boolean => data?.isFollowing;

export const podcastEpisodeFeedIdSelector = (
  data: GetPodcastEpisodeDetailResponse,
): number | undefined => data?.podcast.podcastFeedId;

export const podcastEpisodesSelector = (
  d: InfiniteData<GetPodcastEpisodesResponse>,
) => {
  const episodes = d.pages.flatMap((p) => p.episodes);

  return {
    ...d,
    pages: episodes,
  };
};

export const podcastSearchEpisodesSelector = (
  d: InfiniteData<GetPodcastEpisodesResponse>,
) => {
  const podcast = d.pages[0]?.podcast;

  if (!podcast) {
    return {
      ...d,
      pages: [],
    };
  }

  const episodes: SearchEpisode[] = d.pages
    .flatMap((p) => p.episodes)
    .map((e) => ({
      description: e.description,
      durationSec: millisToSec(e.audioDurationMillis),
      id: e.remoteEpisodeId ?? e.id,
      podcast: {
        id: podcast.podcastId ?? '',
        thumbnailUrl: podcast.thumbnailUrl,
        title: podcast.title,
      },
      publishedAtMillis: e.publishedAtMillis,
      thumbnailUrl: e.thumbnailImageUrl,
      title: e.title,
    }));

  return {
    ...d,
    pages: episodes,
  };
};

export const titlesSelector = (d: GetPodcastEpisodeDetailResponse) => ({
  podcastTitle: d.podcast.title,
  episodeTitle: d.title,
});

export const PodcastClipsByIdSelector = memoize(
  (d: InfiniteData<HighlightVideoClipsResponse>) => {
    const dictionary = d.pages.reduce((acc, page) => {
      page.videoClips.forEach((clip) => {
        acc[clip.clipId] = clip;
      });
      return acc;
    }, {} as Record<string, HighlightVideoClip>);

    return { pages: [dictionary], pageParams: d.pageParams };
  },
  { max: 1 },
);

export const podcastClipSelector = (
  d: InfiniteData<HighlightVideoClipsResponse>,
  clipId: number,
) => {
  const clips = PodcastClipsByIdSelector(d).pages[0];
  return {
    pages: [clips[clipId]],
    pageParams: d.pageParams,
  };
};

export const isEpisodeBlockedSelector = (d: GetPodcastEpisodeDetailResponse) =>
  d.isBlocked || d.podcast.isBlocked;

export const podcastTitleSelector = (
  d: InfiniteData<GetPodcastDetailsResponse>,
) => {
  const podcastTitle = d.pages[0].title;
  return {
    pages: [podcastTitle],
    pageParams: d.pageParams,
  };
};

export const publishedAtMillisSelector = (d: GetPodcastEpisodeDetailResponse) =>
  d.publishedAtMillis;

export const episodeArtSelector = (d: GetPodcastEpisodeDetailResponse) =>
  d.thumbnailImageUrl;

export const isPodcastOwnerSelector = (
  d: GetOwnedPodcastsResult,
  feedId: number | undefined,
) => {
  if (!feedId) {
    return undefined;
  }
  return d.podcasts.some((podcast) => podcast.podcastFeedId === feedId);
};
