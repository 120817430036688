import { Button, ButtonProps } from '@sparemin/blockhead';
import React from 'react';
import styles from './TldlLandingCardButton.module.scss';

export type TldlLandingCardButtonProps = Pick<
  ButtonProps,
  'onPress' | 'children'
>;

const TldlLandingCardButton: React.FC<TldlLandingCardButtonProps> = ({
  children,
  onPress,
}) => (
  <Button className={styles.button} fluid onPress={onPress} variant="ghost">
    {children}
  </Button>
);

export default TldlLandingCardButton;
