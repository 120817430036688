import cn from 'classnames';
import LoadingSpinner from 'components/LoadingSpinner';
import React from 'react';

import styles from './SearchModalResultsLoader.module.scss';

interface SearchModalResultsLoaderProps {
  isTab?: boolean;
}

const SearchModalResultsLoader: React.FunctionComponent<
  SearchModalResultsLoaderProps
> = (props) => {
  const { isTab } = props;

  const className = cn(styles.root, { [styles.root__tab]: isTab });

  return (
    <div className={className}>
      <LoadingSpinner />
    </div>
  );
};

export default SearchModalResultsLoader;
