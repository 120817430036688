import { Heading, List } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import PodcastSearchResult from '../PodcastSearchResult';
import { useOnSelectPodcast } from '../SearchModalDispatchContext';

import SearchModalResultsLoader from '../SearchModalResultsLoader';
import { SearchPodcast } from '../types';
import styles from './SearchModalBestRankedPodcast.module.scss';
import useBestRankedPodcasts from './useBestRankedPoscasts';

export interface SearchModalBestRankedPodcastProps {}

const SearchModalBestRankedPodcast: React.FunctionComponent<
  SearchModalBestRankedPodcastProps
> = () => {
  const { bestRankedPodcasts, isLoading } = useBestRankedPodcasts();
  const { trackSearchTrendingSelected } = useEventTracking();

  const onSelectPodcast = useOnSelectPodcast();

  const handleSelectResult = useCallback(
    (podcast: SearchPodcast) => {
      trackSearchTrendingSelected({ podcastName: podcast.title });
      onSelectPodcast?.(podcast);
    },
    [onSelectPodcast, trackSearchTrendingSelected],
  );

  if (isLoading) {
    return <SearchModalResultsLoader />;
  }

  if (!bestRankedPodcasts) {
    return <div>Error loading podcasts</div>;
  }

  return (
    <div className={styles.root}>
      <Heading className={styles.header} level={2}>
        🔥 Trending podcasts
      </Heading>
      <List
        aria-label="best ranked podcasts"
        borderWeight={0}
        className={styles.list}
        cornerRadius={0}
      >
        {bestRankedPodcasts.map((podcast) => (
          <List.Item key={podcast.id} textValue={podcast.title}>
            <PodcastSearchResult
              onClick={handleSelectResult}
              podcast={podcast}
            />
          </List.Item>
        ))}
      </List>
    </div>
  );
};

export default SearchModalBestRankedPodcast;
