import api, { PodpressJobCreationResult } from '@sparemin/api-sdk';
import { notifyError } from 'components/notification';
import { useCallback } from 'react';
import { useIsMutating, useMutation } from 'react-query';
import { DEFAULT_ERROR_MESSAGE } from '../constants';
import {
  targetDurationMillisSelector,
  tldlJobInputsSelector,
  useTldlActions,
  useTldlSubscriptionWithSelector,
} from './tldl';

const TLDL_JOB_SUBMIT_MUTATION_KEY = 'submitPodpressJob';

export default function useSubmitTldlJob() {
  const { createTldlJobRequest, createTldlJobSuccess, tldlJobFailure } =
    useTldlActions();

  const jobData = useTldlSubscriptionWithSelector(
    useCallback(
      (store) => ({
        jobInputs: tldlJobInputsSelector(store),
        targetDurationMillis: targetDurationMillisSelector(store),
      }),
      [],
    ),
  );

  return useMutation<PodpressJobCreationResult | undefined, void, void>({
    mutationFn: useCallback(
      () => api.submitPodpressJob(jobData.current),
      [jobData],
    ),
    mutationKey: TLDL_JOB_SUBMIT_MUTATION_KEY,
    onError: useCallback(() => {
      tldlJobFailure();
      notifyError({ heading: DEFAULT_ERROR_MESSAGE });
    }, [tldlJobFailure]),
    onMutate: useCallback(() => {
      createTldlJobRequest();
    }, [createTldlJobRequest]),
    onSuccess: useCallback(
      (result) => {
        if (result?.jobCuid) {
          createTldlJobSuccess(result.jobCuid);
        }
      },
      [createTldlJobSuccess],
    ),
  });
}

/**
 * hook that returns true if there is at least one job submission mutation
 * in progress
 */
export function useIsTldlJobSubmitting() {
  return useIsMutating(TLDL_JOB_SUBMIT_MUTATION_KEY) > 0;
}
