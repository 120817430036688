import { useEventTracking } from 'context/EventTrackingContext';
import { useCallback } from 'react';
import { useOnSelectEpisode } from '../SearchModalDispatchContext';
import { SearchEpisode } from '../types';

export default function useSelectEpisodeSearchResult() {
  const { trackClickEpisodeCard, trackSearchResultSelected } =
    useEventTracking();

  const onSelectEpisode = useOnSelectEpisode();

  return {
    selectEpisodeSearchResult: useCallback(
      (e: SearchEpisode) => {
        trackClickEpisodeCard({
          podcastId: e.podcast.id,
          episodeId: e.id,
          podcastName: e.podcast.title,
          episodeName: e.title,
          source: 'SearchResults',
        });

        trackSearchResultSelected({
          podcastName: e.podcast.title,
          episodeName: e.title,
        });

        onSelectEpisode(e);
      },
      [onSelectEpisode, trackClickEpisodeCard, trackSearchResultSelected],
    ),
  };
}
