import { podcastQueryKeys } from 'api/hooks/queries';
import { useGetPodcastEpisodes } from 'api/hooks/useGetPodcastEpisodes';
import { GetPodcastEpisodesResponse } from 'api/podcast';
import { podcastEpisodesSelector } from 'api/selectors/podcast';
import { useCallback } from 'react';
import { InfiniteData } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { useEpisodePage } from '../EpisodePageContext';

type UsePagePodcastEpisodesOptions<TData = GetPodcastEpisodesResponse> =
  UseInfiniteQueryOptions<
    GetPodcastEpisodesResponse,
    ReturnType<typeof podcastQueryKeys.episodes>,
    unknown,
    TData
  >;

function usePagePodcastEpisodes<TData = GetPodcastEpisodesResponse>(
  opts?: UsePagePodcastEpisodesOptions<TData>,
) {
  const { podcastId } = useEpisodePage();

  return useGetPodcastEpisodes(podcastId, opts);
}

const curatedEpisodeSelector = (
  d: InfiniteData<GetPodcastEpisodesResponse>,
  count: number,
  excludeEpisodeIds: string[] = [],
) => {
  const curatedEpisodes = podcastEpisodesSelector(d)
    .pages // take count + excludeEpisodeIds.length to ensure that if every episode id
    // to exclude is included in the slice, we still wind up with the desired count
    .slice(0, count + excludeEpisodeIds.length)
    // filter out the excluded episodes
    .filter((e) => !excludeEpisodeIds.includes(e.remoteEpisodeId))
    // take as many as requested
    .slice(0, count);

  return {
    ...d,
    pages: curatedEpisodes,
  };
};

const episodeSelector = (
  d: InfiniteData<GetPodcastEpisodesResponse>,
  episodeId: string | undefined,
) => {
  if (!episodeId) {
    return { ...d, pages: [] };
  }

  const episodes = podcastEpisodesSelector(d).pages;
  const episode = episodes.find((e) => e.remoteEpisodeId === episodeId);

  return { ...d, pages: [episode] };
};

export const useCuratedEpisodes = (count: number) => {
  const { episodeId } = useEpisodePage();

  const { data, ...rest } = usePagePodcastEpisodes({
    select: useCallback(
      (d) => curatedEpisodeSelector(d, count, [episodeId]),
      [count, episodeId],
    ),
  });

  return {
    curatedEpisodes: data?.pages,
    ...rest,
  };
};

export const useEpisode = (episodeId: string | undefined) => {
  const { data, ...rest } = usePagePodcastEpisodes({
    select: (d) => episodeSelector(d, episodeId),
  });

  return {
    episode: data?.pages?.[0],
    ...rest,
  };
};
