import { AnimatedRouteContainer } from 'components/AnimatedRoute';
import AppTopbar from 'components/AppHeaderTopbar';
import LogoLink from 'components/LogoLink';
import { PageContents } from 'components/Page';
import UserAccountControl from 'components/UserAccountControl';
import VideoBackground from 'components/VideoBackground';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { TLDL_BACKGROUND_VIDEO_URL } from 'utils/constants';
import usePreloadTldlData from '../hooks/usePreloadTldlData/usePreloadTldlData';
import useSyncStateWithRoute from '../hooks/useSyncStateWithRoute';
import useTrackTldlAudioReady from '../hooks/useTrackTldlAudioReady';
import { useIsTldlPreloading, useTldlActions } from '../state/tldl';
import TldlSearchButton from '../TldlSearchButton';
import styles from './TldlLayout.module.scss';

export interface TldlLayoutProps {}

const TldlLayout: React.FC<TldlLayoutProps> = () => {
  const { reset } = useTldlActions();
  const isLoading = useIsTldlPreloading();

  useEffect(() => () => reset(), [reset]);

  useTrackTldlAudioReady();
  useSyncStateWithRoute();
  usePreloadTldlData();

  return (
    <PageContents
      as={AnimatedRouteContainer}
      className={styles.page}
      gutter={0}
    >
      <div className={styles.body}>
        <VideoBackground src={TLDL_BACKGROUND_VIDEO_URL} />
        <AppTopbar
          className={styles.nav}
          topLeftElement={<LogoLink />}
          topCenterElement={<TldlSearchButton />}
          topRightElement={<UserAccountControl />}
        />
        {!isLoading && <Outlet />}
      </div>
    </PageContents>
  );
};

export default TldlLayout;
