import React, { useContext, useMemo } from 'react';
import {
  EpisodeDescriptionContextType,
  EpisodeDescriptionProviderProps,
} from './types';

const EpisodeDescriptionContext = React.createContext<
  EpisodeDescriptionContextType | undefined
>(undefined);

export const EpisodeDescriptionProvider: React.FC<
  EpisodeDescriptionProviderProps
> = ({
  children,
  description,
  durationMillis,
  isLoading,
  podcastName,
  publishedAtMillis,
  thumbnailSrc,
  title,
}) => (
  <EpisodeDescriptionContext.Provider
    value={useMemo(
      () => ({
        description,
        durationMillis,
        isLoading,
        podcastName,
        publishedAtMillis,
        thumbnailSrc,
        title,
      }),
      [
        description,
        durationMillis,
        isLoading,
        podcastName,
        publishedAtMillis,
        thumbnailSrc,
        title,
      ],
    )}
  >
    {children}
  </EpisodeDescriptionContext.Provider>
);

export function useEpisodeDescription() {
  const context = useContext(EpisodeDescriptionContext);

  if (context === undefined) {
    throw new Error(
      'useEpisodeDescription must be used within EpisodeDescriptionProvider',
    );
  }

  return context;
}
