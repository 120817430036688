import React, { useContext, useMemo } from 'react';
import {
  SearchModalDispatchContextType,
  SearchModalDispatchProviderProps,
} from './types';

const SearchModalDispatchContext = React.createContext<
  SearchModalDispatchContextType | undefined
>(undefined);

export const SearchModalDispatchProvider: React.FC<
  SearchModalDispatchProviderProps
> = ({ children, onSelectEpisode, onSelectPodcast }) => (
  <SearchModalDispatchContext.Provider
    value={useMemo(
      () => ({ onSelectEpisode, onSelectPodcast }),
      [onSelectEpisode, onSelectPodcast],
    )}
  >
    {children}
  </SearchModalDispatchContext.Provider>
);

export function useSearchModalDispatch() {
  const context = useContext(SearchModalDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useSearchModalDispatch must be used within SearchModalDispatchProvider',
    );
  }

  return context;
}

export const useOnSelectEpisode = () =>
  useSearchModalDispatch().onSelectEpisode;

export const useOnSelectPodcast = () =>
  useSearchModalDispatch().onSelectPodcast;
