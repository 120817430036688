import React from 'react';
import { useInView } from 'react-intersection-observer';

import { Position } from './types';

export interface VisibilityWrapperProps {
  children: React.ReactNode;
  endIndicatorPosition?: Position;
  onVisibilityChange: (
    inView: boolean,
    handles: {
      start: Element | null;
      end: Element | null;
      element: Element | null;
    },
  ) => void;
  rootMargin?: string;
  startIndicatorPosition?: Position;
  threshold?: number;
}

const DEFAULT_ROOT_MARGIN = '0px 0px 0px 0px';
const DEFAULT_END_INDICATOR_POS: Position = { bottom: '0' };
const DEFAULT_START_INDICATOR_POS: Position = { top: '0' };

const VisibilityWrapper: React.FunctionComponent<VisibilityWrapperProps> = (
  props,
) => {
  const {
    children,
    endIndicatorPosition = DEFAULT_END_INDICATOR_POS,
    onVisibilityChange,
    rootMargin = DEFAULT_ROOT_MARGIN,
    startIndicatorPosition = DEFAULT_START_INDICATOR_POS,
    threshold,
  } = props;

  const innerContainerRef = React.useRef<HTMLDivElement>(null);
  const startRef = React.useRef<HTMLDivElement>(null);
  const endRef = React.useRef<HTMLDivElement>(null);

  const { ref: wrapperRef } = useInView({
    onChange: (nextInView) =>
      onVisibilityChange(nextInView, {
        element: innerContainerRef.current,
        start: startRef.current,
        end: endRef.current,
      }),
    rootMargin,
    threshold,
  });

  return (
    <div ref={wrapperRef}>
      <div ref={innerContainerRef} style={{ position: 'relative' }}>
        {/* This handle allows having a placeable reference for scrolling into view the top position of the component */}
        <div
          ref={startRef}
          style={{ position: 'absolute', ...startIndicatorPosition }}
        />
        {children}
        {/* This handle allows having a placeable reference for scrolling into view the bottom position of the component */}
        <div
          ref={endRef}
          style={{ position: 'absolute', ...endIndicatorPosition }}
        />
      </div>
    </div>
  );
};

export default VisibilityWrapper;
