import { minToMillis } from 'utils/time';

const FIVE_MINUTES_MILLIS = minToMillis(5);
const TEN_MINUTES_MILLIS = minToMillis(10);
const FIFTEEN_MINUTES_MILLIS = minToMillis(15);
const TWENTY_MINUTES_MILLIS = minToMillis(20);

export const DEFAULT_DURATION_MILLIS = TEN_MINUTES_MILLIS;

export const DURATION_OPTIONS = [
  {
    id: FIVE_MINUTES_MILLIS,
    name: '~ 5 min',
  },
  {
    id: TEN_MINUTES_MILLIS,
    name: '~ 10 min',
  },
  {
    id: FIFTEEN_MINUTES_MILLIS,
    name: '~ 15 min',
  },
  {
    id: TWENTY_MINUTES_MILLIS,
    name: '~ 20 min',
  },
];

export const VALID_DURATIONS = DURATION_OPTIONS.map((d) => d.id);
