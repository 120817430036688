import { useIsFollowingFeed } from 'api/hooks/useGetFeedFollow';
import { useFollowPodcastPrompt } from 'context/FollowPodcastContext';
import useListen from 'hooks/useListen';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';

const useFollowPromptOnListen = (feedId?: number) => {
  const { data: isFollowing } = useIsFollowingFeed(feedId);
  const { open } = useFollowPodcastPrompt();
  const { player, loaderContextType } = useAudioPlayer();

  useListen({
    isDisabled: loaderContextType !== 'episode',
    thresholdMillis: process.env.NODE_ENV === 'development' ? 8000 : undefined,
    onListen: () => {
      if (!isFollowing) {
        open();
      }
    },
    player,
  });
};

export default useFollowPromptOnListen;
