import cn from 'classnames';
import { Heading } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { isAudioPlayerLink } from 'pages/AudioPlayerPage/state/AudioPlayerContext/utils';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './PodcastData.module.scss';

type EpisodeTitleProps = {};

const EpisodeTitle: React.FC<EpisodeTitleProps> = () => {
  const { title } = useAudioPlayer();
  const { md } = useBreakpoints();

  const location = useLocation();

  const isLinkConfig = isAudioPlayerLink(title);
  const pathname = isLinkConfig ? title.path.pathname : undefined;
  const isOnPage = location.pathname === pathname;

  const titleText = (
    <Heading
      className={cn(styles.episode, { [styles.episode__link]: !isOnPage })}
      level={2}
    >
      {isLinkConfig ? title.value : title}
    </Heading>
  );

  if (title && isLinkConfig && !isOnPage && !md) {
    const { path, navOpts, onClick } = title;
    return (
      <Link
        className={styles.link}
        to={path ?? ''}
        onClick={onClick}
        {...navOpts}
      >
        {titleText}
      </Link>
    );
  }
  return titleText;
};
export default EpisodeTitle;
