import { DescriptionMetadata } from '@sparemin/blockhead';
import React from 'react';
import { formatFriendlyDuration } from 'utils/time';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export interface EpisodeDescriptionMetadataProps {}

const EpisodeDescriptionMetadata: React.FC<
  EpisodeDescriptionMetadataProps
> = () => {
  const { durationMillis = 0, publishedAtMillis } = useEpisodeDescription();

  return (
    <DescriptionMetadata>
      <DescriptionMetadata.Date epochMillis={publishedAtMillis} />
      <DescriptionMetadata.Separator />
      {formatFriendlyDuration(durationMillis)}
    </DescriptionMetadata>
  );
};

export default EpisodeDescriptionMetadata;
