import LogoLink from 'components/LogoLink';
import { useAppHeaderAnimation } from '../AppHeaderAnimationContext/AppHeaderAnimationContext';

export interface LogoProps {
  className?: string;
}

const Logo: React.FC<LogoProps> = ({ className }) => {
  const { logo } = useAppHeaderAnimation();

  return <LogoLink className={className} {...logo} />;
};

export default Logo;
