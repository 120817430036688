import merge from 'lodash/merge';
import throttle from 'lodash/throttle';
import { Dispatch, useEffect, useReducer } from 'react';
import { Chapter } from 'types';
import {
  findChapterByTime,
  indexOfChapterByTime,
  isTimeWithinChapter,
} from '../utils';
import reducer, { INITIAL_STATE } from './reducer';
import {
  UseAudioPlayerAction,
  UseAudioPlayerState,
  UseAudioPlayerStateConfig,
} from './types';

/*
 * This hook syncs values from a BaseAudioPlayer instance with react state so that
 * they can be used to trigger updates.
 */
export default function useAudioPlayerState({
  chapters,
  defaultDuration,
  player,
}: UseAudioPlayerStateConfig): [
  UseAudioPlayerState,
  Dispatch<UseAudioPlayerAction>,
] {
  const [state, dispatch] = useReducer(
    reducer,
    merge({}, INITIAL_STATE, { duration: defaultDuration }),
  );

  useEffect(() => {
    if (defaultDuration !== undefined) {
      dispatch({ type: 'partiallyloaded', payload: { defaultDuration } });
    }
  }, [defaultDuration]);

  useEffect(() => {
    const handleCanPlay = () => dispatch({ type: 'canplay' });
    const handlePlay = () => {
      dispatch({ type: 'play', payload: {} });
    };
    const handlePause = () => dispatch({ type: 'pause' });
    const handlePlaying = () => dispatch({ type: 'playing' });
    const handleLoadedMetadata = () =>
      dispatch({
        type: 'loaded',
        payload: {
          duration: player?.duration as number,
        },
      });

    // to avoid unnecessary state updates, keep the current chapter in a variable
    // and on time update, check if the playback time is still within the current
    // chapter's range.  if not, find out which chapter is currently playing
    let currentChapter: Chapter | undefined;
    const handleTimeupdate = throttle(() => {
      const time = player?.currentTime();

      if (!isTimeWithinChapter(time, currentChapter)) {
        currentChapter = findChapterByTime(time, chapters);

        dispatch({
          type: 'playchapter',
          payload: {
            chapterIndex: indexOfChapterByTime(time, chapters),
            chapters,
          },
        });
      }
    }, 500);

    player?.on('canplay', handleCanPlay);
    player?.on('play', handlePlay);
    player?.on('pause', handlePause);
    player?.on('playing', handlePlaying);
    player?.on('loadedmetadata', handleLoadedMetadata);
    player?.on('timeupdate', handleTimeupdate);

    return () => {
      player?.off('canplay', handleCanPlay);
      player?.off('play', handlePlay);
      player?.off('pause', handlePause);
      player?.off('playing', handlePlaying);
      player?.off('loadedmetadata', handleLoadedMetadata);
      player?.off('timeupdate', handleTimeupdate);

      dispatch({ type: 'resetplayback' });
    };
  }, [chapters, player]);

  // the useAudioPlayerState hook's purpose is to sync the player state with its
  // own.  if the player reference changes, the internal state might be incorrect,
  // so it should be reset
  useEffect(() => () => dispatch({ type: 'resetplayback' }), []);

  // dispatch is returned so that actions can be dispatched from the outside, but
  // event listeners added in the hook above ensure that the player and react states
  // stay synced regardless of which outside actions are sent.  for example, the react
  // state should set "paused === false" even if the "pause" action was not sent from
  // the outside.
  return [state, dispatch];
}
