import { Spacer, SpacerProps } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import styles from './TldlEpisodeCardHeader.module.scss';

export type TldlEpisodeCardHeaderContainerProps = SpacerProps & {
  className?: string;
};

const TldlEpisodeCardHeaderContainer: React.FC<
  TldlEpisodeCardHeaderContainerProps
> = ({ className, ...props }) => (
  <Spacer className={cn(styles.container, className)} {...props} />
);

export default TldlEpisodeCardHeaderContainer;
