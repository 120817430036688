import { useModalControls } from 'context/ModalContext';
import React, { useCallback } from 'react';
import SearchButton from './SearchButton';

export interface PodcastSearchNavigationButtonProps {}

const PodcastSearchNavigationButton: React.FC<
  PodcastSearchNavigationButtonProps
> = () => {
  const { open } = useModalControls();

  const handleOpen = useCallback(() => {
    open({ name: 'PodcastSearchNavigation' });
  }, [open]);

  return <SearchButton onOpenModal={handleOpen} />;
};

export default PodcastSearchNavigationButton;
