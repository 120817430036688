import { Container, ContainerProps } from '@sparemin/blockhead';
import cn from 'classnames';
import React from 'react';
import styles from './TldlCard.module.scss';

export type TldlCardProps = Pick<ContainerProps, 'gutter'> & {
  children?: React.ReactNode;
  className?: string;
};

const TldlCard: React.FC<TldlCardProps> = ({ children, className, gutter }) => (
  <Container
    className={cn(styles.card, className)}
    cornerRadius="48px"
    gutter={gutter}
  >
    {children}
  </Container>
);
export default TldlCard;
