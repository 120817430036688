import { Description } from '@sparemin/blockhead';
import React from 'react';
import { getEpisodesText } from 'utils/episode';

export type PodcastDescriptionProps = {
  category?: string;
  className?: string;
  description?: string;
  thumbnailSrc: string;
  title: string;
  totalEpisodes: number;
};

/**
 * Description of a podcast, which can be used for podcast cards, search results,
 * etc.
 */
const PodcastDescription: React.FC<PodcastDescriptionProps> = ({
  category,
  className,
  description,
  thumbnailSrc,
  title,
  totalEpisodes,
}) => (
  <Description className={className}>
    <Description.Header>
      <Description.Thumbnail
        alt="podcast artwork"
        ratio={1}
        src={thumbnailSrc}
      />
      <Description.Headline>
        <Description.Headline.PrimaryText>
          {title}
        </Description.Headline.PrimaryText>
        <Description.Meta letterSpacing="1px" weight={500}>
          {category}
          {!!category && <Description.Meta.Separator />}
          {getEpisodesText(totalEpisodes)}
        </Description.Meta>
      </Description.Headline>
    </Description.Header>
    {description && <Description.Body>{description}</Description.Body>}
  </Description>
);

export default PodcastDescription;
