import { PodpressJobDetail } from '@sparemin/api-sdk';
import {
  UseTldlEpisodeStatusArgs,
  useTldlJobStatusQuery as useBaseTldlJobStatusQuery,
  UseTldlJobStatusQueryOptions,
} from 'api/hooks/useTldlJobStatus';
import {
  tldlJobEpisodeStatusSelector,
  tldlJobInputsIdsSelector,
  tldlJobProgressSelector,
  tldlJobStatusSelector,
  tldlJobTargetDurationMillisSelector,
  tldlSummarizedAudioUrlSelector,
} from 'api/selectors/tldl';
import { useCallback } from 'react';
import { useTldlJobCuid } from './tldl';

function useTldlJobStatusQuery<TData>(
  opts?: UseTldlJobStatusQueryOptions<TData>,
) {
  const jobCuid = useTldlJobCuid();

  return useBaseTldlJobStatusQuery({ jobCuid }, opts);
}

export const useTldlJobStatus = () =>
  useTldlJobStatusQuery({ select: tldlJobStatusSelector });

export const useTldlProgressValue = () =>
  useTldlJobStatusQuery({ select: tldlJobProgressSelector });

export const useTldlEpisodeStatus = ({
  podcastId,
  episodeId,
}: Omit<UseTldlEpisodeStatusArgs, 'jobCuid'>) =>
  useTldlJobStatusQuery({
    select: useCallback(
      (d: PodpressJobDetail) =>
        tldlJobEpisodeStatusSelector(d, podcastId, episodeId),
      [episodeId, podcastId],
    ),
  });

export const useTldlAudioUrl = () =>
  useTldlJobStatusQuery({ select: tldlSummarizedAudioUrlSelector });

export const useTldlJobInputIds = () =>
  useTldlJobStatusQuery({ select: tldlJobInputsIdsSelector });

export const useTldlJobTargetDurationMillis = () =>
  useTldlJobStatusQuery({ select: tldlJobTargetDurationMillisSelector });
