import { Heading } from '@sparemin/blockhead';
import HeaderTextLink from 'components/HeaderTextLink';
import { isAudioPlayerLink } from 'pages/AudioPlayerPage/state/AudioPlayerContext/utils';
import React, { useCallback } from 'react';
import { useAudioPlayer } from '../../state/AudioPlayerContext';
import useNowPlayingCard from '../../state/NowPlayingCardContext';
import styles from './EpisodeTitle.module.scss';

interface EpisodeTitleProps {}

const EpisodeTitle: React.FC<EpisodeTitleProps> = () => {
  const { title } = useAudioPlayer();

  const isLinkConfig = isAudioPlayerLink(title);

  const { hide } = useNowPlayingCard();

  const handleEpisodeLinkClick = useCallback(() => {
    hide();

    if (isLinkConfig) {
      title?.onClick?.();
    }
  }, [hide, isLinkConfig, title]);

  return isLinkConfig ? (
    <HeaderTextLink
      className={styles.root}
      linkClassName={styles.link}
      level={3}
      path={title.path}
      pathOpts={title.navOpts}
      onPress={handleEpisodeLinkClick}
      title={title.value}
    />
  ) : (
    <Heading level={3} className={styles.root}>
      {title}
    </Heading>
  );
};
export default EpisodeTitle;
