import { Select } from '@sparemin/blockhead';
import React, { Key, useCallback, useEffect, useRef, useState } from 'react';
import { useTargetDurationMillis, useTldlActions } from '../state/tldl';
import { useIsTldlJobSubmitting } from '../state/useSubmitTldlJob';
import { DEFAULT_DURATION_MILLIS, DURATION_OPTIONS } from './constants';
import styles from './TldlDurationSelector.module.scss';
import { isValidDuration } from './utils';

export interface TldlDurationSelectorProps {}

const TldlDurationSelector: React.FC<TldlDurationSelectorProps> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const initialized = useRef(false);
  const targetDurationMillis = useTargetDurationMillis();
  const { selectTargetDuration } = useTldlActions();
  const isSubmitting = useIsTldlJobSubmitting();

  // check to make sure state holds a value that the dropdown supports - if not,
  // reset it to a default value
  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;

      if (!isValidDuration(targetDurationMillis)) {
        selectTargetDuration(DEFAULT_DURATION_MILLIS);
      }
    }
  }, [selectTargetDuration, targetDurationMillis]);

  const handleSelectionChange = useCallback(
    (val: Key) => {
      selectTargetDuration(Number(val));
    },
    [selectTargetDuration],
  );

  return (
    <Select
      aria-label="target duration"
      className={styles.root}
      isDisabled={isSubmitting}
      isOpen={isOpen}
      onOpenChange={setIsOpen}
      selectedKey={String(targetDurationMillis)}
      items={DURATION_OPTIONS}
      onSelectionChange={handleSelectionChange}
    />
  );
};

export default TldlDurationSelector;
