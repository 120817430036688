import { usePreviousRef, useStaticCallback } from '@sparemin/blockhead';
import { useEventTracking } from 'context/EventTrackingContext';
import useListen from 'hooks/useListen';
import {
  useGetAudioDetails,
  useGetEpisodeDetails,
} from 'pages/AudioPlayerPage/state/useGetEpisodeDetails';
import useTrackPlayerLinkClick from 'pages/AudioPlayerPage/state/useTrackPlayerLinkClick';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MediaElementAudioPlayer } from 'utils/audio';
import { createEpisodePath, createPodcastPath } from 'utils/routes';
import { millisToSec } from 'utils/time';
import usePersistListeningProgress from '../../listeningProgress';
import { AudioPlayerEpisodeLoader, LoadEpisodeAudioConfig } from './types';

type UseEpisodeLoaderConfig = {
  audioElement: HTMLAudioElement | null;
  isEnabled?: boolean;
  onLoadAudio?: () => void;
};

type UseEpisodeLoaderState = Partial<LoadEpisodeAudioConfig> & {
  player?: MediaElementAudioPlayer;
  sourceWidgetId?: string;
};

const DEFAULT_STATE: UseEpisodeLoaderState = {
  episodeId: undefined,
  isPlayedFromQueue: undefined,
  isSponsored: undefined,
  player: undefined,
  playbackRate: 1,
  podcastId: undefined,
  sponsoredWidgetInfo: undefined,
  sourceWidgetId: undefined,
  startSec: 0,
};

export default function useEpisodeLoader({
  audioElement,
  onLoadAudio,
  isEnabled,
}: UseEpisodeLoaderConfig): AudioPlayerEpisodeLoader {
  const [state, setState] = useState<UseEpisodeLoaderState>(DEFAULT_STATE);

  const prevAudioElement = usePreviousRef<HTMLAudioElement | null>(null);

  const {
    podcastId,
    episodeId,
    isPlayedFromQueue,
    isSponsored,
    playbackRate,
    player,
    sourceWidgetId,
    sponsoredWidgetInfo,
    startSec,
  } = state;

  const { data: episodeDetails } = useGetEpisodeDetails({
    episodeId,
    podcastId,
  });

  const { data: audioDetails } = useGetAudioDetails({
    episodeId,
    podcastId,
  });

  const episodeTitle = episodeDetails?.episodeTitle;
  const podcastTitle = episodeDetails?.podcastTitle;

  // chapters reference has to be stable and not change unnecessarily since that
  // will cause some issues with context consumers
  const chapters = useMemo(
    () => audioDetails?.chapters,
    [audioDetails?.chapters],
  );

  const { trackEpisodeComplete, trackEpisodeListen, trackPlaying } =
    useEventTracking();

  usePersistListeningProgress({
    player,
    episodeId,
  });

  useListen({
    isDisabled: !isEnabled,
    player,
    onListen: useCallback(() => {
      if (podcastTitle && episodeTitle) {
        trackEpisodeListen(podcastTitle, episodeTitle);
      }
    }, [episodeTitle, podcastTitle, trackEpisodeListen]),
  });

  const trackEpisodeLinkClick = useTrackPlayerLinkClick({
    linkType: 'EpisodeTitle',
    isSponsored,
    sponsoredEpisodeWidgetInfo: sponsoredWidgetInfo,
  });

  const trackPodcastLinkClick = useTrackPlayerLinkClick({
    linkType: 'PodcastTitle',
    isSponsored,
    sponsoredEpisodeWidgetInfo: sponsoredWidgetInfo,
  });

  const setSourceWidgetId = useCallback((val: string | undefined) => {
    setState((s) => ({ ...s, sourceWidgetId: val }));
  }, []);

  const loadEpisodeAudio = useCallback(
    (val: LoadEpisodeAudioConfig) => {
      onLoadAudio?.();
      setState((s) => ({ ...s, ...val, player: undefined }));
    },
    [onLoadAudio],
  );

  const handlePlaying = useStaticCallback(() => {
    if (episodeDetails) {
      trackPlaying(episodeDetails?.podcastTitle, episodeDetails?.episodeTitle);
    }
  });

  const handleEpisodeComplete = useStaticCallback(() => {
    if (podcastTitle && episodeTitle) {
      trackEpisodeComplete(podcastTitle, episodeTitle);
    }
  });

  useEffect(() => {
    if (
      isEnabled &&
      prevAudioElement.current !== audioElement &&
      audioElement?.src
    ) {
      const newPlayer = new MediaElementAudioPlayer(audioElement, {
        startTime: startSec,
        playbackRate,
      });

      newPlayer.on('playing', handlePlaying);
      newPlayer.on('ended', handleEpisodeComplete);

      setState((s) => ({ ...s, player: newPlayer }));
    }
  }, [
    audioElement,
    handleEpisodeComplete,
    handlePlaying,
    isEnabled,
    playbackRate,
    prevAudioElement,
    startSec,
  ]);

  const subtitle = useMemo(() => {
    const [podcastPath, podcastNavOpts] = !podcastId
      ? []
      : createPodcastPath(podcastId);

    if (!podcastTitle || !podcastPath) {
      return undefined;
    }

    return {
      value: podcastTitle,
      path: podcastPath,
      navOpts: podcastNavOpts,
      onClick: trackPodcastLinkClick,
    };
  }, [podcastId, podcastTitle, trackPodcastLinkClick]);

  const title = useMemo(() => {
    const [episodePath, episodeNavOpts] =
      !episodeId || !podcastId ? [] : createEpisodePath(episodeId, podcastId);

    if (!episodeTitle || !episodePath) {
      return undefined;
    }

    return {
      value: episodeTitle,
      path: episodePath,
      navOpts: episodeNavOpts,
      onClick: trackEpisodeLinkClick,
    };
  }, [episodeId, episodeTitle, podcastId, trackEpisodeLinkClick]);

  return {
    loaderProviderProps: useMemo(
      () => ({
        episodeId,
        loadEpisodeAudio,
        podcastId,
        setSourceWidgetId,
        sourceWidgetId,
        sponsoredEpisodeWidgetInfo: sponsoredWidgetInfo,
      }),
      [
        episodeId,
        loadEpisodeAudio,
        podcastId,
        setSourceWidgetId,
        sourceWidgetId,
        sponsoredWidgetInfo,
      ],
    ),
    playerProviderProps: useMemo(
      () => ({
        chapters,
        defaultDuration: millisToSec(audioDetails?.audioDurationMillis),
        isSponsored: !!isSponsored,
        playedFromQueueOverride: isPlayedFromQueue,
        player,
        title,
        subtitle,
        src: audioDetails?.src,
        thumbnailUrl: episodeDetails?.thumbnailUrl,
      }),
      [
        audioDetails?.audioDurationMillis,
        audioDetails?.src,
        chapters,
        episodeDetails?.thumbnailUrl,
        isPlayedFromQueue,
        isSponsored,
        player,
        subtitle,
        title,
      ],
    ),
  };
}
