import { useCallback, useEffect } from 'react';
import { useTldlActions } from '../../state/tldl';
import {
  useTldlJobInputIds,
  useTldlJobStatus,
} from '../../state/useTldlJobStatus';
import usePreloadTldlForm from './usePreloadTldlForm';

type UsePreloadTldlJobConfig = {
  isDisabled?: boolean;
  jobCuid?: string;
  onSuccess?: () => void;
  onFailure?: (e?: Error) => void;
};

export default function usePreloadTldlJob({
  isDisabled,
  jobCuid,
  onFailure,
  onSuccess,
}: UsePreloadTldlJobConfig) {
  const { data: jobInputIds, status: jobQueryStatus } = useTldlJobInputIds();
  const { data: jobStatus } = useTldlJobStatus();
  const { createTldlJobSuccess } = useTldlActions();

  usePreloadTldlForm({
    ids: jobInputIds,
    // usePreloadTldlForm will call onFailure when the request fails.  this is a
    // fatal error.
    onSuccess: useCallback(() => {
      if (jobStatus === 'error') {
        // if the tldl job failed, this is a fatal error as the project cannot
        // be recovered, however usePreloadTldlForm will still populate the
        // episode list.  calling onFailure here will leave episodes in state with
        // no jobCuid, which is the state needed to trigger the "selection" view.
        onFailure?.();
      } else {
        onSuccess?.();
      }
    }, [jobStatus, onFailure, onSuccess]),
    onFailure,
  });

  useEffect(() => {
    if (!isDisabled) {
      if (jobCuid) {
        createTldlJobSuccess(jobCuid);
      }
    }
  }, [createTldlJobSuccess, isDisabled, jobCuid]);

  useEffect(() => {
    if (!isDisabled && jobQueryStatus === 'error') {
      onFailure?.(new Error('Error loading TLDL job'));
    }
  }, [isDisabled, jobQueryStatus, onFailure]);
}
