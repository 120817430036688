import { useModalControls } from 'context/ModalContext';
import { useWithAuthentication } from 'hooks/useWithAuthentication';
import { useMemo } from 'react';
import { useTldlActions } from '../state/tldl';
import useTldlView from '../state/useTldlView';

/**
 * Opens TLDL search only when allowed.
 *
 * The user can only search for and add podcasts when in the "landing" or
 * "selection" views.
 *
 * This hook also handles the response data from the search modal.
 */
export default function useOpenTldlSearch() {
  const { createAuthenticatedHandler } = useWithAuthentication();
  const { open: openModal } = useModalControls();
  const { addEpisode } = useTldlActions();
  const view = useTldlView();

  return {
    openTldlSearch: useMemo(
      () =>
        createAuthenticatedHandler(() => {
          if (view !== 'landing' && view !== 'selection') {
            return;
          }

          openModal({
            name: 'TldlSearch',
            params: {
              onSelectEpisode: addEpisode,
            },
          });
        }),
      [addEpisode, createAuthenticatedHandler, openModal, view],
    ),
  };
}
