import { useFirstNBestRankedPodcasts } from 'api/hooks/useGetBestRankedPodcasts';
import { useMemo } from 'react';
import { SearchPodcast } from '../types';

import { BEST_RANKED_PODCASTS_DISPLAY_AMOUNT } from './constants';

interface UseBestRankedPodcast {
  bestRankedPodcasts?: SearchPodcast[];
  isLoading: boolean;
}

const useBestRankedPodcasts = (): UseBestRankedPodcast => {
  const { data, isLoading } = useFirstNBestRankedPodcasts(
    BEST_RANKED_PODCASTS_DISPLAY_AMOUNT,
  );

  const bestRankedPodcasts: SearchPodcast[] = useMemo(
    () =>
      data?.map((p) => ({
        category: p.topCategory,
        description: p.description,
        thumbnailUrl: p.thumbnailUrl,
        id: p.podcastId,
        title: p.title,
        totalEpisodes: p.totalEpisodes,
      })) ?? [],
    [data],
  );

  return {
    bestRankedPodcasts,
    isLoading,
  };
};

export default useBestRankedPodcasts;
