import { ThemeProvider } from '@sparemin/blockhead';
import ConnectedModal from 'components/ConnectedModal';
import { useModalControls } from 'context/ModalContext';
import { constant } from 'lodash';
import React from 'react';

import styles from './SearchModal.module.scss';
import { SearchModalConfigProvider } from './SearchModalConfigContext';

import SearchModalContents from './SearchModalContents';
import { SearchModalProps } from './types';
import { getMode } from './utils';

const returnUndefined = constant(undefined);

const SearchModal: React.FunctionComponent<SearchModalProps> = (props) => {
  const { banner, name, navigationConfig, onSelectEpisode } = props;

  const { close } = useModalControls();

  const {
    createAutoplayEpisodePagePath = returnUndefined,
    createEpisodePagePath = returnUndefined,
    createPodcastPagePath = returnUndefined,
  } = navigationConfig ?? {};

  const mode = getMode(props);

  return (
    <ThemeProvider theme="light">
      <ConnectedModal className={styles.root} name={name} position="start">
        <SearchModalConfigProvider
          {...{
            banner,
            createAutoplayEpisodePagePath,
            createEpisodePagePath,
            createPodcastPagePath,
            mode,
          }}
        >
          <SearchModalContents
            onCloseModal={close}
            onSelectEpisode={onSelectEpisode}
          />
        </SearchModalConfigProvider>
      </ConnectedModal>
    </ThemeProvider>
  );
};

export default SearchModal;
