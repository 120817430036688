import { Description } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';
import React from 'react';
import { secToMillis } from 'utils/time';
import { useTldlEpisode } from '../../../state/tldl';
import TldlEpisodeRowDurationBadge from './TldlEpisodeRowDurationBadge';
import TldlEpisodeRowDynamicActionSlot from './TldlEpisodeRowDynamicActionSlot';

export type TldlEpisodeRowProps = {
  episodeId: string;
};

const TldlEpisodeRow: React.FC<TldlEpisodeRowProps> = ({ episodeId }) => {
  const { md } = useBreakpoints();
  const episode = useTldlEpisode(episodeId);

  if (!episode) {
    return null;
  }

  const actions = (
    <>
      <TldlEpisodeRowDurationBadge
        durationMillis={secToMillis(episode.durationSec)}
      />
      <TldlEpisodeRowDynamicActionSlot episodeId={episodeId} />
    </>
  );

  const headerActions = md ? null : (
    <Description.Header.Actions align="center" space="24px">
      {actions}
    </Description.Header.Actions>
  );

  const footer = !md ? null : (
    <Description.Footer align="center" space="24px">
      {actions}
    </Description.Footer>
  );

  return (
    <Description>
      <Description.Header>
        <Description.Thumbnail
          alt="episode artwork"
          ratio={1}
          src={episode.thumbnailUrl}
        />
        <Description.Headline>
          <Description.Headline.SecondaryText>
            {episode.podcast.title}
          </Description.Headline.SecondaryText>
          <Description.Headline.PrimaryText>
            {episode.title}
          </Description.Headline.PrimaryText>
        </Description.Headline>
        {headerActions}
      </Description.Header>
      {footer}
    </Description>
  );
};

export default TldlEpisodeRow;
