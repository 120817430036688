import { Button, Container, Heading, Spacer } from '@sparemin/blockhead';
import cn from 'classnames';
import BetaBadge from 'components/BetaBadge';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { createTldlPath } from 'utils/routes';
import { useEpisodePage } from '../EpisodePageContext';
import styles from './TldlEpisodeBanner.module.scss';

export type TldlEpisodeBannerProps = {
  className?: string;
};

const TldlEpisodeBanner: React.FC<TldlEpisodeBannerProps> = ({ className }) => {
  const { md } = useBreakpoints();
  const { episodeId, podcastId } = useEpisodePage();
  const { trackTldlBannerClick } = useEventTracking();

  const [path, opts] = createTldlPath({
    episodes: [{ podcastId, remoteEpisodeId: episodeId }],
  });

  const handlePress = useCallback(() => {
    trackTldlBannerClick();
  }, [trackTldlBannerClick]);

  return (
    <Container
      align="center"
      as={Spacer}
      borderWeight={0}
      className={cn(styles.root, className)}
      cornerRadius="24px"
      gutter="24px"
      justify="space-between"
      orientation={md ? 'vertical' : 'horizontal'}
      space={md ? '16px' : '8px'}
    >
      <Spacer align="center" orientation="horizontal" space="8px">
        <Heading level={3}>TLDL</Heading>
        <BetaBadge fontSize="10px" />
      </Spacer>
      <Heading level={2}>
        If this episode is too long, you can listen to just the gist!
      </Heading>
      <Button
        as={Link}
        onPress={handlePress}
        size="small"
        to={path}
        fluid={md}
        {...opts}
      >
        create audio summary
      </Button>
    </Container>
  );
};

export default TldlEpisodeBanner;
