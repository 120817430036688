import { EpisodeSearchResult, PodcastSearchResult } from 'api/podcast';
import useKeyBinding from 'hooks/useKeyBinding';
import { isEqual } from 'lodash';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { CreatePathResult } from 'utils/routes/types';

interface Handles {
  start: Element | null;
  end: Element | null;
  element: Element | null;
}

type SearchData = Partial<EpisodeSearchResult> | Partial<PodcastSearchResult>;

type VisibilityMap = Record<string, [boolean, Handles]>;

interface UseResultsKeyboardControlConfig<TSearchData extends SearchData> {
  getElementKey: (element: TSearchData) => string;
  getElementNavigationPath: (element: TSearchData) => CreatePathResult;
  isLoading: boolean;
  onSelectElement: (element: TSearchData) => void;
  searchData: TSearchData[];
  searchQuery: string;
}

interface UseResultsKeyboardControl<TSearchData> {
  onVisibilityChange: (
    key: string,
    inView: boolean,
    { start, end }: Handles,
  ) => void;
  onElementHover: (hoveredElement: TSearchData) => void;
  onElementHoverLost: (hoveredElement: TSearchData) => void;
  selectedKeys: string[];
}

const END_SCROLL_BEHAVIOR: ScrollIntoViewOptions = {
  block: 'end',
  behavior: 'smooth',
};
const START_SCROLL_BEHAVIOR: ScrollIntoViewOptions = {
  block: 'start',
  behavior: 'smooth',
};

const useResultsKeyboardControl = <TSearchData extends SearchData>(
  config: UseResultsKeyboardControlConfig<TSearchData>,
): UseResultsKeyboardControl<TSearchData> => {
  const {
    getElementKey,
    getElementNavigationPath,
    isLoading,
    onSelectElement,
    searchData,
    searchQuery,
  } = config;

  const initialResultIndex = searchData.length ? 0 : -1;

  // Visibility map keeps track of the list elements keys with its visibility state and their handles
  const [visibilityMap, setVisibilityMap] = React.useState<VisibilityMap>({});
  const [prevSearchData, setPrevSearchData] =
    React.useState<TSearchData[]>(searchData);
  const [prevSearchQuery, setPrevSearchQuery] = React.useState(searchQuery);
  const [hoveredKey, setHoveredKey] = React.useState<string | undefined>();
  const [preselectedKeyIndex, setPreselectedKeyIndex] =
    React.useState<number>(initialResultIndex);

  const selectedKeys = React.useMemo(() => {
    // the behavior is disabled in mobile
    if (isMobile) {
      return [];
    }

    // if there is a current hovered key it should lock keyboard selection.
    if (hoveredKey) {
      return [hoveredKey];
    }

    // otherwise the regular keyboard navigation is used for picking the selected
    // keys
    return searchData[preselectedKeyIndex]
      ? [getElementKey(searchData[preselectedKeyIndex])]
      : [];
  }, [getElementKey, hoveredKey, preselectedKeyIndex, searchData]);

  const navigate = useNavigate();

  // Each time the search query is changed, the key index is reset. If data is already loaded
  // because information is in cache, the index is set to the initial position.
  if (prevSearchQuery !== searchQuery) {
    if (!prevSearchData?.length) {
      setPreselectedKeyIndex(-1);
    } else {
      setPreselectedKeyIndex(0);
    }
    setPrevSearchQuery(searchQuery);
  }

  if (!isEqual(prevSearchData, searchData)) {
    setPrevSearchData(searchData);
  }

  // When search data was initially empty and changes for having length, it means that the search
  // data was initially loaded, so the index key is set to 0
  if (!prevSearchData?.length && searchData?.length) {
    setPreselectedKeyIndex(0);
  }

  // When down arrow key is pressed, the selection is checked and if it is possible to select the
  // next element in the list the index is updated. Then if the element is not visible in view,
  // it is scrolled into view using the end handle.
  const handlePressDownArrow = React.useCallback(() => {
    if (preselectedKeyIndex === -1 || !!hoveredKey) {
      return;
    }

    const nextSelection = searchData[preselectedKeyIndex + 1];

    if (nextSelection) {
      setPreselectedKeyIndex(preselectedKeyIndex + 1);
      const [inView, { end: endHandle }] =
        visibilityMap[getElementKey(nextSelection)] ?? [];
      if (!inView) {
        endHandle?.scrollIntoView(END_SCROLL_BEHAVIOR);
      }
    }
  }, [
    getElementKey,
    hoveredKey,
    searchData,
    preselectedKeyIndex,
    visibilityMap,
  ]);

  // When up arrow key is pressed, the selection is checked and if it is possible to select the
  // previous element in the list the index is updated. Then if the element is not visible in view,
  // it is scrolled into view using the start handle.
  const handlePressUpArrow = React.useCallback(() => {
    if (preselectedKeyIndex === -1 || !!hoveredKey) {
      return;
    }

    const nextSelection = searchData[preselectedKeyIndex - 1];

    if (nextSelection) {
      setPreselectedKeyIndex(preselectedKeyIndex - 1);
      const [inView, { start: startHandle }] =
        visibilityMap[getElementKey(nextSelection)] ?? [];
      if (!inView) {
        startHandle?.scrollIntoView(START_SCROLL_BEHAVIOR);
      }
    }
  }, [
    getElementKey,
    hoveredKey,
    searchData,
    preselectedKeyIndex,
    visibilityMap,
  ]);

  // When enter key is pressed the selection is checked. If an element is available, the navigation
  // path for it will be build and navigation will be triggered.
  const handlePressEnter = React.useCallback(() => {
    if (preselectedKeyIndex === -1 || isLoading) {
      return;
    }

    const element = searchData[preselectedKeyIndex];

    if (element) {
      const [path, pathOpts] = getElementNavigationPath(element);
      onSelectElement(element);
      navigate(path, pathOpts);
    }
  }, [
    getElementNavigationPath,
    isLoading,
    navigate,
    searchData,
    preselectedKeyIndex,
    onSelectElement,
  ]);

  // when an element is hovered, the current search data list is checked for looking for
  // that element, if found it will be set as the current selected key and the current
  // preselected index will be overriden. That last part is necessary for the keyboard
  // navigation to know from where to start when hover is lost.
  const handleElementHover = React.useCallback(
    (hoveredElement: TSearchData): void => {
      const elementIndex = searchData.findIndex(
        (searchElement) =>
          getElementKey(hoveredElement) === getElementKey(searchElement),
      );
      if (elementIndex !== -1) {
        setHoveredKey(getElementKey(searchData[elementIndex]));
        setPreselectedKeyIndex(elementIndex);
      }
    },
    [getElementKey, searchData],
  );

  // if the element that has lost its hover is the current selected one, the hovered key
  // is cleared.
  const handleElementHoverLost = React.useCallback(
    (hoveredElement: TSearchData): void => {
      if (getElementKey(hoveredElement) === hoveredKey) {
        setHoveredKey(undefined);
      }
    },
    [getElementKey, hoveredKey],
  );

  useKeyBinding({
    bindings: [
      { keyCode: 'ArrowDown', onKeyDown: handlePressDownArrow },
      { keyCode: 'ArrowUp', onKeyDown: handlePressUpArrow },
      { keyCode: 'Enter', onKeyDown: handlePressEnter },
    ],
  });

  const handleVisibilityChange = React.useCallback(
    (key: string, inView: boolean, handles: Handles): void => {
      // Each time a element changes its visibility, the visibility map is updated to reflect this.
      setVisibilityMap((prevMap) => ({ ...prevMap, [key]: [inView, handles] }));
    },
    [],
  );

  return {
    onElementHover: handleElementHover,
    onElementHoverLost: handleElementHoverLost,
    onVisibilityChange: handleVisibilityChange,
    selectedKeys,
  };
};

export default useResultsKeyboardControl;
