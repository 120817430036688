import PodcastDescription from 'components/PodcastDescription';
import React from 'react';
import { getTextContentFromHtmlString } from 'utils/dom';
import { SearchPodcast } from './types';

export type PodcastResultDescriptionProps = {
  podcast: SearchPodcast;
  showDescription?: boolean;
};

/**
 * Maps `podcast` data to a `PodcastDescription`
 */
const PodcastResultDescription: React.FC<PodcastResultDescriptionProps> = ({
  podcast,
  showDescription,
}) => {
  const {
    category,
    description,
    thumbnailUrl,
    title,
    totalEpisodes = 0,
  } = podcast;

  const sanitizedDescription = React.useMemo(
    () => getTextContentFromHtmlString(description),
    [description],
  );

  return (
    <PodcastDescription
      category={category}
      description={showDescription ? sanitizedDescription : undefined}
      thumbnailSrc={thumbnailUrl}
      title={title}
      totalEpisodes={totalEpisodes}
    />
  );
};

export default PodcastResultDescription;
