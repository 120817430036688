import { List, PlusThin, Spacer, Text, truthy } from '@sparemin/blockhead';
import useOpenTldlSearch from 'pages/TldlPage/hooks/useOpenTldlSearch';
import useHasReachedEpisodeLimit from 'pages/TldlPage/state/useHasReachedEpisodeLimit';
import { useIsTldlJobSubmitting } from 'pages/TldlPage/state/useSubmitTldlJob';
import TldlEpisodeLimitReachedTooltip from 'pages/TldlPage/TldlEpisodeLimitReachedTooltip';
import React, { Key, useCallback, useMemo } from 'react';
import { useHasTldlJob, useTldlEpisodeIdentifiers } from '../../state/tldl';
import styles from './TldlEpisodeList.module.scss';
import TldlEpisodeRow from './TldlEpisodeRow';

export interface TldlEpisodeListProps {}

const ADD_BUTTON_ID = styles.addButtonKey;

const TldlEpisodeList: React.FC<TldlEpisodeListProps> = () => {
  const { openTldlSearch } = useOpenTldlSearch();
  const isSubmitting = useIsTldlJobSubmitting();
  const hasJob = useHasTldlJob();
  const hasReachedEpisodeLimit = useHasReachedEpisodeLimit();

  const data = useTldlEpisodeIdentifiers();

  const dataWithAddButton = useMemo(
    () =>
      [
        ...data,
        !hasJob && { id: ADD_BUTTON_ID, title: 'add an episode' },
      ].filter(truthy),
    [data, hasJob],
  );

  const handleAction = useCallback(
    (key: Key) => {
      if (key === ADD_BUTTON_ID) {
        openTldlSearch();
      }
    },
    [openTldlSearch],
  );

  return (
    <List
      aria-label="selected tldl episodes"
      borderWeight={0}
      className={styles.list}
      cornerRadius="32px"
      disabledKeys={[
        ...data.map((d) => d.id),
        (isSubmitting || hasReachedEpisodeLimit) && ADD_BUTTON_ID,
      ].filter(truthy)}
      items={dataWithAddButton}
      onAction={handleAction}
    >
      {({ id: episodeId, title }) =>
        episodeId === ADD_BUTTON_ID ? (
          <List.Item key={episodeId} textValue={title}>
            <TldlEpisodeLimitReachedTooltip>
              <div className={styles.addRowContainer}>
                <Spacer
                  align="center"
                  justify="center"
                  className={styles.addRow}
                  orientation="horizontal"
                  space="8px"
                >
                  <PlusThin />
                  <Text variant="button">{title}</Text>
                </Spacer>
              </div>
            </TldlEpisodeLimitReachedTooltip>
          </List.Item>
        ) : (
          <List.Item key={episodeId} textValue={title}>
            <TldlEpisodeRow episodeId={episodeId} />
          </List.Item>
        )
      }
    </List>
  );
};

export default TldlEpisodeList;
