import { PodcastSearchResponse, searchPodcasts } from 'api/podcast';
import { SearchOptions } from 'api/types';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { podcastSearchQueryKeys } from './queries';

export type Options<TData = PodcastSearchResponse> = UseInfiniteQueryOptions<
  PodcastSearchResponse,
  ReturnType<typeof podcastSearchQueryKeys.search>,
  unknown,
  TData
>;

export default function useSearchPodcasts<TData = PodcastSearchResponse>(
  q: string,
  searchOpts?: SearchOptions,
  opts?: Omit<Options<TData>, 'getNextPageParam'>,
) {
  return useInfiniteQuery(
    podcastSearchQueryKeys.search(q),
    ({ queryKey: [{ query }], pageParam }) =>
      searchPodcasts(query, { nextToken: pageParam }),
    {
      ...opts,
      enabled: !!q && (opts?.enabled ?? true),
      getNextPageParam: (lastPage) => lastPage?.nextToken ?? undefined,
    },
  );
}
