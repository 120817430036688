import { List } from '@sparemin/blockhead';
import VisibilityWrapper from 'components/VisibilityWrapper';
import React from 'react';
import { createEpisodePath } from 'utils/routes';
import { useSelectEpisodeSearchResult } from '../EpisodeSearchResult';
import EpisodeSearchResult from '../EpisodeSearchResult/EpisodeSearchResult';

import SearchModalNoResults from '../SearchModalNoResults';
import SearchModalResultsLoader from '../SearchModalResultsLoader';
import { SearchEpisode } from '../types';
import useResultsKeyboardControl from '../useResultsKeyboardControl';
import styles from './SearchModalEpisodeResults.module.scss';
import useSeachModalEpisodesResults from './useSeachModalEpisodesResults';

interface SearchModalEpisodeResultsProps {
  searchQuery: string;
}

// When scrolling with the keyboard arrows, the end of the list is eventually reached.
// That end is faded by the fading container. In order to prevent fading the current focused
// element, this offset moves the scroll 80px more when the scroll is triggered by the
// keyboard navigation
const SCROLLING_OFFSET_PX = '-80px';

const SearchModalEpisodeResults: React.FunctionComponent<
  SearchModalEpisodeResultsProps
> = (props) => {
  const { searchQuery } = props;

  const { episodesSearchData, fetchNextPage, hasMore, isError, isLoading } =
    useSeachModalEpisodesResults({ searchQuery });

  const { selectEpisodeSearchResult } = useSelectEpisodeSearchResult();

  const getElementKey = React.useCallback(
    (result: SearchEpisode) => `${result.podcast.id}_${result.id}`,
    [],
  );
  const getElementNavigationPath = React.useCallback(
    (result: SearchEpisode) =>
      createEpisodePath(result.id, result.podcast.id, {
        startSec: 0,
      }),
    [],
  );

  const {
    onElementHover,
    onElementHoverLost,
    onVisibilityChange,
    selectedKeys,
  } = useResultsKeyboardControl<SearchEpisode>({
    getElementKey,
    getElementNavigationPath,
    isLoading,
    onSelectElement: selectEpisodeSearchResult,
    searchData: episodesSearchData,
    searchQuery,
  });

  const handleVisibilityChange = React.useCallback(
    (key: string) =>
      (
        inView: boolean,
        handles: {
          start: Element | null;
          end: Element | null;
          element: Element | null;
        },
      ): void => {
        onVisibilityChange(key, inView, handles);
      },
    [onVisibilityChange],
  );

  if (isLoading) {
    return <SearchModalResultsLoader isTab />;
  }

  if (isError) {
    return (
      <SearchModalNoResults text="Something went wrong, please try again" />
    );
  }

  if (!episodesSearchData.length) {
    return <SearchModalNoResults />;
  }

  return (
    <List
      aria-label="episode results"
      borderWeight={0}
      className={styles.list}
      cornerRadius={0}
      hasMore={hasMore}
      onLoadMore={fetchNextPage}
      selectedKeys={selectedKeys}
    >
      {episodesSearchData.map((episode) => (
        <List.Item key={getElementKey(episode)} textValue={episode.title}>
          <VisibilityWrapper
            endIndicatorPosition={{ bottom: SCROLLING_OFFSET_PX }}
            onVisibilityChange={handleVisibilityChange(getElementKey(episode))}
            startIndicatorPosition={{ top: SCROLLING_OFFSET_PX }}
            threshold={1}
          >
            <EpisodeSearchResult
              episode={episode}
              onHover={onElementHover}
              onHoverLost={onElementHoverLost}
            />
          </VisibilityWrapper>
        </List.Item>
      ))}
    </List>
  );
};

export default SearchModalEpisodeResults;
