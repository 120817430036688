import { useEffect } from 'react';
import { createTldlPath } from 'utils/routes';
import { useIsTldlPreloading, useTldlJobCuid } from '../state/tldl';

/**
 * synchronizes the URL with data from TLDL state.
 *
 * The data only flows one way - from the applicaton state to the URL.  the application
 * is considered the source of truth.
 *
 * The only time the route is the source of truth is during preloading.  During
 * preloading, this hook is effectively disabled and won't modify the URL.
 */
export default function useSyncStateWithRoute() {
  const isPreloading = useIsTldlPreloading();
  const jobCuid = useTldlJobCuid();

  useEffect(() => {
    if (isPreloading) {
      return;
    }

    const [{ pathname }] = createTldlPath({ jobCuid });

    // HACK - react-router doesn't know about this, but that's kind of the point.
    // this line changes the URL without triggering a render cycle.  Triggering a
    // render wouldn't be that bad, however location.pathname is used as the key
    // for all top-level routes in the app (they're all animated).  so if we cahnged
    // from /tldl to /tldl/jobId, then the entire tldl page would animateout and
    // back in.  That can be fixed in react-router world, but this is a lot easier
    // at the moment.
    //
    // and if it makes you feel any better Kent Dodds did it too
    // https://github.com/kentcdodds/kentcdodds.com/blob/main/app/utils/misc.tsx#L296-L323
    window.history.replaceState(null, '', pathname);
  }, [isPreloading, jobCuid]);
}
