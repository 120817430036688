import { StatusTag } from '@sparemin/blockhead';
import cn from 'classnames';
import React, { CSSProperties } from 'react';
import { numToPx } from 'utils/styles';
import styles from './BetaBadge.module.scss';

export type BetaBadgeProps = Pick<CSSProperties, 'fontSize'> & {
  className?: string;
};

/**
 * Purple badge with "beta" text.
 */
const BetaBadge: React.FC<BetaBadgeProps> = ({
  className,
  fontSize = '10px',
}) => (
  <StatusTag
    className={cn(styles.badge, className)}
    color="secondary"
    style={{
      [styles.fontSizeVariable]: numToPx(fontSize),
    }}
  >
    BETA
  </StatusTag>
);

export default BetaBadge;
