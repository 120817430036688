import cn from 'classnames';
import { AppBody } from 'components/AppHeader';
import EpisodeDescription from '../EpisodeDescription';
import { useEpisodePage } from '../EpisodePageContext';
import EpisodeParts from '../EpisodeParts';
import TldlEpisodeBanner from '../TldlEpisodeBanner';
import styles from './EpisodePageBody.module.scss';

export interface EpisodePageBodyProps {}

const EpisodePageBody: React.FC<EpisodePageBodyProps> = () => {
  const { hasChapters: hasParts } = useEpisodePage();

  return (
    <AppBody>
      <div
        className={cn(styles.contents, {
          [styles.contents__hasParts]: hasParts,
        })}
      >
        <TldlEpisodeBanner className={styles.banner} />
        {
          // EpisodeParts renders a skeleton if there's no data, so before we know
          // for sure whether or not there are parts (hasParts === true or hasParts === false),
          // render EpisodeParts
          // modified until future reintroduction of parts
          // hasParts ? hasParts === undefined

          hasParts ? <EpisodeParts /> : <EpisodeDescription />
        }
      </div>
    </AppBody>
  );
};

export default EpisodePageBody;
