import React from 'react';
import TldlDurationBadge from '../../TldlDurationBadge';
import TldlDurationSelector from '../../TldlDurationSelector';
import styles from './TldlEpisodeCardHeader.module.scss';
import TldlEpisodeCardHeaderContainer from './TldlEpisodeCardHeaderContainer';
import TldlHeaderH1 from './TldlHeaderH1';

export interface TldlIdleHeaderProps {}

const TldlIdleHeader: React.FC<TldlIdleHeaderProps> = () => (
  <TldlEpisodeCardHeaderContainer
    align="center"
    className={styles.idleContainer}
    justify="center"
    orientation="horizontal"
    space={0}
  >
    <TldlHeaderH1>Listen to the gist of</TldlHeaderH1>
    <TldlDurationBadge className={styles.badge} />
    <TldlHeaderH1>in only</TldlHeaderH1>
    <TldlDurationSelector />
  </TldlEpisodeCardHeaderContainer>
);

export default TldlIdleHeader;
