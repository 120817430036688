import UnloadingAudio from 'components/UnloadingAudio';
import { motion } from 'framer-motion';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state';
import React, { useEffect, useState } from 'react';
import styles from './StickyAudioPlayer.module.scss';
import StickyAudioPlayerUi from './StickyAudioPlayerUi';
import useStickyAudioPlayerAnimation from './useStickyAudioPlayerAnimation';

export interface StickyAudioPlayerProps {
  containerRef?: (val: Element | null) => void;
}

const StickyAudioPlayer = React.forwardRef<
  HTMLAudioElement,
  StickyAudioPlayerProps
>(({ containerRef }, ref) => {
  const { src } = useAudioPlayer();

  const [initialized, setInitialized] = useState(false);

  const animation = useStickyAudioPlayerAnimation();
  useEffect(() => {
    if (src && !initialized) {
      setInitialized(true);
    }
  }, [initialized, src]);

  return (
    <>
      <motion.div
        className={styles.container}
        {...animation}
        ref={containerRef}
      >
        <StickyAudioPlayerUi />
      </motion.div>
      {src && <UnloadingAudio key={src} preload="none" ref={ref} src={src} />}
    </>
  );
});

export default StickyAudioPlayer;
