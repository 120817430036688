import React from 'react';

import styles from './SearchModalNoResults.module.scss';

interface SearchModalNoResultsProps {
  text?: string;
}

const SearchModalNoResults: React.FunctionComponent<
  SearchModalNoResultsProps
> = (props) => {
  const { text = 'We couldn’t find any results.' } = props;

  return <p className={styles.root}>{text}</p>;
};

export default SearchModalNoResults;
