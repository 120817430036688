import { List } from '@sparemin/blockhead';
import VisibilityWrapper from 'components/VisibilityWrapper';
import { useEventTracking } from 'context/EventTrackingContext';
import React, { useCallback } from 'react';
import { createPodcastPath } from 'utils/routes';
import PodcastSearchResult from '../PodcastSearchResult';
import { useOnSelectPodcast } from '../SearchModalDispatchContext';

import SearchModalNoResults from '../SearchModalNoResults';
import SearchModalResultsLoader from '../SearchModalResultsLoader';
import { SearchPodcast } from '../types';
import useResultsKeyboardControl from '../useResultsKeyboardControl';
import styles from './SearchModalPodcastResults.module.scss';
import useSearchModalPodcastResults from './useSearchModalPodcastResults';

interface SearchModalPodcastResultsProps {
  searchQuery: string;
}

// When scrolling with the keyboard arrows, the end of the list is eventually reached.
// That end is faded by the fading container. In order to prevent fading the current focused
// element, this offset moves the scroll 80px more when the scroll is triggered by the
// keyboard navigation
const SCROLLING_OFFSET_PX = '-80px';

const SearchModalPodcastResults: React.FunctionComponent<
  SearchModalPodcastResultsProps
> = (props) => {
  const { searchQuery } = props;
  const onSelectPodcast = useOnSelectPodcast();

  const { trackSearchResultSelected } = useEventTracking();
  const { fetchNextPage, hasMore, isError, isLoading, podcastsSearchData } =
    useSearchModalPodcastResults({ searchQuery });

  const handleSelectPodcast = useCallback(
    (podcast: SearchPodcast) => {
      trackSearchResultSelected({ podcastName: podcast.title });
      onSelectPodcast?.(podcast);
    },
    [onSelectPodcast, trackSearchResultSelected],
  );

  const getElementKey = React.useCallback(
    (result: SearchPodcast) => result.id,
    [],
  );
  const getElementNavigationPath = React.useCallback(
    (result: SearchPodcast) => createPodcastPath(result.id),
    [],
  );

  const {
    onElementHover,
    onElementHoverLost,
    onVisibilityChange,
    selectedKeys,
  } = useResultsKeyboardControl<SearchPodcast>({
    getElementKey,
    getElementNavigationPath,
    isLoading,
    onSelectElement: handleSelectPodcast,
    searchData: podcastsSearchData,
    searchQuery,
  });

  const handleVisibilityChange = React.useCallback(
    (key: string) =>
      (
        inView: boolean,
        handles: {
          start: Element | null;
          end: Element | null;
          element: Element | null;
        },
      ): void => {
        onVisibilityChange(key, inView, handles);
      },
    [onVisibilityChange],
  );

  if (isLoading) {
    return <SearchModalResultsLoader isTab />;
  }

  if (isError) {
    return (
      <SearchModalNoResults text="Something went wrong, please try again" />
    );
  }

  if (!podcastsSearchData.length) {
    return <SearchModalNoResults />;
  }

  return (
    <List
      aria-label="podcast results"
      borderWeight={0}
      className={styles.list}
      cornerRadius={0}
      hasMore={hasMore}
      onLoadMore={fetchNextPage}
      selectedKeys={selectedKeys}
    >
      {podcastsSearchData.map((podcast) => (
        <List.Item key={getElementKey(podcast)} textValue={podcast.title}>
          <VisibilityWrapper
            endIndicatorPosition={{ bottom: SCROLLING_OFFSET_PX }}
            onVisibilityChange={handleVisibilityChange(podcast.id)}
            startIndicatorPosition={{ top: SCROLLING_OFFSET_PX }}
            threshold={1}
          >
            <PodcastSearchResult
              onClick={handleSelectPodcast}
              onHover={onElementHover}
              onHoverLost={onElementHoverLost}
              podcast={podcast}
              showDescription
            />
          </VisibilityWrapper>
        </List.Item>
      ))}
    </List>
  );
};

export default SearchModalPodcastResults;
