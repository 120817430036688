import useTldlView from 'pages/TldlPage/state/useTldlView';
import React from 'react';
import TldlIdleFooter from './TldlIdleFooter';
import TldlProcessingFooter from './TldlProcessingFooter';

export interface TldlEpisodeCardFooterProps {}

const TldlEpisodeCardFooter: React.FC<TldlEpisodeCardFooterProps> = () => {
  const view = useTldlView();

  if (view === 'selection') {
    return <TldlIdleFooter />;
  }

  if (view === 'summarizing') {
    return <TldlProcessingFooter />;
  }

  return null;
};

export default TldlEpisodeCardFooter;
