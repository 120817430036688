import { Tabs } from '@sparemin/blockhead';
import React from 'react';

import SearchModalEpisodeResults from '../SearchModalEpisodeResults';
import SearchModalFadingWrapper from '../SearchModalFadingWrapper';
import SearchModalPodcastResults from '../SearchModalPodcastResults';

import styles from './SearchModalResults.module.scss';

interface SearchModalResultsProps {
  searchQuery: string;
}

const SearchModalResults: React.FunctionComponent<SearchModalResultsProps> = (
  props,
) => {
  const { searchQuery } = props;

  return (
    <Tabs className={styles.root}>
      <Tabs.Item title="podcasts">
        <SearchModalFadingWrapper>
          <SearchModalPodcastResults searchQuery={searchQuery} />
        </SearchModalFadingWrapper>
      </Tabs.Item>
      <Tabs.Item title="episodes">
        <SearchModalFadingWrapper>
          <SearchModalEpisodeResults searchQuery={searchQuery} />
        </SearchModalFadingWrapper>
      </Tabs.Item>
    </Tabs>
  );
};

export default SearchModalResults;
