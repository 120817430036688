import { podcastSearchQueryKeys } from 'api/hooks/queries';
import useSearchPodcasts from 'api/hooks/useSearchPodcasts';
import { PodcastSearchResponse } from 'api/podcast';
import { useEffect } from 'react';
import { InfiniteData, useQueryClient } from 'react-query';
import { SearchPodcast } from '../types';

export interface UseSearchModalPodcastResults
  extends Pick<ReturnType<typeof useSearchPodcasts>, 'fetchNextPage'> {
  hasMore: boolean;
  isError: boolean;
  isLoading: boolean;
  podcastsSearchData: SearchPodcast[];
}

export interface UseSearchModalPodcastResultsConfig {
  searchQuery: string;
}

const searchPodcastSelector = (d: InfiniteData<PodcastSearchResponse>) => {
  const data: SearchPodcast[] = d.pages
    .flatMap((p) => p.content)
    .map((r) => ({
      category: r.topCategory,
      description: r.description,
      id: r.podcastId,
      thumbnailUrl: r.thumbnailUrl,
      title: r.title,
      totalEpisodes: r.totalEpisodes,
    }));

  return {
    ...d,
    pages: data,
  };
};

const useSearchModalPodcastResults = (
  config: UseSearchModalPodcastResultsConfig,
): UseSearchModalPodcastResults => {
  const { searchQuery } = config;
  const queryClient = useQueryClient();

  useEffect(
    () => () => {
      if (searchQuery) {
        // This prevents the screen render to get slow due to too many results
        // and also clears the query when the search query changes
        queryClient.removeQueries(podcastSearchQueryKeys.search(searchQuery));
      }
    },
    [queryClient, searchQuery],
  );

  const {
    data: podcastsSearchDataResponse,
    fetchNextPage,
    hasNextPage: hasMore = false,
    isError,
    isLoading: isQueryLoading,
  } = useSearchPodcasts(searchQuery, undefined, {
    keepPreviousData: false,
    refetchOnMount: 'always',
    select: searchPodcastSelector,
  });

  const isLoading = (!isError && !podcastsSearchDataResponse) || isQueryLoading;

  const podcastsSearchData = podcastsSearchDataResponse?.pages ?? [];

  return {
    fetchNextPage,
    hasMore,
    isError,
    isLoading,
    podcastsSearchData,
  };
};

export default useSearchModalPodcastResults;
