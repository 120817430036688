import { SponsoredEpisodeWidgetInfo } from 'api/podcast';
import { useEventTracking, PlayerLinkType } from 'context/EventTrackingContext';
import { useCallback } from 'react';

type UseTrackPlayerLinkClickConfig = {
  linkType: PlayerLinkType;
  isSponsored?: boolean;
  sponsoredEpisodeWidgetInfo?: SponsoredEpisodeWidgetInfo;
};

export default function useTrackPlayerLinkClick({
  linkType,
  isSponsored,
  sponsoredEpisodeWidgetInfo,
}: UseTrackPlayerLinkClickConfig) {
  const { trackPersistentPlayerLinkClick, trackSponsoredEpisodeClickThrough } =
    useEventTracking();

  const handleLinkClick = useCallback(() => {
    trackPersistentPlayerLinkClick(linkType);
    if (isSponsored && sponsoredEpisodeWidgetInfo) {
      trackSponsoredEpisodeClickThrough(sponsoredEpisodeWidgetInfo);
    }
  }, [
    isSponsored,
    linkType,
    sponsoredEpisodeWidgetInfo,
    trackPersistentPlayerLinkClick,
    trackSponsoredEpisodeClickThrough,
  ]);

  return handleLinkClick;
}
