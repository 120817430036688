import { useModalContents } from 'context/ModalContentsContext';
import { useCallback } from 'react';
import {
  PodcastSearchEventResult,
  SearchEpisode,
  SearchModalParams,
  SearchPodcast,
} from '../types';
import { createEvent } from '../utils';
import { SearchModalContentsProps } from './types';

export type OnSelectEpisodeInternal = (
  episode: SearchEpisode,
  result: PodcastSearchEventResult,
) => void;

export type OnSelectPodcastInternal = (
  podcast: SearchPodcast,
  result: PodcastSearchEventResult,
) => void;

type UseSearchModalCallbacksConfig = {
  onSelectEpisode?: OnSelectEpisodeInternal;
  onSelectPodcast?: OnSelectPodcastInternal;
};

export default function useSearchModalCallbacks(
  props: SearchModalContentsProps,
  config?: UseSearchModalCallbacksConfig,
) {
  const { params } = useModalContents<SearchModalParams>();

  const {
    onSelectEpisode: onSelectEpisodeParam,
    onSelectPodcast: onSelectPodcastParam,
  } = params ?? {};

  const {
    onSelectEpisode: onSelectEpisodeProp,
    onSelectPodcast: onSelectPodcastProp,
  } = props;

  const {
    onSelectEpisode: onSelectEpisodeInternal,
    onSelectPodcast: onSelectPodcastInternal,
  } = config ?? {};

  return {
    onSelectEpisode: useCallback(
      (episode: SearchEpisode) => {
        const { result, event } = createEvent();

        onSelectEpisodeProp?.(episode, event);
        onSelectEpisodeParam?.(episode, event);
        onSelectEpisodeInternal?.(episode, result);
      },
      [onSelectEpisodeInternal, onSelectEpisodeParam, onSelectEpisodeProp],
    ),
    onSelectPodcast: useCallback(
      (podcast: SearchPodcast) => {
        const { result, event } = createEvent();

        onSelectPodcastProp?.(podcast, event);
        onSelectPodcastParam?.(podcast, event);
        onSelectPodcastInternal?.(podcast, result);
      },
      [onSelectPodcastInternal, onSelectPodcastParam, onSelectPodcastProp],
    ),
  };
}
