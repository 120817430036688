import { useGetEpisodePublishedAtMillis } from 'api/hooks/useGetPodcastEpisodeDetail';
import { useEventTracking } from 'context/EventTrackingContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { useEffect, useState } from 'react';
import { useQueueEpisodes } from '..';
import { useNextEpisodeInQueue } from '../useGetListeningQueue';

type EpisodeIdentifier = {
  podcastId: string | undefined;
  episodeId: string | undefined;
  isPlayedFromQueue: boolean;
  sourceWidgetId?: string | undefined;
};

export default function useCreateListeningQueue() {
  // the episode used to "seed" the listening queue -
  // the request only generates a new queue if queueSeed.isPlayedFromQueue
  // is set to false
  const [queueSeed, setQueueSeed] = useState<EpisodeIdentifier>();

  const {
    loadEpisodeAudio,
    events,
    podcastId,
    episodeId,
    playedFromQueueOverride,
    sourceWidgetId,
  } = useAudioPlayer();
  const { trackPlayback, trackSponsoredEpisodePlayed } = useEventTracking();

  const [prevPlayerIds, setPrevPlayerIds] = useState({
    episodeId,
    podcastId,
  });
  if (
    prevPlayerIds?.episodeId !== episodeId ||
    prevPlayerIds.podcastId !== podcastId
  ) {
    setPrevPlayerIds({ episodeId, podcastId });

    if (
      queueSeed?.podcastId !== podcastId ||
      queueSeed?.episodeId !== episodeId
    ) {
      // if the player episode changes and it doesn't match the current queue seed,
      // update the queue seed, because the trigger for the player
      // change came from outside this hook. If the trigger came from the queue component, playedFromQueueOverride will
      // be true, otherwise, it will be false, indicating that a new queue should be generated.
      setQueueSeed({
        episodeId,
        podcastId,
        isPlayedFromQueue: !!playedFromQueueOverride,
        // the sourceWidgetId should only be passed the first time the queue request is sent, so it shouldn't be used
        // if the request to play comes from the queue or continue listening.
        sourceWidgetId: !playedFromQueueOverride ? sourceWidgetId : undefined,
      });
    }
  }

  const { data: publishedAtMillis } = useGetEpisodePublishedAtMillis({
    podcastId: queueSeed?.podcastId,
    episodeId: queueSeed?.episodeId,
  });

  const { data: nextEpisode } = useNextEpisodeInQueue({
    remoteEpisodeId: queueSeed?.episodeId,
    podcastId: queueSeed?.podcastId,
    publishedAtMillis,
    isPlayedFromQueue: queueSeed?.isPlayedFromQueue,
    sourceWidgetId: queueSeed?.sourceWidgetId,
  });

  const { data: queueContents } = useQueueEpisodes({
    remoteEpisodeId: queueSeed?.episodeId,
    podcastId: queueSeed?.podcastId,
    publishedAtMillis,
    isPlayedFromQueue: queueSeed?.isPlayedFromQueue,
    sourceWidgetId: queueSeed?.sourceWidgetId,
  });

  const {
    nextPodcastId,
    nextEpisodeId,
    nextPodcastName,
    nextEpisodeName,
    isSponsored,
    widgetInfo,
  } = nextEpisode ?? {};

  useEffect(() => {
    const handleAudioEnded = () => {
      // React v17 does not "batch" all state updates, but the state will get updated in the
      // order it is called, so the queueSeed will be updated before the player, preventing the queue from being
      // reset

      setQueueSeed({
        episodeId: nextEpisodeId,
        podcastId: nextPodcastId,
        isPlayedFromQueue: true,
        sourceWidgetId: undefined,
      });

      if (
        nextPodcastId &&
        nextEpisodeId &&
        nextEpisodeName &&
        nextPodcastName
      ) {
        loadEpisodeAudio({
          podcastId: nextPodcastId,
          episodeId: nextEpisodeId,
          isSponsored,
          sponsoredWidgetInfo: widgetInfo,
        });

        trackPlayback('play', {
          source: isSponsored
            ? 'ContinuousListeningSponsored'
            : 'ContinuousListeningOrganic',
          podcastId: nextPodcastId,
          remoteEpisodeId: nextEpisodeId,
          podcastName: nextPodcastName,
          episodeName: nextEpisodeName,
        });
        if (isSponsored && widgetInfo) {
          trackSponsoredEpisodePlayed(widgetInfo);
        }
      }
    };

    events.registerEventListener('ended', handleAudioEnded);

    return () => {
      events.unregisterEventListener('ended', handleAudioEnded);
    };
  }, [
    events,
    isSponsored,
    loadEpisodeAudio,
    nextEpisodeId,
    nextEpisodeName,
    nextPodcastId,
    nextPodcastName,
    trackPlayback,
    trackSponsoredEpisodePlayed,
    widgetInfo,
  ]);

  return queueContents;
}
