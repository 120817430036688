import { Description } from '@sparemin/blockhead';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export interface EpisodeDescriptionTextProps {}

const EpisodeDescriptionText: React.FC<EpisodeDescriptionTextProps> = () => {
  const { description, isLoading } = useEpisodeDescription();

  return (
    <Description.Body>
      {isLoading ? <Skeleton count={2} /> : description}
    </Description.Body>
  );
};

export default EpisodeDescriptionText;
