import {
  Button,
  ButtonProps,
  IconButton,
  ShareIcon,
} from '@sparemin/blockhead';
import React from 'react';

export type ShareButtonVariant = 'icon-text' | 'icon';

export type ShareButtonProps = Pick<ButtonProps, 'onPress'> & {
  className?: string;
  variant?: ShareButtonVariant;
};

const ShareButton: React.FC<ShareButtonProps> = ({
  className,
  onPress,
  variant = 'icon',
}) => {
  if (variant === 'icon') {
    return (
      <IconButton
        {...{ className, onPress }}
        size="large"
        color="dark"
        variant="frosted"
      >
        <ShareIcon height="16px" width="16px" />
      </IconButton>
    );
  }

  return (
    <Button
      {...{ className, onPress }}
      color="dark"
      startIcon={<ShareIcon />}
      variant="frosted"
    >
      share
    </Button>
  );
};

export default ShareButton;
