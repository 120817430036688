import cn from 'classnames';
import { HeadlinerLogo, HeadySolid } from 'components/icons';
import { useBreakpoints } from 'context/BreakpointsContext';
import { AnimationProps, motion } from 'framer-motion';
import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import routes from 'utils/routes';
import styles from './LogoLink.module.scss';

export type LogoLinkProps = AnimationProps &
  Partial<Pick<LinkProps, 'to'>> & {
    className?: string;
  };

const MotionLink = motion(Link);

const LogoLink: React.FC<LogoLinkProps> = ({
  className,
  to = routes.home,
  ...animationProps
}) => {
  const { md } = useBreakpoints();

  return (
    <MotionLink
      className={cn(styles.root, className)}
      to={to}
      {...animationProps}
    >
      {md ? (
        <HeadySolid className={styles.icon} />
      ) : (
        <HeadlinerLogo className={styles.icon} />
      )}
    </MotionLink>
  );
};

export default LogoLink;
