import { Tooltip, TooltipProps } from '@sparemin/blockhead';
import React from 'react';
import { SetOptional } from 'type-fest';
import useHasReachedEpisodeLimit from './state/useHasReachedEpisodeLimit';

type TldlEpisodeLimitRenderFunction = (
  limitReached: boolean,
) => React.ReactNode;

export type TldlEpisodeLimitReachedTooltipProps = SetOptional<
  TooltipProps,
  'contents'
> & {
  children?: React.ReactNode | TldlEpisodeLimitRenderFunction;
};

const DEFAULT_MESSAGE = "You've reached the maximum number of episodes";

const TldlEpisodeLimitReachedTooltip: React.FC<
  TldlEpisodeLimitReachedTooltipProps
> = ({
  arrow = true,
  children,
  contents = DEFAULT_MESSAGE,
  delay = 0,
  isDisabled,
  ...rest
}) => {
  const limitReached = useHasReachedEpisodeLimit();

  return (
    <Tooltip
      isDisabled={isDisabled ?? !limitReached}
      {...{ arrow, contents, delay }}
      {...rest}
    >
      {typeof children === 'function' ? children(limitReached) : children}
    </Tooltip>
  );
};

export default TldlEpisodeLimitReachedTooltip;
