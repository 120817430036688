import cn from 'classnames';
import { Text } from 'components/Typography';
import { useBreakpoints } from 'context/BreakpointsContext';
import { useAudioPlayer } from 'pages/AudioPlayerPage/state/AudioPlayerContext';
import { isAudioPlayerLink } from 'pages/AudioPlayerPage/state/AudioPlayerContext/utils';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styles from './PodcastData.module.scss';

type PodcastTitleProps = {};

const PodcastTitle: React.FC<PodcastTitleProps> = () => {
  const { subtitle } = useAudioPlayer();
  const { md } = useBreakpoints();

  const location = useLocation();

  const isLinkConfig = isAudioPlayerLink(subtitle);
  const pathname = isLinkConfig ? subtitle.path.pathname : undefined;
  const isOnPage = location.pathname === pathname;

  const titleText = (
    <Text
      className={cn(styles.podcast, {
        [styles.podcast__link]: !isOnPage,
      })}
      type="details"
    >
      {isLinkConfig ? subtitle.value : subtitle}
    </Text>
  );

  if (subtitle && isLinkConfig && !isOnPage && !md) {
    const { path, navOpts, onClick } = subtitle;

    return (
      <Link
        className={styles.link}
        to={path ?? ''}
        onClick={onClick}
        {...navOpts}
      >
        {titleText}
      </Link>
    );
  }
  return titleText;
};
export default PodcastTitle;
