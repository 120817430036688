import { AspectRatio, DescriptionThumbnail } from '@sparemin/blockhead';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useEpisodeDescription } from './EpisodeDescriptionContext';

export type EpisodeDescriptionThumbnailProps = {
  alt?: string;
};

const EpisodeDescriptionThumbnail: React.FC<
  EpisodeDescriptionThumbnailProps
> = ({ alt = 'episode artwork' }) => {
  const { isLoading, thumbnailSrc } = useEpisodeDescription();

  return isLoading ? (
    <AspectRatio ratio={1}>
      <Skeleton />
    </AspectRatio>
  ) : (
    <DescriptionThumbnail alt={alt} ratio={1} src={thumbnailSrc} />
  );
};

export default EpisodeDescriptionThumbnail;
