import { SearchModalMode } from './SearchModalConfigContext';
import { SearchModalProps } from './types';

export function isNavigationConfig(props: SearchModalProps) {
  return props.navigationConfig !== undefined;
}

export function getMode(props: SearchModalProps): SearchModalMode {
  return isNavigationConfig(props) ? 'navigation' : 'selection';
}

export function createEvent() {
  const result = { isDefaultPrevented: false };

  const event = {
    preventDefault() {
      result.isDefaultPrevented = true;
    },
  };

  return { result, event };
}
