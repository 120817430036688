import React, { useContext, useMemo } from 'react';
import {
  SearchModalConfigContextType,
  SearchModalConfigProviderProps,
} from './types';

const Null = () => null;

const SearchModalConfigContext = React.createContext<
  SearchModalConfigContextType | undefined
>(undefined);

export const SearchModalConfigProvider: React.FC<
  SearchModalConfigProviderProps
> = ({
  banner = <Null />,
  children,
  createAutoplayEpisodePagePath,
  createEpisodePagePath,
  createPodcastPagePath,
  mode,
}) => (
  <SearchModalConfigContext.Provider
    value={useMemo(
      () => ({
        banner,
        createAutoplayEpisodePagePath,
        createEpisodePagePath,
        createPodcastPagePath,
        mode,
      }),
      [
        banner,
        createAutoplayEpisodePagePath,
        createEpisodePagePath,
        createPodcastPagePath,
        mode,
      ],
    )}
  >
    {children}
  </SearchModalConfigContext.Provider>
);

export function useSearchModalConfig() {
  const context = useContext(SearchModalConfigContext);

  if (context === undefined) {
    throw new Error(
      'useSearchModalConfig must be used within SearchModalConfigProvider',
    );
  }

  return context;
}

export function useSearchModalBanner() {
  return useSearchModalConfig().banner;
}

export function useMode() {
  return useSearchModalConfig().mode;
}

export function useCreatePodcastPath() {
  return useSearchModalConfig().createPodcastPagePath;
}

export function useCreateEpisodePath() {
  return useSearchModalConfig().createEpisodePagePath;
}

export function useCreateAutoplayEpisodePath() {
  return useSearchModalConfig().createAutoplayEpisodePagePath;
}
