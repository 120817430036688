import { Heading, HeadingProps } from '@sparemin/blockhead';
import { useBreakpoints } from 'context/BreakpointsContext';
import React from 'react';

export type TldlHeaderH1Props = Omit<HeadingProps, 'level'>;

const TldlHeaderH1: React.FC<TldlHeaderH1Props> = (props) => {
  const { md } = useBreakpoints();

  return <Heading level={md ? 2 : 1} {...props} />;
};

export default TldlHeaderH1;
