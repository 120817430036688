import { EpisodeSearchResponse, searchEpisodes } from 'api/podcast';
import { SearchOptions } from 'api/types';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryOptions } from 'types';
import { episodeSearchQueryKeys } from './queries';

export type Options<TData = EpisodeSearchResponse> = UseInfiniteQueryOptions<
  EpisodeSearchResponse,
  ReturnType<typeof episodeSearchQueryKeys.search>,
  unknown,
  TData
>;

export default function useSearchEpisodes<TData = EpisodeSearchResponse>(
  q: string,
  searchOpts?: SearchOptions,
  opts?: Omit<Options<TData>, 'getNextPageParam'>,
) {
  return useInfiniteQuery(
    episodeSearchQueryKeys.search(q),
    ({ queryKey: [{ query }], pageParam }) =>
      searchEpisodes(query, {
        nextToken: pageParam,
      }),
    {
      ...opts,
      enabled: !!q && (opts?.enabled ?? true),
      getNextPageParam: (lastPage) => lastPage.nextToken ?? undefined,
    },
  );
}
