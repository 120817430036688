import { Heading, Spacer } from '@sparemin/blockhead';
import BetaBadge from 'components/BetaBadge';
import React from 'react';
import styles from './TldlSearchModalBanner.module.scss';

export interface TldlSearchModalBannerProps {}

const TldlSearchModalBanner: React.FC<TldlSearchModalBannerProps> = () => (
  <Spacer
    align="center"
    className={styles.banner}
    orientation="vertical"
    space="4px"
  >
    <Spacer align="center" orientation="horizontal" space="4px">
      <Heading level={3}>TLDL</Heading>
      <div>
        <BetaBadge />
      </div>
    </Spacer>
    <Heading level={1}>Select episodes to summarize</Heading>
  </Spacer>
);

export default TldlSearchModalBanner;
